import { PublicKey, SystemProgram, TransactionInstruction } from "@solana/web3.js";
import { BN } from "@coral-xyz/anchor";
import House from "../../sdk/house";
import HouseToken from "../../sdk/houseToken";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { DelegateAccounts, MAGIC_CONTEXT_ID } from "@magicblock-labs/ephemeral-rollups-sdk";
import { DELEGATION_PROGRAM_ID, MAGIC_PROGRAM_ID } from "@magicblock-labs/delegation-program";

export const updateHouseTokenIx = async (
    houseToken: HouseToken,
    authority: PublicKey,
    status?: "active" | "frozen" | "inFlowsSuspended" | "outFlowsSuspended",
    incrementUnit?: number,
    proportionTotalBankrollBps?: number
): Promise<TransactionInstruction> => {
    const program = houseToken.isDelegated ? houseToken.erProgram : houseToken.baseProgram;
    const permissionPubkey = House.deriverPermissionPubkey(
        houseToken.house.publicKey,
        authority,
        houseToken.house.programId
    )
    const statusObj = {};
    statusObj[status] = {};
    const args = {
        status: status != undefined ? statusObj as any : null,
        incrementUnit: incrementUnit != undefined ? new BN(incrementUnit) : null,
        proportionTotalBankrollBps: proportionTotalBankrollBps != undefined ? proportionTotalBankrollBps : null,
    };
    console.log(args);
    return await program.methods.houseTokenUpdate(
        args
    ).accounts({
        authority: authority,
        permission: permissionPubkey,
        house: houseToken.house.publicKey,
        houseToken: houseToken.publicKey,
        systemProgram: SystemProgram.programId
    }).instruction()
}

export const updateHouseIx = async (
    house: House,
    authority: PublicKey,
    status?: "active" | "frozen" | "inFlowsSuspended" | "outFlowsSuspended"
): Promise<TransactionInstruction> => {
    const permissionPubkey = House.deriverPermissionPubkey(
        house.publicKey,
        authority,
        house.programId
    )
    const statusObj = {};
    statusObj[status] = {};
    const args = {
        status: status != undefined ? statusObj as any : null,
    };
    console.log(args);
    return await house.baseProgram.methods.houseUpdate(
        args
    ).accounts({
        authority: authority,
        permission: permissionPubkey,
        house: house.publicKey,
        systemProgram: SystemProgram.programId
    }).instruction()
}

export const createHouseTokenIx = async (
    house: House,
    authority: PublicKey,
    tokenMint: PublicKey,
    incrementUnit: number,
    lpIsPermissioned: boolean,
    proportionTotalBankrollBps: number
): Promise<TransactionInstruction> => {
    const houseTokenPubkey = HouseToken.deriveHouseTokenPubkey(
        house.publicKey,
        tokenMint,
        house.programId
    );
    const houseTokenBankPubkey = HouseToken.deriveHouseTokenBankPubkey(
        house.publicKey,
        tokenMint,
        house.programId
    );
    const vaultPubkey = HouseToken.deriveHouseTokenVaultPubkey(
        houseTokenBankPubkey,
        tokenMint
    );
    const permissionPubkey = House.deriverPermissionPubkey(
        house.publicKey,
        authority,
        house.programId
    )

    return await house.baseProgram.methods.houseTokenInitialize({
        incrementUnit: new BN(incrementUnit),
        lpIsPermissioned: lpIsPermissioned,
        proportionTotalBankrollBps: proportionTotalBankrollBps,
    }).accounts({
        payer: house.baseProgram.provider.publicKey,
        authority: house.baseProgram.provider.publicKey,
        permission: permissionPubkey,
        house: house.publicKey,
        houseToken: houseTokenPubkey,
        houseTokenBank: houseTokenBankPubkey,
        tokenMint: tokenMint,
        vault: vaultPubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId
    }).instruction()
}

export const delegateHouseTokenIx = async (
    houseToken: HouseToken,
    authority: PublicKey,
    commitFrequencyMs: number = 60_000
): Promise<TransactionInstruction> => {
    const {
        delegationPda,
        delegationMetadata,
        bufferPda,
        commitStateRecordPda,
        commitStatePda,
    } = DelegateAccounts(
        houseToken.publicKey, 
        houseToken.baseProgram.programId
    );
    const permissionPubkey = House.deriverPermissionPubkey(
        houseToken.house.publicKey,
        authority,
        houseToken.house.programId
    )
    return await houseToken.baseProgram.methods.houseTokenDelegate({
        commitFrequencyMs: commitFrequencyMs
    }).accounts({
        payer: authority,
        authority: authority,
        permission: permissionPubkey,
        house: houseToken.house.publicKey,
        houseToken: houseToken.publicKey,
        buffer: bufferPda,
        delegationRecord: delegationPda,
        delegationMetadata: delegationMetadata,
        ownerProgram: houseToken.baseProgram.programId,
        delegationProgram: DELEGATION_PROGRAM_ID,
        systemProgram: SystemProgram.programId
    }).instruction()
}

export const undelegateHouseTokenIx = async (
    houseToken: HouseToken,
    authority: PublicKey
): Promise<TransactionInstruction> => {
    const permissionPubkey = House.deriverPermissionPubkey(
        houseToken.house.publicKey,
        authority,
        houseToken.house.programId
    );
    return await houseToken.erProgram.methods.houseTokenFreezeOrUndelegate(
        {}
    ).accounts({
        authority: authority,
        permission: permissionPubkey,
        houseToken: houseToken.publicKey,
        magicProgram: MAGIC_PROGRAM_ID,
        magicContext: MAGIC_CONTEXT_ID
    }).instruction()
}

export const updateHouseTokenStatusIx = async (
    houseToken: HouseToken,
    signer: PublicKey,
    status?: "active" | "frozen" | "inFlowsSuspended" | "outFlowsSuspended"
): Promise<TransactionInstruction> => {
    return await updateHouseTokenIx(houseToken, signer, status)
}

export const updateHouseTokenConfigIx = async (
    houseToken: HouseToken,
    authority: PublicKey,
    incrementUnit?: number,
    proportionTotalBankrollBps?: number,
): Promise<TransactionInstruction> => {
    return await updateHouseTokenIx(houseToken, authority, undefined, incrementUnit, proportionTotalBankrollBps)
}

export const addOrUpdateOracleListIx = async (
    house: House,
    oraclePubkey: PublicKey,
    remove: boolean, // ADD OR REMOVE THE ORACLE
    authority: PublicKey // AUTHORITY TO SIGN THE TXN
): Promise<TransactionInstruction> => {

    const permissionPubkey = House.deriverPermissionPubkey(
        house.publicKey,
        authority,
        house.baseProgram.programId
    );

    return await house.baseProgram.methods.houseOracleListAddOrUpdate({
        remove: remove
    }).accounts({
        payer: authority,
        authority: authority,
        permission: permissionPubkey,
        house: house.publicKey,
        oracleList: house.oracleListPubkey,
        oracle: oraclePubkey,
        systemProgram: SystemProgram.programId
    }).instruction()
};