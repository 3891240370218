import React, { useContext } from "react";
import Icon from "../../components/common/icon/Icon";
import AdminBodyItem from "../Containers/AdminBodyItem";
import { BalanceContext, BalanceType } from "../Contexts/BalanceContext";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import { truncatePubkey } from "../../utils/string/string";
import IconButton from "../../components/common/button/IconButton";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import TableGrid from "../../components/common/table-grid/TableGrid";

export const OverviewPage = () => {
  const { balanceByType, oracleListBalances } = useContext(BalanceContext)
  const { copyToClipboard } = useCopyToClipboard("Copied pubkey to clipboard")

  const formattedBalancesRows = [
    BalanceType.HOUSE_PAYER
  ].map((balanceType) => (
    {
      id: balanceType,
      classes: "",
      name: (<span className="capitalize whitespace-nowrap">{balanceType} Balance</span>),
      pubkey: (
        <div className="flex gap-1.5 items-center">
          {truncatePubkey(balanceByType?.get(balanceType)?.pubkey.toString() || '', 5)}
          <IconButton
            size="xs"
            iconName="copy"
            className="bg-transparent"
            onClick={() => copyToClipboard(balanceByType?.get(balanceType)?.pubkey.toString() || '')}
          />
        </div>
      ),
      balance: (
        <div className="flex gap-2 items-center">
          <Icon iconUrl="/static/tokens/sol.png" size="xl"/>
          <span className="">{
            formatZeebitNumber(
              balanceByType?.get(balanceType)?.solBalance || 0,
              NumberType.TOKEN_AMOUNT,
              undefined,
              8
            )
          }</span>
        </div>
      )
    }
  ));

  const formattedOracleBalancesRows = (oracleListBalances || []).map((balance, index) => (
    {
      id: balance.pubkey.toString(),
      classes: "",
      name: (<span className="capitalize whitespace-nowrap">{balance.type} {index} Balance</span>),
      pubkey: (
        <div className="flex gap-1.5 items-center">
          {truncatePubkey(balance?.pubkey.toString() || '', 5)}
          <IconButton
            size="xs"
            iconName="copy"
            className="bg-transparent"
            onClick={() => copyToClipboard(balance?.pubkey.toString() || '')}
          />
        </div>
      ),
      balance: (
        <div className="flex gap-2 items-center">
          <Icon iconUrl="/static/tokens/sol.png" size="xl"/>
          <span className="">{
            formatZeebitNumber(
              balance?.solBalance || 0,
              NumberType.TOKEN_AMOUNT,
              undefined,
              8
            )
          }</span>
        </div>
      )
    }
  ));

  return (
    <AdminBodyItem title="Key Balances">
      <TableGrid
        rows={[...formattedBalancesRows, ...formattedOracleBalancesRows]}
        columns={[
          {
            header: "Name",
            accessor : "name",
            classes : "text-base bg-none! border-b border-gray-500 first:rounded-l-none",
          },
          {
            header : "Pubkey",
            accessor : "pubkey",
            classes : "text-base bg-none! border-b border-gray-500 rounded-none",
          },
          {
            header : "Balance",
            accessor : "balance",
            classes : "text-base bg-none! border-b border-gray-500 rounded-none last:rounded-r-none",
          },
        ]}
        variant="regular"
        emptyMessage=" "
        onRowClick={(rowId) => {}}
      />
    </AdminBodyItem>
  );
};
