import { multiply } from "lodash";
import { IBetHistory } from "../../sdk/types";

export const toGameType = (gameType: object) => {
  if ("coinFlip" in gameType) {
    return "Coin Flip";
  } else if ("plinko" in gameType) {
    return "Plinko";
  } else if ("limbo" in gameType) {
    return "Limbo";
  } else if ("dice" in gameType) {
    return "Dice";
  } else if ("roulette" in gameType) {
    return "Roulette";
  } else if ("wheel" in gameType) {
    return "Wheel";
  } else if ("mines" in gameType) {
    return "Mines";
  } else if ("crash" in gameType) {
    return "Crash";
  } else if ("crashMulti" in gameType) {
    return "Crash Multi";
  } else if ("baccarat" in gameType) {
    return "Baccarat";
  } else if ("keno" in gameType) {
    return "Keno"
  } else if ("slotsThree" in gameType) {
    return "Zeebros Slots"
  } else if ("blackjack" in gameType) {
    return "BlackJack"
  } else if ("slide" in gameType) {
    return "Slide"
  } else if ("jackpot" in gameType) {
    return "Jackpot"
  } else if ("tower" in gameType) {
    return "Tower"
  } else if ("crashMulti" in gameType) {
    return "Crash Multi"
  }

  return "Unknown";
};

export const toMultiplier = (bet: IBetHistory) => {
  return bet.payout.toNumber() / bet.wager.toNumber();
};
