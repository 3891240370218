import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import Separator from "../../Components/Separator/Separator";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { ProgramContext } from "../../../contexts";
import { updateHouseIx } from "../../sdk/house";
import { sendTransaction } from "../../../sdk/transactions";
import { useWallet } from "@solana/wallet-adapter-react";
import DropdownInput from "../../../components/common/dropdown-input/DropdownInput";
import { SignerContext } from "../../Contexts/SignersContext";
import { HouseContext } from "../../Contexts/AdminHouseContext";

interface IEditStatusModalProps {
  visible: boolean;
  hideModal: Function;
}

export const EditStatusModal: FC<IEditStatusModalProps> = ({ visible, hideModal }) => {
  const { house, loadHouse } = useContext(HouseContext)
  const [noPermissions, setNoPermissions] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const { signTransaction } = useWallet();
  const { signerInput, signerInputError, authorityPubkey, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput } = useContext(SignerContext);
  // CLEAR INPUTS WHEN MODAL LOADS
  useEffect(() => {
    setPinInput(undefined);
    setSelectedStatus(undefined)
  }, [visible])

  const noPermissionsContent = useMemo(() => {
    return (
      <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
        <div className="font-white text-3xl font-oxanium">No Permissions</div>
        <div className="text-xl text-gray-300">
          No permissions have been granted to selected wallet. Please contact Zeebit’s team when
          requesting DevTools access.
        </div>
        <Button
          variant="secondary"
          onClick={() => {
            // WIPE ALL THE INPUTS
            // emptyInputs();
          }}
        >
          Try Another Wallet
        </Button>
      </div>
    );
  }, []);

  const { client } = useContext(NetworkContext)
  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext)
  const { meta } = useContext(ProgramContext)

  const updateHouseStatus = useCallback(async () => {
    if (signerInputError || house == null || selectedStatus == null || client == null) {
      console.error('Issue with inputs for set house status', house)

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const updateHouseIxn = await updateHouseIx(house, authorityPubkey, selectedStatus)
      const sig = await sendTransaction(
        [updateHouseIxn],
        client,
        authorityPubkey,
        true,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )

      toast(
        <BaseToast
          message={`Successfully updated house status to ${selectedStatus}: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE HOUSE
      await loadHouse()

      // CLEAR PIN AND SIGNER
      setPinInput(undefined)

      hideModal()
    } catch (err) {
      console.error('Issue updating house status', err)
      toast(
        <BaseToast
          message={`Issue updating house status, check logs. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [house, selectedSigner, selectedStatus, pinInput, client, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  return (
    <BaseModal open={visible} onClose={hideModal} title="Authentication">
      <div className="max-h-[400px] flex flex-col justify-center items-center text-gray-400 w-full">
        {noPermissions ? noPermissionsContent : ""}
        {!noPermissions ? (
          <div className="flex flex-col items-center gap-y-4 w-full">
            {/* Status */}
            <FormItem label="Choose Status" className="flex-1 self-stretch">
              <DropdownInput
                classes={{ button: "py-0 h-[42px] rounded font-normal border-2 focus:border-2 border-gray-600" }}

                options={[
                  { label: 'active', value: 'active' },
                  { label: 'frozen', value: 'frozen' },
                  { label: 'inFlowsSuspended', value: 'inFlowsSuspended' },
                  { label: 'outFlowsSuspended', value: 'outFlowsSuspended' },
                ]}
                selectedValue={selectedStatus}
                onSelect={setSelectedStatus}
              />
            </FormItem>
            <Separator />

            {/* Wallet */}
            {signerInput}

            {/* AUTH WALLET BUTTON */}
            <FormItem
              error={
                noPermissions ? "Selected wallet does not have permission for this action" : ""
              }
              className="flex flex-col gap-1 flex-1 self-stretch"
            >
              <Button
                isLoading={loading}
                disabled={house == null || signerInputError || selectedStatus == null || client == null}
                onClick={updateHouseStatus}
                variant="secondary"
              >
                Update House Status
              </Button>
            </FormItem>
          </div>
        ) : (
          ""
        )}
      </div>
    </BaseModal>
  );
};
