import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { SignerContext } from "../../Contexts/SignersContext";
import { DataCachingContext } from "../../Contexts/DataCachingContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { ReferralSettlementStatus } from "../../Pages/ReferralsPage";
import { fetchAdminReferralSettlements, processReferralSettlements } from "../../../utils/supabase/supabase";
import { createAssociatedTokenAccountIdempotentInstruction, createTransferInstruction, getAssociatedTokenAddressSync, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey, TransactionMessage, VersionedTransaction } from "@solana/web3.js";
import { USDC_DECIMALS, USDC_MINT } from "../../../constants/sol";
import { IReferralSettlement } from "../../../utils/supabase/types";
import * as bs58 from 'bs58'
import { IS_MAINNET } from "../../../utils/solana/rpc";
import { TOKEN_MINT_PUBKEY_MAINNET_SOLANA } from "../../../sdk/constants";
import { ToasterContext } from "../../../contexts";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";

export enum ReferralSettlementModalType {
    INITIAL = 'initial',
    RETRY = 'retry'
}

interface IAuthenticateModalProps {
    visible: boolean;
    hideModal: Function;
    type: ReferralSettlementModalType
}

// MODAL FOR AUTHENTICATING THE REFERRALS PAGE
export const ReferralSettlementsModal: FC<IAuthenticateModalProps> = ({ visible, hideModal, type }) => {
    const toast = useContext(ToasterContext);
    const { signerInput, signerInputError, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput, signers, authorityPubkey } = useContext(SignerContext);
    const { adminWallets } = useContext(DataCachingContext)
    const { publicKey, signTransaction } = useWallet()
    const noPermissions = useMemo(() => {
        if (connectedViaWallet) {
            const hasWallet = adminWallets.find((wallet) => {
                return wallet.pubkey == publicKey?.toString()
            })

            return hasWallet == null
        } else if (connectedViaKeyPair) {
            const adminPubkeys = adminWallets.map((wall) => {
                return wall.pubkey
            })
            const signerPubkeys = signers.map((signe) => {
                return signe.publicKey.toString()
            })
            const hasWallet = adminPubkeys.find((wallet) => {
                return signerPubkeys.includes(wallet)
            })

            return hasWallet == null
        }

        return true
    }, [adminWallets, publicKey, keypair, connectedViaWallet, connectedViaKeyPair])

    // CLEAR INPUTS WHEN MODAL LOADS
    useEffect(() => {
        setPinInput(undefined);
    }, [visible])

    const noPermissionsContent = useMemo(() => {
        return (
            <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
                <div className="font-white text-3xl font-oxanium">No Permissions</div>
                <div className="text-xl text-gray-300">
                    No permissions have been granted to selected wallet. Please contact Zeebit’s team when
                    requesting DevTools access.
                </div>
                <Button
                    variant="secondary"
                    onClick={() => {
                        // WIPE ALL THE INPUTS
                        // emptyInputs();
                    }}
                >
                    Try Another Wallet
                </Button>
            </div>
        );
    }, []);

    const { client, solanaClient } = useContext(NetworkContext)
    const [loading, setLoading] = useState(false)
    const { jwt } = useContext(DataCachingContext)

    // INITIAL PROCESSING
    const processPayments = useCallback(async () => {
        setLoading(true)
        let payload: any = {
            ...jwt
        }

        payload.status = ReferralSettlementStatus.APPROVED

        try {
            // LOAD RECORDS IN APPROVED STATUS, PAST END TIME, WITH NO SIG OR TX META
            const processableSettlements = await fetchAdminReferralSettlements(payload)

            // DB ROWS TO UPDATE
            const processed: IReferralSettlement[] = []

            // RAW SIGNED TXNS TO BE SENT
            const transactionsToSend: VersionedTransaction[] = []

            const blockhashMeta = await solanaClient.getLatestBlockhash("confirmed")
            const slot = await solanaClient.getSlot("confirmed")

            processableSettlements.forEach(async (settlement) => {
                // VALIDATE
                // STATUS
                const approvedStatus = settlement.status == ReferralSettlementStatus.APPROVED

                // END DATE
                const endDate = new Date(settlement.startDate)
                endDate.setMonth(endDate.getMonth() + 1)
                const currentDate = new Date()
                const pastEndDate = currentDate.getTime() > endDate.getTime()


                // NO SIG META
                const notSent = (settlement.settlementSignature == null || settlement.settlementSignature == '') && settlement.settlementTxnSlot == null && settlement.settlementTxnStatus == null

                // HAS REFERRAL RATE + OWNER
                const referralRate = settlement.referralRate
                const owner = settlement.owner
                const hasReferralRateAndOwner = referralRate != null && owner != null

                const canProcess = approvedStatus == true && pastEndDate == true && notSent == true && hasReferralRateAndOwner == true

                if (canProcess == true) {
                    // CHECK PNL
                    const thisMonthPnl = settlement.totalWagered - settlement.totalPaidOut
                    const lastMonthPnl = (settlement.previousMonthWagered != null ? settlement.previousMonthWagered : 0) - (settlement.previousMonthPaidOut != null ? settlement.previousMonthPaidOut : 0)
                    let aggregatePnl = 0

                    if (thisMonthPnl > 0) {
                        if (lastMonthPnl < 0) {
                            aggregatePnl = thisMonthPnl + lastMonthPnl
                        } else {
                            aggregatePnl = thisMonthPnl
                        }
                    }

                    const referralComission = aggregatePnl * (settlement.referralRate || 0)

                    if (referralComission > 0) {
                        // CREATE TRANSFER IXN
                        const USER_WALLET = new PublicKey(settlement.owner)
                        const referralCommissionBasis = Math.floor(referralComission * Math.pow(10, USDC_DECIMALS))

                        // DEVNET USDC
                        const USDC = IS_MAINNET ? TOKEN_MINT_PUBKEY_MAINNET_SOLANA : new PublicKey('BWvbxUTAxevm1NG8RHe1LhKmca9nz5ym2xqafTxr6ybj')

                        // CHECK THEY HAVE AN ATA - OTHERWISE ERROR
                        const userAta = getAssociatedTokenAddressSync(USDC, USER_WALLET)
                        const fromAta = getAssociatedTokenAddressSync(USDC, authorityPubkey)
                        const transferIx = createTransferInstruction(fromAta, userAta, authorityPubkey, referralCommissionBasis)

                        let ixns = []

                        if (IS_MAINNET == false) {
                            ixns.push(createAssociatedTokenAccountIdempotentInstruction(authorityPubkey, userAta, USER_WALLET, USDC))
                        }

                        ixns.push(transferIx)

                        // SIGN THE TRANSACTION
                        let messageV0 = new TransactionMessage({
                            payerKey: authorityPubkey,
                            recentBlockhash: blockhashMeta.blockhash,
                            instructions: ixns
                        }).compileToV0Message();

                        let transaction = new VersionedTransaction(messageV0);

                        if (keypair != null) {
                            transaction.sign([keypair]);
                        } else if (signTransaction != null) {
                            transaction = await signTransaction(transaction)
                        }

                        const sig58 = bs58.encode(transaction.signatures[0])

                        transactionsToSend.push(transaction)
                        processed.push({
                            startDate: settlement.startDate,
                            referralId: settlement.referralId,
                            chain: settlement.chain,
                            platform: settlement.platform,
                            id: settlement.id,
                            status: ReferralSettlementStatus.PROCESSING,
                            settlementSignature: sig58,
                            settlementTxnSlot: slot
                        })
                    } else {
                        processed.push({
                            startDate: settlement.startDate,
                            referralId: settlement.referralId,
                            chain: settlement.chain,
                            platform: settlement.platform,
                            id: settlement.id,
                            status: ReferralSettlementStatus.COMPLETED
                        })
                    }

                    // FORM TX + SIGN
                } else {
                    console.warn(`Cant process ${JSON.stringify(settlement)}`)
                }
            })

            // UPDATE DB ROWS

            if (processed.length > 0) {
                const dbUpdate = await processReferralSettlements({
                    jwt: jwt?.jwt,
                    records: processed
                })

                console.log({
                    dbUpdate,
                    processed
                })

                // SEND TRANSACTIONS
                if (transactionsToSend.length > 0) {
                    const sigs = await Promise.allSettled(transactionsToSend.map((txn) => {
                        return solanaClient.sendRawTransaction(txn.serialize())
                    }))

                    console.log({
                        sigs
                    })
                }
            }

            toast(
                    <BaseToast
                        message={`Successfully processed settlements.`}
                        type={"success"}
                    />,
                    BASE_TOAST_CONFIG,
                );

            // REFRESH DATA + CLOSE
            hideModal()
        } catch (err) {
            console.warn(`Issue processing referral payments`, { err })
            toast(
                <BaseToast
                    message={`Issue processing settlements. ${JSON.stringify(err)}`}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
            // show a toast here
        } finally {
            setLoading(false)
        }
    }, [jwt, solanaClient, keypair, authorityPubkey, signTransaction, hideModal, toast])

    // PROCESS RETRIES

    const processRetries = useCallback(async () => {
        setLoading(true)
        let payload: any = {
            ...jwt
        }

        payload.status = ReferralSettlementStatus.READY_FOR_RETRY

        try {
            // LOAD RECORDS IN APPROVED STATUS, PAST END TIME, WITH NO SIG OR TX META
            const retryable = await fetchAdminReferralSettlements(payload)

            // DB ROWS TO UPDATE
            const processed: IReferralSettlement[] = []

            // RAW SIGNED TXNS TO BE SENT
            const transactionsToSend: VersionedTransaction[] = []

            const blockhashMeta = await solanaClient.getLatestBlockhash("confirmed")
            const slot = await solanaClient.getSlot("confirmed")

            retryable.forEach(async (settlement) => {
                // VALIDATE
                // STATUS
                const readyForReviewStatus = settlement.status == ReferralSettlementStatus.READY_FOR_RETRY

                // END DATE
                const endDate = new Date(settlement.startDate)
                endDate.setMonth(endDate.getMonth() + 1)
                const currentDate = new Date()
                const pastEndDate = currentDate.getTime() > endDate.getTime()

                // NO SIG META
                // TODO - CAN BE SENT, BUT TXN NOT VISIBLE OR ERROR
                const txnSignature = settlement.settlementSignature
                const txnSlot = settlement.settlementTxnSlot
                let staleSlot = false

                if (txnSignature == null || txnSlot == null) {
                    console.warn(`Not loading txn info.`);
                } else {
                    const txnMeta = await solanaClient.getSignatureStatus(txnSignature, { searchTransactionHistory: true })
                    const hasError = txnMeta.value?.err != null
                    const isConfirmed = txnMeta.value?.confirmationStatus != null
                    
                    if (hasError == true) {
                        processed.push({
                            ...settlement,
                            status: ReferralSettlementStatus.ERROR
                        })
                        return
                    } else if (isConfirmed == true) {
                        processed.push({
                            ...settlement,
                            status: ReferralSettlementStatus.COMPLETED,
                            settlementTxnStatus: txnMeta.value?.confirmationStatus
                        })
                        return
                    } else {
                        // check for stale slot
                        staleSlot = slot > (txnSlot + 200)
                    }
                }

                // HAS REFERRAL RATE + OWNER
                const referralRate = settlement.referralRate
                const owner = settlement.owner
                const hasReferralRateAndOwner = referralRate != null && owner != null
                const passedSlotCheck = (settlement.settlementTxnSlot == null && settlement.settlementSignature == null) || staleSlot == true

                const canProcess = readyForReviewStatus == true && pastEndDate == true && passedSlotCheck == true && hasReferralRateAndOwner == true

                if (canProcess == true) {
                    // CHECK PNL
                    const thisMonthPnl = settlement.totalWagered - settlement.totalPaidOut
                    const lastMonthPnl = (settlement.previousMonthWagered != null ? settlement.previousMonthWagered : 0) - (settlement.previousMonthPaidOut != null ? settlement.previousMonthPaidOut : 0)
                    let aggregatePnl = 0

                    if (thisMonthPnl > 0) {
                        if (lastMonthPnl < 0) {
                            aggregatePnl = thisMonthPnl + lastMonthPnl
                        } else {
                            aggregatePnl = thisMonthPnl
                        }
                    }

                    const referralComission = aggregatePnl * referralRate

                    if (referralComission > 0) {
                        // CREATE TRANSFER IXN
                        const USER_WALLET = new PublicKey(settlement.owner)
                        const referralCommissionBasis = Math.floor(referralComission * Math.pow(10, USDC_DECIMALS))

                        // CHECK THEY HAVE AN ATA - OTHERWISE ERROR
                        const userAta = getAssociatedTokenAddressSync(USDC_MINT, USER_WALLET, false)
                        const fromAta = getAssociatedTokenAddressSync(USDC_MINT, authorityPubkey, false)
                        const transferIx = createTransferInstruction(fromAta, userAta, authorityPubkey, referralCommissionBasis)

                        // SIGN THE TRANSACTION
                        let messageV0 = new TransactionMessage({
                            payerKey: authorityPubkey,
                            recentBlockhash: blockhashMeta.blockhash,
                            instructions: [
                                createAssociatedTokenAccountIdempotentInstruction(authorityPubkey, userAta, USER_WALLET, USDC_MINT),
                                transferIx
                            ]
                        }).compileToV0Message();

                        let transaction = new VersionedTransaction(messageV0);

                        if (keypair != null) {
                            transaction.sign([keypair]);
                        } else if (signTransaction != null) {
                            transaction = await signTransaction(transaction)
                        }

                        const sig58 = bs58.encode(transaction.signatures[0])

                        transactionsToSend.push(transaction)
                        processed.push({
                            startDate: settlement.startDate,
                            referralId: settlement.referralId,
                            chain: settlement.chain,
                            platform: settlement.platform,
                            id: settlement.id,
                            status: ReferralSettlementStatus.PROCESSING,
                            settlementSignature: sig58,
                            settlementTxnSlot: slot
                        })
                    } else {

                        processed.push({
                            startDate: settlement.startDate,
                            referralId: settlement.referralId,
                            chain: settlement.chain,
                            platform: settlement.platform,
                            id: settlement.id,
                            status: ReferralSettlementStatus.COMPLETED
                        })
                    }

                    if (processed.length > 0) {
                        const dbUpdate = await processReferralSettlements({
                            jwt: jwt?.jwt,
                            records: processed
                        })
        
                        console.log({
                            dbUpdate,
                            processed
                        })
        
                        // SEND TRANSACTIONS
                        if (transactionsToSend.length > 0) {
                            const sigs = await Promise.allSettled(transactionsToSend.map((txn) => {
                                return solanaClient.sendRawTransaction(txn.serialize())
                            }))
        
                            console.log({
                                sigs
                            })
                        }
                    }
        
                    // REFRESH DATA + CLOSE
                    hideModal()
                } else {
                    console.warn(`Cant process ${JSON.stringify({
                        settlement,
                        hasReferralRateAndOwner,
                        passedSlotCheck,
                        slot,
                    })}`)
                }
            })

            toast(
                <BaseToast
                    message={`Success processing settlement retries.`}
                    type={"success"}
                />,
                BASE_TOAST_CONFIG,
            );

            hideModal()
        } catch (err) {
            console.warn(`Issue processing referral payments`, { err })
            toast(
                <BaseToast
                    message={`Issue processing settlement retries. ${JSON.stringify(err)}`}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
        } finally {
            setLoading(false)
        }
    }, [jwt, solanaClient, keypair, authorityPubkey, signTransaction, toast, hideModal])

    return (
        <BaseModal open={visible} onClose={hideModal} title={`Referral Settlements (${type})`}>
            <div className="max-h-[400px] flex flex-col justify-center items-center text-gray-400 w-full">
                {noPermissions ? noPermissionsContent : ""}
                {!noPermissions ? (
                    <div className="flex flex-col items-center gap-y-4 w-full">

                        {/* Wallet */}
                        {signerInput}

                        {/* AUTH WALLET BUTTON */}
                        <FormItem
                            error={
                                noPermissions ? "Selected wallet does not have permission for this action" : ""
                            }
                            className="flex flex-col gap-1 flex-1 self-stretch"
                        >
                            {type == 'initial' ? <Button
                                isLoading={loading}
                                disabled={signerInputError || client == null}
                                onClick={processPayments}
                                variant="secondary"
                            >
                                Process initial payments
                            </Button> : ''}
                            {type == 'retry' ? <Button
                                isLoading={loading}
                                disabled={signerInputError || client == null}
                                onClick={processRetries}
                                variant="secondary"
                            >
                                Process retries
                            </Button> : ''}
                        </FormItem>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </BaseModal>
    );
};
