import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { fundDistributionIx, unFundDistributionIx } from "../../sdk/nfStaking";
import { getS3StaticFolderUrl } from "../../../utils/config/utils";
import { sendTransaction } from "../../../sdk/transactions";
import { ProgramContext } from "../../../contexts";
import { NftStakingContext } from "../../Contexts/NftStakingContext";
import { BalanceInput } from "../../Components/BalanceInput/BalanceInput";
import { useWallet } from "@solana/wallet-adapter-react";
import { SignerContext } from "../../Contexts/SignersContext";
import { APP_NETWORK_TYPE } from "../../../types/chain";

export interface DistributionBalances {
  unstaking: number,
  royalties: number
  platform: number,
  dicretionary: number,
  rollover: number
}

export enum FundModalType {
  FUND = 'fund',
  UN_FUND = 'un-fund'
}

interface IFundModalProps {
  visible: boolean;
  hideModal: Function;
  type: FundModalType | undefined
  balances: DistributionBalances
}

export const FundModal: FC<IFundModalProps> = ({
  visible,
  hideModal,
  type,
  balances
}) => {
  const { signerInput, signerInputError, authorityPubkey, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput } = useContext(SignerContext);
  const { signTransaction } = useWallet();

  const { solanaClient } = useContext(NetworkContext)
  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext)
  const { nftStaking, updateNftStaking } = useContext(NftStakingContext)

  // BE CAREFUL THESE ARE UI VALUES
  const [platformShare, setPlatformShare] = useState(0)
  const [royaltiesShare, setRoyaltiesShare] = useState(0)
  const [unstakingShare, setUnstakingShare] = useState(0)
  const [discretionaryAmount, setDiscretionaryAmount] = useState(0)

  const platformShareBasis = useMemo(() => {
    return platformShare * Math.pow(10, 6)
  }, [platformShare])
  const royaltiesShareBasis = useMemo(() => {
    return royaltiesShare * Math.pow(10, 6)
  }, [royaltiesShare])
  const unstakingShareBasis = useMemo(() => {
    return unstakingShare * Math.pow(10, 6)
  }, [unstakingShare])
  const discretionaryAmountBasis = useMemo(() => {
    return discretionaryAmount * Math.pow(10, 6)
  }, [discretionaryAmount])

  const { meta } = useContext(ProgramContext);

  useEffect(() => {
    setPinInput(undefined);
  }, [visible])

  const fundOrUnFundDistribution = useCallback(async () => {
    if (signerInputError || solanaClient == null || nftStaking == null) {
      console.error('Issue with inputs for crankAttributeSelect')

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const ix = type == FundModalType.FUND ? await fundDistributionIx(nftStaking, authorityPubkey, platformShareBasis, royaltiesShareBasis, unstakingShareBasis, discretionaryAmountBasis) : await unFundDistributionIx(nftStaking, authorityPubkey, platformShareBasis, royaltiesShareBasis, unstakingShareBasis, discretionaryAmountBasis)

      const sig = await sendTransaction(
        [ix],
        solanaClient,
        authorityPubkey,
        true,
        APP_NETWORK_TYPE.MB_AND_SOLANA,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )

      toast(
        <BaseToast
          message={`Successfully funded the distribution: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE NFT STAKING
      await updateNftStaking()

      // CLEAR PIN AND SIGNER
      setPinInput(undefined);

      hideModal()
    } catch (err) {
      console.error(err)
      console.error(`Issue ${type == FundModalType.FUND ? 'funding' : 'un-funding'} the distrition`, err)
      toast(
        <BaseToast
          message={`Issue ${type == FundModalType.FUND ? 'funding' : 'un-funding'} the distribution. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, solanaClient, pinInput, nftStaking, platformShareBasis, royaltiesShareBasis, unstakingShareBasis, discretionaryAmountBasis, updateNftStaking, type, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  return (
    <BaseModal open={visible} onClose={hideModal} title={`${type == FundModalType.FUND ? 'Fund Distribution' : 'Un-fund Distribution'}`}>
      <div className=" flex flex-col justify-center items-center text-gray-400 w-full">

        <div className="flex flex-col items-center gap-y-4 w-full">
          <FormItem
            className="flex-1 self-stretch"
            label="Platform Share (UI)"
            rightLabel={
              <div className="flex gap-1.5 items-center">
                {/* <Icon iconUrl={lpMeta?.token?.context?.imageDarkPng} size="sm" /> */}
                ${balances.platform / Math.pow(10, 6)}
              </div>
            }
          >
            <BalanceInput balance={platformShare} setBalance={setPlatformShare} icon={getS3StaticFolderUrl("/static/tokens/usdc.svg")} />
          </FormItem>

          <FormItem
            className="flex-1 self-stretch"
            label="Royalties Share (UI)"
            rightLabel={
              <div className="flex gap-1.5 items-center">
                {/* <Icon iconUrl={lpMeta?.token?.context?.imageDarkPng} size="sm" /> */}
                ${balances.royalties / Math.pow(10, 6)}
              </div>
            }
          >
            <BalanceInput
              balance={royaltiesShare}
              setBalance={setRoyaltiesShare}
              icon={getS3StaticFolderUrl("/static/tokens/usdc.svg")}
            />
          </FormItem>

          <FormItem
            className="flex-1 self-stretch"
            label="Unstaking Share (UI)"
            rightLabel={
              <div className="flex gap-1.5 items-center">
                {/* <Icon iconUrl={lpMeta?.token?.context?.imageDarkPng} size="sm" /> */}
                ${balances.unstaking / Math.pow(10, 6)}
              </div>
            }
          >
            <BalanceInput
              balance={unstakingShare}
              setBalance={setUnstakingShare}
              icon={getS3StaticFolderUrl("/static/tokens/usdc.svg")}
            />
          </FormItem>

          <FormItem
            className="flex-1 self-stretch"
            label="Discretionary Amount (UI)"
            rightLabel={
              <div className="flex gap-1.5 items-center">
                {/* <Icon iconUrl={lpMeta?.token?.context?.imageDarkPng} size="sm" /> */}
                ${balances.dicretionary / Math.pow(10, 6)}
              </div>
            }
          >
            <BalanceInput
              balance={discretionaryAmount}
              setBalance={setDiscretionaryAmount}
              icon={getS3StaticFolderUrl("/static/tokens/usdc.svg")}
            />
          </FormItem>

          {/* Wallet */}
          {signerInput}

          {/* AUTH WALLET BUTTON */}
          <Button disabled={nftStaking == null || signerInputError}
            onClick={fundOrUnFundDistribution} isLoading={loading} variant="secondary" size="sm">
            {`${type == FundModalType.FUND ? 'Fund' : 'Un-fund'} distribution`}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
