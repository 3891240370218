import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import House from "../../sdk/house";
import {
  getHousePubkey,
} from "../..//sdk/constants";
import { Program } from "@coral-xyz/anchor";
import HouseToken from "../../sdk/houseToken";
import { NetworkContext } from "../../contexts/NetworkContext";
import { APP_NETWORK_TYPE } from "../../types/chain";
import { ProgramContext } from "../../contexts/ProgramContext";
import * as base58 from 'bs58'
import { sha256 } from "js-sha256";
import { DELEGATION_PROGRAM_ID } from "@magicblock-labs/delegation-program";
import { PublicKey } from "@solana/web3.js";

export interface IHouseValidation {
  takingBets: boolean;
  tokenTakingBets: boolean
}

export interface IHouseContext {
  house: House | undefined;
  houseLoaded: boolean;
  loadHouse: (program?: Program) => Promise<void>
  houseTokens: HouseToken[] | undefined
  houseTokensLoaded: boolean;
  houseTokenByMint: Map<string, HouseToken> | undefined
  houseTokenByPubkey: Map<string, HouseToken> | undefined
  houseUptoDateWithChain: boolean
  housePubkey: PublicKey
  setHousePubkey: React.Dispatch<React.SetStateAction<PublicKey | undefined>>
}

export const HouseContext = createContext<IHouseContext>({} as IHouseContext);

interface Props {
  children: any;
}

export const HouseProvider = ({ children }: Props) => {
  const [house, setHouse] = useState<House>();
  const [houseTokens, setHouseTokens] = useState<HouseToken[]>()
  
  const [housePubkey, setHousePubkey] = useState<PublicKey>()

  const [houseLoaded, setHouseLoaded] = useState(false);
  const [houseTokensLoaded, setHouseTokensLoaded] = useState(false);

  const { meta, isUptoDateWithChain } = useContext(ProgramContext);
  const { chain, uptoDateWithChain } = useContext(NetworkContext)

  useEffect(() => {
    setHousePubkey(getHousePubkey(chain))
  }, [chain])

  const [houseUptoDateWithChain, setHouseUptoDateWithChain] = useState<boolean>()
  const [chainLoaded, setChainLoaded] = useState<APP_NETWORK_TYPE>()
  
  const [houseTokenByMint, setHouseTokenByMint] = useState<Map<string, HouseToken>>()
  const [houseTokenByPubkey, setHouseTokenByPubkey] = useState<Map<string, HouseToken>>()

  const loadHouse = useCallback(async (program?: Program, erProgram?: Program, chainIn?: APP_NETWORK_TYPE) => {
    try {
      setHouseLoaded(false)
      
      const baseProgram = program || meta?.zeebitV2Program
      const rollupProgram = erProgram || meta?.zeebitV2ErProgram
      const chainVal = chainIn || chain

      const housePubkeyCalc = housePubkey || getHousePubkey(chainVal)

      const house = await House.load(baseProgram, rollupProgram, housePubkeyCalc);

      setHouse(house);
    } catch (e) {
      console.warn(`Issue loading the house from chain.`, e);
    } finally {
      setHouseLoaded(true);
    }
  }, [meta?.zeebitV2Program, meta?.zeebitV2ErProgram, chain, housePubkey])

  useEffect(() => {
    async function houseLoadAndTokens(baseProgram: Program, rollupProgram: Program, chain: APP_NETWORK_TYPE, housePubkeyInput?: PublicKey) {
      try {
        setHouseLoaded(false)

        const housePubkeyToUse = housePubkeyInput || getHousePubkey(chain)
        const house = await House.load(baseProgram, rollupProgram, housePubkeyToUse);
        setHouse(house);

        const baseHouseTokens = await baseProgram.account.houseToken.all()
        const erHouseTokensBuffers = await baseProgram.provider.connection.getProgramAccounts(new PublicKey(DELEGATION_PROGRAM_ID), {
            filters: [
              {
                memcmp: {
                  offset: 0, 
                  bytes: base58.encode(
                    Buffer.from(sha256.digest("account:HouseToken")).subarray(0, 8)
                  ),
                },
              }
            ]
        })
        const erHouseTokens = erHouseTokensBuffers.map((buffer) => {
            return {
                publicKey: buffer.pubkey,
                account: baseProgram.coder.accounts.decode('houseToken', buffer.account.data)
            }
        })

        const houseTokenByPubkey = new Map();
        baseHouseTokens.filter((token) => {
          return token.account.house?.toString() == housePubkeyToUse.toString()
        }).forEach((hseToken) => {
            houseTokenByPubkey.set(hseToken.publicKey.toString(), {
                base: hseToken.account,
                er: undefined,
                tokenMint: hseToken.account.tokenMint
            })
        })
        erHouseTokens.filter((token) => {
          return token.account.house?.toString() == housePubkeyToUse.toString()
        }).forEach((hseToken) => {
            if (houseTokenByPubkey.has(hseToken.publicKey.toString())) {
                let hseTokenMeta = houseTokenByPubkey.get(hseToken.publicKey.toString())
                hseTokenMeta.er = hseToken.account


                houseTokenByPubkey.set(hseToken.publicKey.toString(), hseTokenMeta)
            } else {
                houseTokenByPubkey.set(hseToken.publicKey.toString(), {
                    er: hseToken.account,
                    base: hseToken.account,
                    tokenMint: hseToken.account.tokenMint
                })
            }
        })

        const metas = Array.from(houseTokenByPubkey.values())
        console.log({
            metas
        })
  
        // USE ANY THAT ARE FOUND
        let hseTokens: HouseToken[] = await Promise.all(metas.map((meta) => {
          return HouseToken.loadFromState(house, meta.tokenMint, meta.base, meta.er)
        }))
  
        setHouseTokens(hseTokens)

        const hseTokenByMint = hseTokens?.reduce((result, item) => {
          result.set(item.tokenMintPubkey.toString(), item)
    
          return result
        }, new Map<string, HouseToken>())

        setHouseTokenByMint(hseTokenByMint)

        setHouseTokenByPubkey(hseTokens?.reduce((result, item) => {
          result.set(item.publicKey.toString(), item)
    
          return result
        }, new Map<string, HouseToken>()))
      } catch (err) {
        console.warn({
          err
        })
      } finally {
        setHouseLoaded(true)
        setHouseTokensLoaded(true)
        setChainLoaded(chain)
      }
    }
    if (meta == null || meta.zeebitV2ErProgram == null || meta.zeebitV2Program == null || chain == null || isUptoDateWithChain == false || uptoDateWithChain == false) {
      return;
    }
    
    houseLoadAndTokens(meta.zeebitV2Program, meta.zeebitV2ErProgram, chain, housePubkey);
  }, [meta, isUptoDateWithChain, uptoDateWithChain, housePubkey]);

  useEffect(() => {
    if (chainLoaded != chain && houseUptoDateWithChain == true) {
      setHouseUptoDateWithChain(false)
    } else if (chainLoaded == chain && houseUptoDateWithChain == false) {
      setHouseUptoDateWithChain(true)
    }
  }, [chain, house, houseTokens])

  return (
    <HouseContext.Provider
      value={useMemo(
        () => ({
          house: house,
          houseLoaded: houseLoaded,
          loadHouse: loadHouse,
          houseTokens: houseTokens,
          houseTokensLoaded: houseTokensLoaded,
          houseTokenByMint: houseTokenByMint,
          houseTokenByPubkey: houseTokenByPubkey,
          houseUptoDateWithChain: houseUptoDateWithChain,
          housePubkey: housePubkey,
          setHousePubkey: setHousePubkey
        }),
        [house, houseLoaded, loadHouse, houseTokensLoaded, houseTokenByMint, houseTokens, houseTokenByPubkey, houseUptoDateWithChain, housePubkey, setHousePubkey],
      )}
    >
      {children}
    </HouseContext.Provider>
  );
};
