import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Input from "../../../components/common/input/Input";
import Button from "../../../components/common/button/Button";
import Separator from "../../Components/Separator/Separator";
import { NetworkContext } from "../../../contexts/NetworkContext";
import Game from "../../../sdk/gameSpec";
import { IPlatformGame } from "../../../types/game";
import { addGameSpecTokenIxn, updateGameSpecIx, updateGameSpecStatusIx } from "../../sdk/gameSpec";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import Icon from "../../../components/common/icon/Icon";
import { CasinoSwitch } from "../../../components/common/switch/switch";
import { ImageStatic } from "../../../components/common/image/ImageStatic";
import Pubkey from "../../Components/TableCells/Pubkey";
import { toGameType } from "../../../utils/history/game";
import GameSpec from "../../../sdk/gameSpec";
import { sendTransaction } from "../../../sdk/transactions";
import { ProgramContext } from "../../../contexts";
import { PublicKey } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import DropdownInput from "../../../components/common/dropdown-input/DropdownInput";
import { SignerContext } from "../../Contexts/SignersContext";
import { HouseContext } from "../../Contexts/AdminHouseContext";

export interface IGameEditInput {
    game: Game | undefined
    context: IPlatformGame | undefined
}

export enum TransferModalType {
    WITHDRAW = 'withdraw',
    DEPOSIT = 'deposit'
}

export enum GameEditModalType {
    STATUS = 'status',
    CONFIG = 'config',
    TOKEN_SUPPORT = 'tokenSupport',
    DELEGATION = "delegation",
}

interface IGameConfigurationModalProps {
    visible: boolean;
    hideModal: Function;
    game: IGameEditInput | undefined
    type: GameEditModalType | undefined
    loadGames: () => Promise<void>
}

export const GameConfigurationModal: FC<IGameConfigurationModalProps> = ({
    visible,
    hideModal,
    game,
    type,
    loadGames
}) => {
    const { platformTokenMetaByPubkey } = useContext(NetworkContext);
    const [noPermissions, setNoPermissions] = useState(false);
    const gameDisplayName = toGameType(game.game?.state.game);

    const noPermissionsContent = useMemo(() => {
        return (
            <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
                <div className="font-white text-3xl font-oxanium">No Permissions</div>
                <div className="text-xl text-gray-300">
                    No permissions have been granted to selected wallet. Please contact Zeebit’s team when
                    requesting DevTools access.
                </div>
                <Button
                    variant="secondary"
                    onClick={() => {
                        // WIPE ALL THE INPUTS
                        // emptyInputs();
                    }}
                >
                    Try Another Wallet
                </Button>
            </div>
        );
    }, []);

    const { publicKey: walletPubKey, signTransaction, ...wallet } = useWallet();
    const { connectedViaKeyPair, connectedViaWallet, setPinInput, authorityPubkey, keypair, signerInputError, signerInput, selectedSigner, pinInput } = useContext(SignerContext);

    const [selectedGameStatus, setSelectedGameStatus] = useState<string>()
    const { client, chain } = useContext(NetworkContext)
    const { house } = useContext(HouseContext)
    const [loading, setLoading] = useState(false)
    const toast = useContext(ToasterContext);
    const { meta } = useContext(ProgramContext);

    useEffect(() => {
        setPinInput(undefined);
    }, [visible])

    const updateStatus = useCallback(async () => {
        if (signerInputError || house == null || game?.game == null || selectedGameStatus == null || client == null) {
            console.error('Issue with inputs for set game status')
            toast(
                <BaseToast
                    message={"Issue with inputs for set game status."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
            return
        }

        setLoading(true)

        try {
            const ix = await updateGameSpecStatusIx(game.game, authorityPubkey, selectedGameStatus)
            const sig = await sendTransaction(
                [ix],
                client,
                authorityPubkey,
                true,
                chain,
                meta?.errorByCodeByProgram,
                undefined,
                connectedViaWallet ? signTransaction : undefined,
                connectedViaKeyPair ? keypair : undefined,
                undefined,
                "confirmed"
            )

            toast(
                <BaseToast
                    message={`Successfully updated game status to ${selectedGameStatus}: ${sig}`}
                    icon={<Icon size="lg" name="link" />}
                    type={"success"}
                />,
                BASE_TOAST_CONFIG,
            );

            // lOAD THE GAMES
            await loadGames()

            // CLEAR PIN AND SIGNER
            setPinInput(undefined)

            hideModal()
        } catch (err) {
            console.error('Issue updating game status', err)
            toast(
                <BaseToast
                    message={"Issue updating game status."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
        }

        setLoading(false)
    }, [selectedSigner, selectedGameStatus, client, pinInput, game, loadGames, meta?.errorByCodeByProgram, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError, chain]);

    // EDIT STATUS FORM
    const editStatusForm = (
        <FormItem label="Choose Status" className="flex-1 self-stretch">
            <DropdownInput
                classes={{ button: "py-0 h-[42px] rounded font-normal border-2 focus:border-2 border-gray-600" }}

                options={[
                    { label: 'active', value: 'active' },
                    { label: 'frozen', value: 'frozen' },
                    { label: 'inFlowsSuspended', value: 'inFlowsSuspended' },
                    { label: 'outFlowsSuspended', value: 'outFlowsSuspended' },
                    { label: 'uninitialized', value: 'uninitialized' }
                ]}
                selectedValue={selectedGameStatus}
                onSelect={setSelectedGameStatus}
            />
        </FormItem>
    )

    // TOKEN SUPPORT FORM
    const [multitokenAlowed, seMultitokenAlowed] = useState(false);
    const [tokensConfig, setTokensConfig] = useState<{ pubkey: string, active: boolean }[]>([]);
    const [newTokenMint, setNewTokenMint] = useState('');
    const newTokenPubkey = useMemo(() => {
        try {
            return new PublicKey(newTokenMint)
        } catch (err) { }
    }, [newTokenMint])

    useEffect(() => {
        if (tokensConfig.length == 0) {
            setTokensConfig(Array.from(game?.game?.supportedTokens)?.map((token) => ({ pubkey: token[0], active: Object.keys(token[1].status)[0] === "active" })))
        }
    }, [tokensConfig, game]);
    useEffect(() => {
        setTokensConfig(Array.from(game?.game?.supportedTokens)?.map((token) => ({ pubkey: token[0], active: Object.keys(token[1].status)[0] === "active" })))
    }, [game, gameDisplayName]);

    const addGameSpecToken = useCallback(async () => {
        if (signerInputError || house == null || game?.game == null || selectedGameStatus == null || newTokenPubkey) {
            console.error('Issue with inputs for set game status')
            toast(
                <BaseToast
                    message={"Issue with inputs for set game status."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
            return
        }

        setLoading(true)

        try {
            const ix = await addGameSpecTokenIxn(house, newTokenPubkey, game.game, selectedGameStatus, authorityPubkey)
            const sig = await sendTransaction(
                [ix],
                client,
                authorityPubkey,
                true,
                chain,
                meta?.errorByCodeByProgram,
                undefined,
                connectedViaWallet ? signTransaction : undefined,
                connectedViaKeyPair ? keypair : undefined,
                undefined,
                "confirmed"
            )

            toast(
                <BaseToast
                    message={`Successfully added game spec token ${newTokenPubkey?.toString()}: ${sig}`}
                    icon={<Icon size="lg" name="link" />}
                    type={"success"}
                />,
                BASE_TOAST_CONFIG,
            );

            // lOAD THE GAMES
            await loadGames()

            // CLEAR PIN AND SIGNER
            setPinInput(undefined)

            hideModal()
        } catch (err) {
            console.error('Issue adding game spec token', err)
            toast(
                <BaseToast
                    message={"Issue adding game spec token."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
        }

        setLoading(false)
    }, [selectedSigner, selectedGameStatus, client, pinInput, game, loadGames, meta?.errorByCodeByProgram, signTransaction, newTokenPubkey, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError, chain]);

    const tokenSupportForm = (
        <>
            <FieldItem
                title={`Multitoken Support (${multitokenAlowed ? "On" : "Off"})`}
                input={<CasinoSwitch
                    disabled={true}
                    checked={multitokenAlowed}
                    onChange={seMultitokenAlowed}
                />} />
            <div className="flex flex-col gap-3 bg-gray-600 rounded-lg items-start w-full p-4">
                <div className="text-sm text-gray-300">House Token List</div>
                {
                    Array.from(tokensConfig)?.map((token) => {
                        const tokenMeta = platformTokenMetaByPubkey.get(token.pubkey);
                        const tokenImg = tokenMeta?.imageDarkPng;
                        const tokenConfigIdx = tokensConfig.findIndex((tkn) => tkn.pubkey == token.pubkey);
                        return (
                            <>
                                <div className="grid grid-cols-3 w-full justify-between items-center">
                                    <div className="flex text-gray-50 gap-2"> <ImageStatic url={tokenImg} />{tokenMeta?.symbol}</div>
                                    <Pubkey pubkey={token.pubkey} />
                                    <div className="ms-auto"><CasinoSwitch
                                        disabled={true}
                                        checked={tokensConfig[tokenConfigIdx]?.active}
                                        onChange={() => {
                                            const newTokenConfig = { pubkey: token.pubkey, active: !tokensConfig[tokenConfigIdx]?.active };
                                            const newConfig = [...tokensConfig];
                                            newConfig[tokenConfigIdx] = newTokenConfig;
                                            setTokensConfig(newConfig);
                                        }}
                                    /></div>
                                </div>
                                <Separator className='border-gray-500' />
                            </>
                        )
                    })
                }
                {signerInput}
                <div className="flex gap-3 w-full">
                    <Input classes={{ wrapper: "w-full" }} value={newTokenMint} onChange={(e) => {
                        setNewTokenMint(e.target.value);
                    }} placeholder="Input Mint Address" />
                    <Button disabled={newTokenPubkey == null || selectedSigner == null || pinInput == null} onClick={addGameSpecToken}>Add</Button>
                </div>
            </div>

        </>
    )

    // GAME CONFIG FORM
    const [gameConfig, setGameConfig] = useState<GameSpec["state"]>({});
    useEffect(() => {
        setGameConfig(game?.game?.state)
    }, [game, gameDisplayName]);


    const updateConfig = useCallback(async () => {
        if (signerInputError || house == null || game?.game == null || selectedGameStatus == null || newTokenPubkey) {
            console.error('Issue with inputs for set game status')
            toast(
                <BaseToast
                    message={"Issue with inputs for set game status."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
            return
        }

        setLoading(true)
        try {
            if (authorityPubkey == null) return;
            const ix = await updateGameSpecIx(
                game.game,
                authorityPubkey,
                undefined,
                undefined,
                gameConfig.minWagerInHouseTokenUnits != null ? Number(gameConfig.minWagerInHouseTokenUnits) : undefined,
                gameConfig.maxBetsPerInstanceToken != null ? Number(gameConfig.maxBetsPerInstanceToken) : undefined,
                gameConfig.maxBetsPerPlaceIxn != null ? Number(gameConfig.maxBetsPerPlaceIxn) : undefined,
                gameConfig.maxBetsPerSettleIxn != null ? Number(gameConfig.maxBetsPerSettleIxn) : undefined,
                gameConfig.maxPlayersPerToken != null ? Number(gameConfig.maxPlayersPerToken) : undefined,
                gameConfig.maxTokensPerInstance != null ? Number(gameConfig.maxTokensPerInstance) : undefined,
                gameConfig.inactivityTimeoutSeconds != null ? Number(gameConfig.inactivityTimeoutSeconds) : undefined
            )
            const sig = await sendTransaction(
                [ix],
                client,
                authorityPubkey,
                true,
                chain,
                meta?.errorByCodeByProgram,
                undefined,
                connectedViaWallet ? signTransaction : undefined,
                connectedViaKeyPair ? keypair : undefined,
                undefined,
                "confirmed"
            )

            toast(
                <BaseToast
                    message={`Successfully updated game config: ${sig}`}
                    icon={<Icon size="lg" name="link" />}
                    type={"success"}
                />,
                BASE_TOAST_CONFIG,
            );

            // lOAD THE GAMES
            await loadGames()

            // CLEAR PIN AND SIGNER
            setPinInput(undefined)

            hideModal()
        } catch (err) {
            console.error('Issue updating game config', err)
            toast(
                <BaseToast
                    message={"Issue updating game config."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
        }

        setLoading(false)
    }, [selectedSigner, selectedGameStatus, client, pinInput, game, loadGames, meta?.errorByCodeByProgram, gameConfig, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError, chain]);

    const configForm = (
        <>
            <FieldItem
                title="Min Wager"
                subtitle="in house token units"
                input={
                    <Input
                        name="minWagerInHouseTokenUnits"
                        type="number"
                        value={gameConfig.minWagerInHouseTokenUnits?.toString()}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, minWagerInHouseTokenUnits
                                : Number(e.target.value)
                        }))} />}
            />
            <FieldItem
                title="Max Bets"
                subtitle="per instance token"
                input={
                    <Input
                        name="maxBetsPerInstanceToken"
                        type="number"
                        value={gameConfig.maxBetsPerInstanceToken}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, maxBetsPerInstanceToken
                                : Number(e.target.value)
                        }))} />}
            />
            <FieldItem
                title="Max Bets"
                subtitle="per place ixn"
                input={
                    <Input
                        name="maxBetsPerPlaceIxn"
                        type="number"
                        value={gameConfig.maxBetsPerPlaceIxn}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, maxBetsPerPlaceIxn
                                : Number(e.target.value)
                        }))} />}
            />
            <FieldItem
                title="Max Bets"
                subtitle="per settle"
                input={
                    <Input
                        name="maxBetsPerSettleIxn"
                        type="number"
                        value={gameConfig.maxBetsPerSettleIxn}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, maxBetsPerSettleIxn
                                : Number(e.target.value)
                        }))} />}
            />
            <FieldItem
                title="Max Players"
                subtitle="per token"
                input={
                    <Input
                        name="maxPlayersPerToken"
                        type="number"
                        value={gameConfig.maxPlayersPerToken}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, maxPlayersPerToken
                                : Number(e.target.value)
                        }))} />}
            />
            <FieldItem
                title="Max Tokens"
                subtitle="per instance"
                input={
                    <Input
                        name="maxTokensPerInstance"
                        type="number"
                        value={gameConfig.maxTokensPerInstance}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, maxTokensPerInstance: Number(e.target.value)
                        }))} />}
            />
            <FieldItem title="Inactivity Timeout" subtitle="seconds"
                input={
                    <Input
                        name="inactivityTimeoutSeconds"
                        type="number"
                        value={gameConfig.inactivityTimeoutSeconds}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, inactivityTimeoutSeconds: Number(e.target.value)
                        }))} />}
            />
            <FieldItem title="Callback Respond" subtitle="discriminator"
                input={
                    <Input
                        name="callbackRespondDiscriminator"
                        disabled={true}
                        type="text"
                        value={gameConfig.callbackRespondDiscriminator}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, callbackRespondDiscriminator: Number(e.target.value)
                        }))} />}
            />
            <FieldItem title="Callback Settle" subtitle="discriminator"
                input={
                    <Input name="callbackSettleDiscriminator" type="text" value={gameConfig.callbackSettleDiscriminator}
                        disabled={true}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, callbackSettleDiscriminator: Number(e.target.value)
                        }))} />}
            />
            <FieldItem
                title="Callback Expire"
                subtitle="discriminator"
                input={
                    <Input name="callbackExpireDiscriminator" type="text" value={gameConfig.callbackExpireDiscriminator}
                        disabled={true}
                        onChange={(e) => setGameConfig((prev) => ({
                            ...prev, callbackExpireDiscriminator: Number(e.target.value)
                        }))} />}
            />
        </>
    )

    const modalTitle = (() => {
        if (type === GameEditModalType.CONFIG) return `${gameDisplayName} Configuration`
        if (type === GameEditModalType.STATUS) return `${gameDisplayName} Status`
        if (type === GameEditModalType.TOKEN_SUPPORT) return `${gameDisplayName} Token Support`
        if (type === GameEditModalType.DELEGATION) return `${gameDisplayName} Delegation`
    })();

    // TODO
    const changeDelegationOnGameSpec = useCallback(async () => {
        if (signerInputError || house == null || game?.game == null) {
            console.error('Issue with inputs for changeDelegationOnGameSpec')
            toast(
                <BaseToast
                    message={"Issue with inputs for changeDelegationOnGameSpec."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
            return
        }

        setLoading(true)

        try {

            // CHECK IF WE NEED TO DELEGATE OR UN-DELEGATE
            const ix = await addGameSpecTokenIxn(house, newTokenPubkey, game.game, selectedGameStatus, authorityPubkey)
            const sig = await sendTransaction(
                [ix],
                client,
                authorityPubkey,
                true,
                chain,
                meta?.errorByCodeByProgram,
                undefined,
                connectedViaWallet ? signTransaction : undefined,
                connectedViaKeyPair ? keypair : undefined,
                undefined,
                "confirmed"
            )

            toast(
                <BaseToast
                    message={`Successfully added game spec token ${newTokenPubkey?.toString()}: ${sig}`}
                    icon={<Icon size="lg" name="link" />}
                    type={"success"}
                />,
                BASE_TOAST_CONFIG,
            );

            // lOAD THE GAMES
            await loadGames()

            // CLEAR PIN AND SIGNER
            setPinInput(undefined)

            hideModal()
        } catch (err) {
            console.error('Issue adding game spec token', err)
            toast(
                <BaseToast
                    message={"Issue adding game spec token."}
                    icon={<Icon size="lg" name="link" />}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
        }

        setLoading(false)
    }, [selectedSigner, selectedGameStatus, client, pinInput, game, loadGames, meta?.errorByCodeByProgram, newTokenPubkey, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError, chain]);

    const onClick = () => {
        if (type === GameEditModalType.CONFIG) {
            console.log('Update game ', gameConfig)// NEEDS REAL DATA
            updateConfig()
            return
        };
        if (type === GameEditModalType.STATUS) {
            updateStatus()
            return
        };
        if (type === GameEditModalType.TOKEN_SUPPORT) {
            console.log('Update tokens ', tokensConfig)// NEEDS REAL DATA
            return
        };
        if (type === GameEditModalType.DELEGATION) {
            console.log('Delegation ', transferAmount)// NEEDS REAL DATA
            return
        };
    };

    return (
        <BaseModal
            open={visible}
            onClose={hideModal}
            title={<div className="text-xl">{modalTitle}</div>}>
            <div className=" flex flex-col justify-center items-center text-gray-400 w-full">
                {noPermissions ? noPermissionsContent : ""}
                {!noPermissions ? (
                    <div className="flex flex-col items-center gap-y-4 w-full">
                        {type == GameEditModalType.STATUS ? editStatusForm : null}
                        {type == GameEditModalType.TOKEN_SUPPORT ? tokenSupportForm : null}
                        {type == GameEditModalType.CONFIG ? configForm : null}
                        {type == GameEditModalType.DELEGATION ? delegationForm : null}
                        <Separator />

                        {type != GameEditModalType.TOKEN_SUPPORT ? <>
                            {signerInput}
                            {/* AUTH WALLET BUTTON */}
                            <Button
                                disabled={
                                    client == null ||
                                    game?.game == null || signerInputError ||
                                    (connectedViaKeyPair && (selectedSigner == null || pinInput == null || pinInput == "")) ||
                                    (connectedViaWallet && (walletPubKey == null || signTransaction == null))
                                }
                                onClick={onClick}
                                variant="secondary"
                                isLoading={loading}
                                className="w-full"
                            >
                                Apply changes
                            </Button>
                        </> : ''}
                    </div>
                ) : (
                    ""
                )}
            </div>
        </BaseModal>
    );
};

const FieldItem = ({ title, subtitle, input }: { title?: string | ReactNode, subtitle?: string, input: ReactNode }) => {
    return <div className="flex justify-between w-full">
        {title ? <div className="flex flex-col gap-1">
            <div className="text-gray-50 font-bold">{title}</div>
            {subtitle ? <div className="text-sm text-gray-300 font-normal">{subtitle}</div> : null}
        </div> : null}
        {input}
    </div>
}