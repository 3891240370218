import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import { NATIVE_MINT, TOKEN_PROGRAM_ID, createAssociatedTokenAccountIdempotentInstruction, getAssociatedTokenAddressSync } from "@solana/spl-token";
import { DELEGATION_PROGRAM_ID, MAGIC_PROGRAM_ID } from "@magicblock-labs/delegation-program";
import { DelegateAccounts, MAGIC_CONTEXT_ID } from "@magicblock-labs/ephemeral-rollups-sdk";
import HouseToken from "../../sdk/houseToken";
import { ZeebitV2 } from "../../sdk/program-types/solana_zeebit_v2";


export default class LiquidityProvider {

    private _houseToken: HouseToken;
    private _ownerPubkey: PublicKey;
    private _publicKey: PublicKey;
    private _erState: anchor.IdlAccounts<ZeebitV2>["liquidityProvider"];
    private _baseState: anchor.IdlAccounts<ZeebitV2>["liquidityProvider"];

    constructor( 
        houseToken: HouseToken,
        ownerPubkey: PublicKey
    ) {
        this._houseToken = houseToken; 
        this._ownerPubkey = ownerPubkey; 
        this._publicKey = LiquidityProvider.deriveLiquidityProviderPubkey(
            houseToken.publicKey,
            ownerPubkey,
            houseToken.programId
        );   
    };

    static async load(
        houseToken: HouseToken,
        ownerPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const liquidityProvider = new LiquidityProvider(
            houseToken,
            ownerPubkey,
        )
        await liquidityProvider.loadBaseState(commitmentLevel);
        await liquidityProvider.loadErState(commitmentLevel);
        return liquidityProvider
    };

   

    async loadBaseState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.liquidityProvider.fetchNullable(
            this._publicKey,
            commitmentLevel
        );
        if (state) {
            this._baseState = state;
        } else {
            // throw new Error(`A valid account was not found at the pubkey provided: ${this._publicKey}`)
        }
        return
    }

    async loadErState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.erProgram.account.liquidityProvider.fetchNullable(
            this._publicKey,
            commitmentLevel
        );
        console.log('lp.loadErState', state)
        if (state) {
            this._erState = state;
        } else {
            // throw new Error(`A valid account was not found at the pubkey provided: ${this._publicKey}`)
        }
        return
    }

    static deriveLiquidityProviderPubkey(
        houseTokenPubkey: PublicKey,
        ownerPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("liquidity_provider"),
                houseTokenPubkey.toBuffer(),
                ownerPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    static deriveUpdateSlipPubkey(
        liquidityProviderPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("update_slip"),
                liquidityProviderPubkey.toBuffer(),
            ],
            programId
        );
        return pk
    };


    get houseToken() {
        return this._houseToken
    }

    get baseProgram() {
        return this._houseToken.baseProgram
    }

    get erProgram() {
        return this._houseToken.erProgram
    }

    get programId() {
        return this._houseToken.baseProgram.programId
    }

    get publicKey() {
        return this._publicKey
    }

    get baseState() {
        return this._baseState
    }

    get erState() {
        return this._erState
    }

    get ownerPubkey() {
        return this._baseState?.owner
    }

    get houseTokenPubkey() {
        return this._baseState.houseToken
    }

    get tokenPubkey() {
        return this.houseToken.tokenMintPubkey
    }

    get lpTokens() {
        return Number(this._baseState.lpTokens)
    }

    get netDeposits() {
        return Number(this._baseState.netDeposits)
    }

    getWithdrawableBalance() {
        const houseTokenBalance = (this.houseToken.availableBalance || 0) + (this.houseToken.lockedBalance || 0)
        const houseLpTokensOutstanding = this.houseToken.outstandingLpBalance || 0
        const usersLpTokensOutstanding = (this.lpTokens || 0)
        const sharePercentage = houseLpTokensOutstanding > 0 ? usersLpTokensOutstanding / houseLpTokensOutstanding: 0
        
        return houseTokenBalance * sharePercentage
    }

    async depositIxns(
        amount: number
    ): Promise<TransactionInstruction[]> {
        const ixns = []

        // MAY NEED TO CREATE AN ATA
        const tokenAccountPubkey = getAssociatedTokenAddressSync(this.houseToken.tokenMintPubkey, this.ownerPubkey, false);
        const ataAcc = await this.baseProgram.provider.connection.getAccountInfo(tokenAccountPubkey)

        if (ataAcc == null) {
            const createAta = createAssociatedTokenAccountIdempotentInstruction(
                this.ownerPubkey,
                tokenAccountPubkey,
                this.ownerPubkey,
                this.houseToken.tokenMintPubkey
            )
            ixns.push(createAta)
        }

        const vaultPubkey = HouseToken.deriveHouseTokenVaultPubkey(this.houseToken.bankPublicKey, this.houseToken.tokenMintPubkey);
        
        const depositIx = await this.baseProgram.methods.lpDeposit({
            amount: new anchor.BN(amount)
        }).accounts({
            owner: this.ownerPubkey,
            liquidityProvider: this.publicKey,
            house: this.houseToken.house.publicKey,
            houseToken: this.houseToken.publicKey,
            houseTokenBank: this.houseToken.bankPublicKey,
            tokenMint: this.houseToken.tokenMintPubkey,
            tokenAccount: tokenAccountPubkey,
            vault: vaultPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId,
        }).instruction()
        ixns.push(depositIx)

        return ixns;
    };

    

    async withdrawIxn(
        amount: number
    ) {
        const tokenAccountPubkey = getAssociatedTokenAddressSync(this.houseToken.tokenMintPubkey, this.ownerPubkey, false);
        const vaultPubkey = HouseToken.deriveHouseTokenVaultPubkey(this.houseToken.bankPublicKey, this.houseToken.tokenMintPubkey);
        
        return await this.baseProgram.methods.lpWithdrawal({
            amount: new anchor.BN(amount)
        }).accounts({
            payer: this.ownerPubkey,
            owner: this.ownerPubkey,
            house: this.houseToken.house.publicKey,
            houseToken: this.houseToken.publicKey,
            houseTokenBank: this.houseToken.bankPublicKey,
            liquidityProvider: this.publicKey,
            tokenMint: this.houseToken.tokenMintPubkey,
            tokenAccount: tokenAccountPubkey,
            vault: vaultPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId,
        }).instruction()
    };

    // MagicBlock Ixns

    async depositInitializeIxns(
        amount: number,
    ): Promise<TransactionInstruction[]> {
        const ixns = []

        // MAY NEED TO CREATE AN ATA
        const tokenAccountPubkey = getAssociatedTokenAddressSync(this.houseToken.tokenMintPubkey, this.ownerPubkey, false);
        const ataAcc = await this.baseProgram.provider.connection.getAccountInfo(tokenAccountPubkey)

        if (ataAcc == null) {
            const createAta = createAssociatedTokenAccountIdempotentInstruction(
                this.ownerPubkey,
                tokenAccountPubkey,
                this.ownerPubkey,
                this.houseToken.tokenMintPubkey
            )
            ixns.push(createAta)
        }


        const updateSlipPubkey = LiquidityProvider.deriveUpdateSlipPubkey(this.publicKey, this.programId);
        const vaultPubkey = HouseToken.deriveHouseTokenVaultPubkey(this.houseToken.bankPublicKey, this.houseToken.tokenMintPubkey);
        
        const {
            delegationPda,
            delegationMetadata,
            bufferPda,
            commitStateRecordPda,
            commitStatePda,
        } = DelegateAccounts(
            updateSlipPubkey, 
            this.baseProgram.programId
        );

        const depositIxn = await this.baseProgram.methods.lpDepositInitialize({
            amount: new anchor.BN(amount)
        }).accounts({
            payer: this.ownerPubkey,
            owner: this.ownerPubkey,
            updateSlip: updateSlipPubkey,
            liquidityProvider: this.publicKey,
            house: this.houseToken.house.publicKey,
            houseToken: this.houseToken.publicKey,
            houseTokenBank: this.houseToken.bankPublicKey,
            tokenMint: this.houseToken.tokenMintPubkey,
            tokenAccount: tokenAccountPubkey,
            vault: this.houseToken.vaultPublicKey,
            tokenProgram: TOKEN_PROGRAM_ID,
            buffer: bufferPda,
            delegationRecord: delegationPda,
            delegationMetadata: delegationMetadata,
            ownerProgram: this.baseProgram.programId,
            delegationProgram: DELEGATION_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()
        ixns.push(depositIxn)

        return ixns;
    };

    async applyDepositIxn(): Promise<TransactionInstruction> {
        const updateSlipPubkey = LiquidityProvider.deriveUpdateSlipPubkey(this.publicKey, this.programId);
        
        return await this.erProgram.methods.lpDepositApply(
            {}
        ).accounts({
            payer: this.ownerPubkey,
            updateSlip: updateSlipPubkey,
            houseToken: this.houseToken.publicKey,
            magicProgram: MAGIC_PROGRAM_ID,
            magicContext: MAGIC_CONTEXT_ID
        }).instruction();    
    };


    async predelegateUpdateSlipIxn(): Promise<TransactionInstruction> {
        const updateSlipPubkey = LiquidityProvider.deriveUpdateSlipPubkey(this.publicKey, this.programId);
        const {
            delegationPda,
            delegationMetadata,
            bufferPda,
            commitStateRecordPda,
            commitStatePda,
        } = DelegateAccounts(
            updateSlipPubkey, 
            this.baseProgram.programId
        );

        return await this.baseProgram.methods.updateSlipPredelegate({}).accounts({
            payer: this.ownerPubkey,
            relatedAccount: this.publicKey,
            updateSlip: updateSlipPubkey,
            buffer: bufferPda,
            delegationRecord: delegationPda,
            delegationMetadata: delegationMetadata,
            ownerProgram: this.baseProgram.programId,
            delegationProgram: DELEGATION_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()
    }

    async closeUpdateSlipIxn(): Promise<TransactionInstruction> {
        const updateSlipPubkey = LiquidityProvider.deriveUpdateSlipPubkey(this.publicKey, this.programId);

        return await this.baseProgram.methods.updateSlipClose({

        }).accounts({
            updateSlip: updateSlipPubkey,
            rentRecipient: this.ownerPubkey
        }).instruction();
    }

    async initializeWithdrawalIxn(amount: number): Promise<TransactionInstruction> {
        /// NOTE: Requires an updateSlip to be pre-delegated
       
        const updateSlipPubkey = LiquidityProvider.deriveUpdateSlipPubkey(this.publicKey, this.programId);

        return await this.erProgram.methods.lpWithdrawInitialize({
            amount: new anchor.BN(amount)
        }).accounts({
            owner: this.ownerPubkey,
            liquidityProvider: this.publicKey,
            updateSlip: updateSlipPubkey,
            house: this.houseToken.house.publicKey,
            houseToken: this.houseToken.publicKey,
            tokenMint: this.houseToken.tokenMintPubkey,
            systemProgram: anchor.web3.SystemProgram.programId,
            magicProgram: MAGIC_PROGRAM_ID,
            magicContext: MAGIC_CONTEXT_ID
        }).instruction()
    };


    async applyWithdrawalIxn(): Promise<TransactionInstruction> {
        const tokenAccountPubkey = this.houseToken.tokenMintPubkey.toString() != NATIVE_MINT.toString() ? getAssociatedTokenAddressSync(this.houseToken.tokenMintPubkey, this.ownerPubkey, false): this.ownerPubkey;
        const updateSlipPubkey = LiquidityProvider.deriveUpdateSlipPubkey(this.publicKey, this.programId);

        return await this.erProgram.methods.lpWithdrawApply(
            {}
        ).accounts({
            updateSlip: updateSlipPubkey,
            rentRecipient: this.ownerPubkey,
            owner: this.ownerPubkey,
            liquidityProvider: this.publicKey,
            houseToken: this.houseTokenPubkey,
            houseTokenBank: this.houseToken.bankPublicKey,
            tokenMint: this.houseToken.tokenMintPubkey,
            vault: this.houseToken.vaultPublicKey,
            tokenAccount: tokenAccountPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

}
