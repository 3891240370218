import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import Separator from "../../Components/Separator/Separator";
import Icon from "../../../components/common/icon/Icon";
import LiquidityProvider from "../../sdk/liquidityProvider";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { Connection } from "@solana/web3.js";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { LpContext } from "../../Contexts/LiquidityProviderContext";
import Tabs from "../../../components/common/tabs/Tabs";
import { createAssociatedTokenAccountIdempotentInstruction, getAssociatedTokenAddress, getAssociatedTokenAddressSync, NATIVE_MINT } from "@solana/spl-token";
import { sendTransaction } from "../../../sdk/transactions";
import { ProgramContext } from "../../../contexts";
import { awaitCommitToBaseLayer } from "../../../sdk/utils";
import { ICasinoToken } from "../../../types/token";
import { BalanceInput } from "../../Components/BalanceInput/BalanceInput";
import { useWallet } from "@solana/wallet-adapter-react";
import { SignerContext } from "../../Contexts/SignersContext";

export enum TransferModalType {
  WITHDRAW = 'WITHDRAW',
  DEPOSIT = 'DEPOSIT'
}

export interface ILpMeta { lp: LiquidityProvider | undefined, token: ICasinoToken | undefined }

interface ITransferModalProps {
  visible: boolean;
  hideModal: Function;
  lpMeta: ILpMeta
  modalType: TransferModalType
  updateModalType: React.Dispatch<React.SetStateAction<TransferModalType | undefined>>
}

export const TransferModal: FC<ITransferModalProps> = ({ visible, hideModal, lpMeta, modalType, updateModalType }) => {
  const { signerInput, signerInputError, authorityPubkey, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput } = useContext(SignerContext);
  const [noPermissions, setNoPermissions] = useState(false);
  const [balance, setBalance] = useState<number>(0); // this should be a ui value, not basis
  const [availableBalance, setAvailableBalance] = useState<number>()
  const availableBalanceUi = useMemo(() => {
    return (availableBalance || 0) / Math.pow(10, lpMeta.token?.decimals || 6)
  }, [availableBalance, lpMeta.token?.decimals])
  const { signTransaction } = useWallet();

  const { client, erClient, chain } = useContext(NetworkContext);
  const { loadLps } = useContext(LpContext);

  useEffect(() => {
    setPinInput(undefined);
  }, [visible])

  useEffect(() => {
    async function loadAvailableBalance(lp: LiquidityProvider, connection: Connection) {
      try {
        const tokenMint = lp.houseToken.tokenMintPubkey
        const wallet = lp.ownerPubkey

        if (tokenMint.toString() != NATIVE_MINT.toString()) {
          const ata = await getAssociatedTokenAddress(tokenMint, wallet, false, lp.houseToken.tokenProgram)

          const tokenBalance = await connection.getTokenAccountBalance(ata)

          setAvailableBalance(Number(tokenBalance.value.amount))
        } else {
          const balance = await connection.getBalance(wallet)
          setAvailableBalance(Number(balance))
        }
      } catch (err) {
        console.error("Issue loading available balance", err)
      }
    }

    if (lpMeta?.lp?.lpTokens == null || client == null) {
      setAvailableBalance(0)
      return
    }

    if (modalType == TransferModalType.WITHDRAW) {
      const availableBalance = lpMeta.lp.getWithdrawableBalance()

      setAvailableBalance(availableBalance)
    } else {
      loadAvailableBalance(lpMeta.lp, client)
    }
  }, [modalType, lpMeta, client])

  useEffect(() => {
    if (lpMeta?.lp?.lpTokens == null) {
      return
    }

    setBalance(0)
  }, [lpMeta?.lp])

  const noPermissionsContent = useMemo(() => {
    return (
      <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
        <div className="font-white text-3xl font-oxanium">No Permissions</div>
        <div className="text-xl text-gray-300">
          No permissions have been granted to selected wallet. Please contact Zeebit’s team when
          requesting DevTools access.
        </div>
        <Button
          variant="secondary"
          onClick={() => {
            // WIPE ALL THE INPUTS
            setPinInput(undefined);
          }}
        >
          Try Another Wallet
        </Button>
      </div>
    );
  }, []);

  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext)
  const { meta } = useContext(ProgramContext)

  const withdrawTokens = useCallback(async (withdrawAll: boolean = false) => {
    if (lpMeta?.lp == null || client == null || signerInputError) {
      return
    }

    setLoading(true)

    try {
      let valueToWithdraw = 0

      if (withdrawAll == false) {
        const balanceBasis = balance * Math.pow(10, lpMeta.token?.decimals || 6)

        // SOLVE FOR TOKENS TO WITHDRAW
        const totalShares = lpMeta.lp.lpTokens || 0
        const tokensToWithdraw = balanceBasis
        const totalBalanceAvailable = availableBalance || 0

        valueToWithdraw = (totalShares * tokensToWithdraw) / totalBalanceAvailable
      } else {
        valueToWithdraw = lpMeta.lp.lpTokens || 0
      }

      let sig

      // CHECK IF WE NEED TO CREATE AN ATA
      const ata = getAssociatedTokenAddressSync(lpMeta.lp.houseToken.tokenMintPubkey, lpMeta.lp.ownerPubkey, false, lpMeta.lp.houseToken.tokenProgram)
      const ataAccount = await lpMeta.lp.houseToken.baseProgram.provider.connection.getAccountInfo(ata)

      console.log({
        ataAccount,
        tokenProgram: lpMeta.lp.houseToken.tokenProgram.toString()
      })

      if (ataAccount == null) {
        const ix = createAssociatedTokenAccountIdempotentInstruction(lpMeta.lp.ownerPubkey, ata, lpMeta.lp.ownerPubkey, lpMeta.lp.houseToken.tokenMintPubkey, lpMeta.lp.houseToken.tokenProgram)
        sig = await sendTransaction(
          [ix],
          client,
          authorityPubkey,
          true,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )
      }

      // CHECK IF ITS DELEGATED
      if (lpMeta.lp.houseToken.isDelegated == false) {
        const ix = await lpMeta.lp.withdrawIxn(valueToWithdraw)
        sig = await sendTransaction(
          [ix],
          client,
          authorityPubkey,
          true,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )
      } else {
        // GO THROUGH FLOW
        // UPDATE SLIP PRE-DELEGATE

        // GO THROUGH STEPS DEPOSIT INIT
        const predelegate = await lpMeta.lp.predelegateUpdateSlipIxn()
        const predelegateSig = await sendTransaction([
          predelegate
        ],
          client,
          authorityPubkey,
          true,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )

        // INIT WITHDRAWAL
        const initWithdraw = await lpMeta.lp.initializeWithdrawalIxn(valueToWithdraw)
        const initWithdrawSig = await sendTransaction([
          initWithdraw
        ],
          erClient,
          authorityPubkey,
          false,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )

        await awaitCommitToBaseLayer(erClient, client, initWithdrawSig)


        // Withdraw Apply
        const withdrawApply = await lpMeta.lp.applyWithdrawalIxn()
        const withdrawApplySig = await sendTransaction([
          withdrawApply
        ],
          client,
          authorityPubkey,
          true,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )
        sig = withdrawApplySig

      }

      toast(
        <BaseToast
          message={`Successfully withdrew lp tokens: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      await loadLps()

      hideModal()
    } catch (err) {
      console.error('Issue withdrawing tokens', err)
      toast(
        <BaseToast
          message={`Issue withdrawing lp tokens: ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [chain, balance, client, lpMeta?.lp, selectedSigner, pinInput, availableBalance, erClient, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  const depositTokens = useCallback(async () => {
    if (lpMeta?.lp == null || client == null || signerInputError) {
      return
    }

    setLoading(true)

    try {
      let sig

      const balanceBasis = balance * Math.pow(10, lpMeta.token?.decimals || 6)

      if (lpMeta.lp.houseToken.isDelegated == true) {
        // GO THROUGH STEPS DEPOSIT INIT
        const depositInitIxns = await lpMeta.lp.depositInitializeIxns(balanceBasis)
        const depositInitSig = await sendTransaction(
          depositInitIxns,
          client,
          authorityPubkey,
          true,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )

        // DEPOSIT APPLY
        const depositApply = await lpMeta.lp.applyDepositIxn()
        const depositApplySig = await sendTransaction([
          depositApply
        ],
          erClient,
          authorityPubkey,
          false,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )

        await awaitCommitToBaseLayer(erClient, client, depositApplySig)

        // CLOSE UPDATE SLIP
        const closeUpdateSlip = await lpMeta.lp.closeUpdateSlipIxn()
        const closeUpdateSlipSig = await sendTransaction([
          closeUpdateSlip
        ],
          client,
          authorityPubkey,
          true,
          chain,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )

        sig = closeUpdateSlipSig
      } else {
        const ixns = await lpMeta.lp.depositIxns(balanceBasis)
        sig = await sendTransaction(ixns, client, authorityPubkey, true, chain, meta?.errorByCodeByProgram, undefined, connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined, undefined, "confirmed")
      }

      toast(
        <BaseToast
          message={`Successfully deposit lp tokens: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      await loadLps()

      hideModal()
    } catch (err) {
      console.error('Issue depositing tokens', err)
      toast(
        <BaseToast
          message={`Issue depositing lp tokens: ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [chain, balance, client, lpMeta, selectedSigner, pinInput, erClient, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  return (
    <BaseModal
      open={visible}
      onClose={hideModal}
      title={"Funding"}
    >
      <div className=" flex flex-col justify-center items-center text-gray-400 w-full">
        {noPermissions ? noPermissionsContent : ""}
        {!noPermissions ? (
          <div className="flex flex-col items-center gap-y-4 w-full">
            {/* Wallet */}
            <Tabs
              classes={{ wrapper: "self-stretch w-full" }}
              variant="modal" tabs={[
                {
                  "label": "Deposit",
                  "name": TransferModalType.DEPOSIT,
                  "disable": false
                },
                {
                  "label": "Withdraw",
                  "name": TransferModalType.WITHDRAW,
                  "disable": false
                },
              ]}
              onTabSelect={updateModalType}
              activeTab={modalType}
            />
            {/* Status */}
            <FormItem
              className="flex-1 self-stretch"
              label="Available Balance (UI Value)"
              rightLabel={
                <div className="flex gap-1.5 items-center">
                  <Icon iconUrl={lpMeta?.token?.imageDarkPng} size="sm" />
                  {availableBalanceUi}
                </div>
              }
            >
              <BalanceInput balance={balance} setBalance={setBalance} icon={lpMeta?.token?.imageDarkPng || ""} />
            </FormItem>

            <Separator />

            {/* Wallet */}
            {signerInput}

            {/* AUTH WALLET BUTTON */}
            {modalType == TransferModalType.DEPOSIT ? <Button
              variant="primary"
              disabled={signerInputError || client == null}
              isLoading={loading}
              onClick={depositTokens}
              className="w-full"
            >
              {'Deposit Tokens'}
            </Button> : ''}
            {modalType == TransferModalType.WITHDRAW ? <div className="flex flex-col gap-y-3 w-full">
              <Button
                variant="primary"
                disabled={signerInputError || client == null}
                isLoading={loading}
                onClick={() => withdrawTokens(false)}
                className="w-full"

              >
                {'Withdraw Tokens'}
              </Button>
              <Button
                disabled={signerInputError || client == null}
                isLoading={loading}
                onClick={() => withdrawTokens(true)}
                variant="secondary"
                className="w-full"
              >
                {'Withdraw All Tokens'}
              </Button>
            </div> : ''}
          </div>
        ) : (
          ""
        )}
      </div>
    </BaseModal>
  );
};

