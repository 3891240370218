import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Program } from "@coral-xyz/anchor";
import { SwitchboardProgram } from "@switchboard-xyz/solana.js";
import NftStaking from "../../sdk/nftStaking/nftStaking";
import { ProgramContext } from "../../contexts/ProgramContext";
import { ZEEBROS_NFT_COLLECTION_PUBKEY } from "../../sdk/nftStaking/constants";
import { SignerContext } from "./SignersContext";
import { useWallet } from "@solana/wallet-adapter-react";
import House from "../../sdk/house";
import { HouseContext } from "./AdminHouseContext";
import { PublicKey } from "@solana/web3.js";

export interface INftStakingContext {
  updateNftStaking: () => Promise<void>
  nftStaking: NftStaking | undefined
  loading: boolean;
  isAdmin: boolean;
}

export const NftStakingContext = createContext<INftStakingContext>({} as INftStakingContext);

interface Props {
  children: any;
}

export const NftStakingProvider = ({ children }: Props) => {
  // CONTEXTS NEEDED
  const { meta } = useContext(ProgramContext)
  const nftProgram = useMemo(() => {
    return meta?.nftStakingProgram
  }, [meta?.nftStakingProgram]);
  const switchboardProgram = useMemo(() => {
    return meta?.switchboardProgram
  }, [meta?.switchboardProgram]);
  const { signers } = useContext(SignerContext);
  const { publicKey: walletPubkey } = useWallet();
  const { house } = useContext(HouseContext)

  // STATE
  const [nftStaking, setNftStaking] = useState<NftStaking>();
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!nftStaking || house == null) {
      return
    }
    const authority = nftStaking.mainState.authority;
    if (signers.some((signer) => signer.publicKey === authority.toString()) ||
      walletPubkey?.toString() === authority?.toString()
    ) {
      setIsAdmin(true);
    } else if (isAdmin) {
      setIsAdmin(false)
    }
  }, [nftStaking, signers, walletPubkey, house?.baseProgram, house?.publicKey]);

  useEffect(() => {
    async function loadNftMeta(nftProgram: Program, switchboardProgram: SwitchboardProgram) {
      try {
        setLoading(true)

        const mainPubkey = NftStaking.deriveMainPubkey(ZEEBROS_NFT_COLLECTION_PUBKEY, nftProgram.programId)

        // LOAD NFT STAKING CLASS
        const nftStak = await NftStaking.load(nftProgram, switchboardProgram, mainPubkey)
        setNftStaking(nftStak)
      } catch (err) {
        console.error(`Issue loading NFT meta`, err)
      } finally {
        setLoading(false)
      }
    }

    if (nftProgram == null || switchboardProgram == null) {
      return
    }

    loadNftMeta(nftProgram, switchboardProgram)
  }, [nftProgram, switchboardProgram])

  const updateNftStaking = useCallback(async () => {
    if (nftStaking == null || meta?.switchboardProgram == null) {
      return
    }

    try {

      const updated = await NftStaking.load(nftStaking.program, meta.switchboardProgram, nftStaking.mainPubkey, "processed")
      setNftStaking(updated)

      setCounter(counter + 1)
    } catch (err) {
      console.error("Error loading the NFT staking instance", err)
    }
  }, [nftStaking, counter, meta?.switchboardProgram])

  return (
    <NftStakingContext.Provider
      value={useMemo(
        () => ({
          nftStaking: nftStaking,
          loading: loading,
          updateNftStaking: updateNftStaking,
          isAdmin: isAdmin,
        }),
        [nftStaking, loading, updateNftStaking, counter, isAdmin],
      )}
    >
      {children}
    </NftStakingContext.Provider>
  );
};
