import { FC, useCallback, useContext, useEffect, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import Button from "../../../components/common/button/Button";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { crankAllUpdateIndividualsTxns, crankAttributeSelectionIx, crankResultSelectionIx } from "../../sdk/nfStaking";
import { sendTransaction } from "../../../sdk/transactions";
import { ProgramContext } from "../../../contexts";
import { NftStakingContext } from "../../Contexts/NftStakingContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { SignerContext } from "../../Contexts/SignersContext";
import { APP_NETWORK_TYPE } from "../../../types/chain";

export enum CrankerActionModalType {
  ATTRIBUTE_SELECT = 'attribute-select',
  RESULT_SELECT = 'result-select',
  ALL_INDIVIDUALS = 'all-individuals',
}

interface ICrankerActionModalProps {
  visible: boolean;
  hideModal: Function;
  type: CrankerActionModalType | undefined
}

export const CrankerActionModal: FC<ICrankerActionModalProps> = ({
  visible,
  hideModal,
  type
}) => {
  const { signerInput, signerInputError, authorityPubkey, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput } = useContext(SignerContext);
  const { signTransaction } = useWallet();

  // useEffect(() => {
  //   if (selectedSigner == null && signers != null && signers.length > 0) {
  //     setSelectedSigner(signers[0])
  //   }
  // }, [signers])
  useEffect(() => {
    setPinInput(undefined);
  }, [visible])

  const { client, solanaClient } = useContext(NetworkContext)
  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext)
  const { nftStaking, updateNftStaking } = useContext(NftStakingContext)
  const { meta } = useContext(ProgramContext)

  const crankAttributeSelect = useCallback(async () => {
    if (signerInputError || solanaClient == null || nftStaking == null) {
      console.error('Issue with inputs for crankAttributeSelect')

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const ix = await crankAttributeSelectionIx(nftStaking, authorityPubkey)
      const sig = await sendTransaction(
        [ix],
        solanaClient,
        authorityPubkey,
        true,
        APP_NETWORK_TYPE.MB_AND_SOLANA,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )

      toast(
        <BaseToast
          message={`Successfully cranked attribute selection: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE NFT STAKING
      await updateNftStaking()

      // CLEAR PIN AND SIGNER
      setPinInput(undefined);

      hideModal()
    } catch (err) {
      console.error('Issue updating status', err)
      toast(
        <BaseToast
          message={`Issue cranking the NFT status. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, solanaClient, pinInput, nftStaking, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  const crankResultSelect = useCallback(async () => {
    if (signerInputError || solanaClient == null || nftStaking == null) {
      console.error('Issue with inputs for crankAttributeSelect')

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const ix = await crankResultSelectionIx(nftStaking, authorityPubkey)
      const sig = await sendTransaction(
        [ix],
        solanaClient,
        authorityPubkey,
        true,
        APP_NETWORK_TYPE.MB_AND_SOLANA,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )


      toast(
        <BaseToast
          message={`Successfully cranked result selection: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE NFT STAKING
      await updateNftStaking()

      // CLEAR PIN AND SIGNER
      setPinInput(undefined);

      hideModal()
    } catch (err) {
      console.error({
        err
      })
      console.error('Issue cranking the result selection', err)
      toast(
        <BaseToast
          message={`Issue cranking the result selection. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, solanaClient, pinInput, nftStaking, meta, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  const crankAllIndividuals = useCallback(async () => {
    if (signerInputError || solanaClient == null || nftStaking == null) {
      console.error('Issue with inputs for crankAttributeSelect')

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const txns = await crankAllUpdateIndividualsTxns(nftStaking, authorityPubkey)

      if (txns == null) {
        setLoading(false)
        return
      }

      const sigs = await Promise.all(txns.map((tx) => sendTransaction(
        tx.instructions,
        solanaClient,
        authorityPubkey,
        true,
        APP_NETWORK_TYPE.MB_AND_SOLANA,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )))

      toast(
        <BaseToast
          message={`Successfully cranked crankAllUpdateIndividuals: ${sigs}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE NFT STAKING
      await updateNftStaking()

      // CLEAR PIN AND SIGNER
      setPinInput(undefined);

      hideModal()
    } catch (err) {
      console.error({ err })
      toast(
        <BaseToast
          message={`Issue cranking the crankAllUpdateIndividuals. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, solanaClient, pinInput, nftStaking, meta, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  return (
    <BaseModal open={visible} onClose={hideModal} title={`Nft Cranking - ${type}`}>
      <div className=" flex flex-col justify-center items-center text-gray-400 w-full">

        <div className="flex flex-col items-center gap-y-4 w-full">
          {/* Wallet */}
          {signerInput}
          {/* AUTH WALLET BUTTON */}
          <Button
            disabled={nftStaking == null || signerInputError}
            onClick={type == CrankerActionModalType.ATTRIBUTE_SELECT ? crankAttributeSelect : type == CrankerActionModalType.RESULT_SELECT ? crankResultSelect : crankAllIndividuals} isLoading={loading} variant="secondary" size="sm">
            {`Crank ${type}`}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
