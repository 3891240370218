import { useNavigate } from "react-router-dom";
import { Signers } from "../../Components/Signers/Signers";
import { useContext } from "react";
import { NetworkContext } from "../../../contexts";
import { ENABLED_NETWORKS } from "../../../constants/chains";
import { PriorityFeeLevel } from "../../../contexts/NetworkContext";
import { WalletDisconnectButton, WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import DropdownInput from "../../../components/common/dropdown-input/DropdownInput";
import { SignerContext } from "../../Contexts/SignersContext";
require("@solana/wallet-adapter-react-ui/styles.css");

export function TopNav() {
  const navigate = useNavigate();
  const { chain, setChain, priorityFeeLevel, setPriorityFeeLevel } = useContext(NetworkContext);
  const { connectedViaKeyPair, connectedViaWallet } = useContext(SignerContext);
  const buttonClasses = "[&_button]:cursor-pointer [&_button]:border-0 [&_button]:leading-none [&_button]:relative [&_button]:disabled:cursor-not-allowed [&_button]:flex [&_button]:ring-0 [&_button]:outline-0 [&_button]:text-gray-50 [&_button]:bg-gray-700 [&_button]:hover:bg-gray-500 [&_button]:active:bg-gray-700 [&_button]:font-normal [&_button]:disabled:text-gray-300 [&_button]:disabled:hover:bg-gray-700 [&_button]:px-4 [&_button]:h-[42px] [&_button]:rounded-md [&_button]:after:rounded-md [&_button]:items-center [&_button]:justify-center";

  return (
    <ul className="flex justify-between h-top-bar-height bg-gray-800 px-4 py-0 items-center relative z-40 shadow-black-regular">
      {/* HAMBURGER AND LOGO */}
      <li className="text-white flex align-center sm:space-x-5">
        <div
          className="flex align-center cursor-pointer"
          onClick={() => {
            navigate(``);
          }}
        >
          <div className="">
            ADMIN APP
          </div>
        </div>
      </li>

      <li className="flex gap-2">
        <DropdownInput
          classes={{ button: "py-0 h-[42px] rounded font-normal border-2 focus:border-2 border-gray-600" }}

          options={Object.values(PriorityFeeLevel).map((feeLevel) => ({ label: feeLevel, value: feeLevel }
          ))}
          selectedValue={priorityFeeLevel}
          onSelect={setPriorityFeeLevel}
        />
        <DropdownInput
          classes={{ button: "py-0 h-[42px] rounded font-normal border-2 focus:border-2 border-gray-600" }}

          options={ENABLED_NETWORKS.map((network) => (
            { label: network, value: network }
          ))}

          selectedValue={chain}
          onSelect={setChain}
        />
      </li>


      {/* CONNECT, LIST WALLETS */}
      <li className="flex gap-2">
        {!connectedViaKeyPair ?
          <WalletModalProvider>
            <div className={buttonClasses} >
              {connectedViaWallet ? <WalletDisconnectButton /> : <WalletMultiButton />}
            </div>
          </WalletModalProvider>
          : null}
        {!connectedViaWallet ? <Signers /> : null}
      </li>
    </ul>
  );
}
