import React, { FC } from "react";
import { twMerge } from "tailwind-merge";

export interface FlatTableProps {
  data: {
    title: string;
    value: string | number | React.ReactNode;
  }[];
  classes?: {
    wrapperClass?: string;
    itemClass?: string;
  };
}

const FlatTable: FC<FlatTableProps> = ({ data, classes }) => {
  return (
    <div className={twMerge("flex flex-wrap", `${classes?.wrapperClass}`)}>
      {data.map(({ title, value }) => (
        <div
          className={twMerge(
            "flex flex-col py-6.5 px-5 gap-2 border-gray-600 border-b-[1px] grow min-w-[15%]",
            `${classes?.itemClass}`,
          )}
        >
          <span className="text-sm text-gray-400 uppercase font-normal">{title}</span>
          {typeof value === "number" || typeof value === "string" ? (
            <span className="text-lg">{value}</span>
          ) : (
            value
          )}
        </div>
      ))}
    </div>
  );
};

export default FlatTable;
