import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useModal } from "react-modal-hook"

import AdminBodyItem from "../Containers/AdminBodyItem"
import { truncatePubkey } from "../../utils/string/string"
import IconButton from "../../components/common/button/IconButton"
import { BalanceContext, BalanceType } from "../Contexts/BalanceContext"
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard"
import Icon from "../../components/common/icon/Icon"
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting"
import FlatTable from "../Components/FlatTable/FlatTable"
import { Button } from "../../components/common/button/Button"
import { CrankerActionModal, CrankerActionModalType } from "../modals/nft-staking/CrankerActionModal"
import NftStaking from "../../sdk/nftStaking/nftStaking"
import { FundModal, FundModalType } from "../modals/nft-staking/FundModal"
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import { NftStakingContext } from "../Contexts/NftStakingContext"

export const NftStakingPage = () => {
    // CONTEXT
    const { nftStaking, updateNftStaking } = useContext(NftStakingContext)
    const { balanceByType } = useContext(BalanceContext)
    const { copyToClipboard } = useCopyToClipboard("Copied pubkey to clipboard")

    const updateNftStakingRef = useRef<() => Promise<void>>()
    const nftStakingRef = useRef<NftStaking>()

    useEffect(() => {
        updateNftStakingRef.current = updateNftStaking
    }, [updateNftStaking])

    useEffect(() => {
        nftStakingRef.current = nftStaking
    }, [nftStaking])

    useEffect(() => {
        const interval = setInterval(() => {
            if (nftStakingRef.current != null && updateNftStakingRef.current != null) {
                try {
                    updateNftStakingRef.current()
                } catch (err) {
                    console.error(`Error updating the NFT staking instance`, err)
                }
            }
        }, 20000)

        return () => {
            if (interval != null) {
                clearInterval(interval)
            }
        }
    }, [])

    // WRAPPERS
    const bannerMeta = useMemo(() => { }, [])
    const mainAccountData = useMemo(
        () => [
            {
                title: "STATUS",
                value: nftStaking?.mainStatus,
            },
            {
                title: "PLATFORM",
                value: nftStaking?.mainState.platform.toString(),
            },
            { title: "AUTHORITY", value: nftStaking?.mainState.authority.toString() },
            { title: "TOKEN MINT", value: nftStaking?.tokenMint?.toString() },
            {
                title: "TOKEN VAULT",
                value: nftStaking?.mainState.tokenVault.toString(),
            },
            {
                title: "CASHIER PROGRAM",
                value: nftStaking?.mainState.cashierProgram.toString(),
            },
            {
                title: "CAN CLAIM",
                value: nftStaking?.mainState.canClaimRewards ? 'TRUE' : 'FALSE',
            },
            {
                title: "UNSTAKING PENALTY",
                value: `${formatZeebitNumber(nftStaking?.mainState.unstakingPenaltyPerThousand / 1000, NumberType.PROBABILITY)}%`,
            },
            {
                title: "DISTRIBUTION PERIOD",
                value: Number(nftStaking?.mainState.distributionPeriodInterval),
            },
            {
                title: "INDIVIDUAL VS TRAIT",
                value: `${formatZeebitNumber(nftStaking?.mainState.individualVsTraitSplitPerThousand / 1000, NumberType.PROBABILITY)}%`,
            },
            {
                title: "ATTRIBUTES",
                value: nftStaking?.mainState.countAttributes,
            },
            {
                title: "TRAITS",
                value: nftStaking?.mainState.countTraits,
            },
            {
                title: "POPULATION",
                value: nftStaking?.mainState.countPopulation,
            },
            {
                title: "STAKED",
                value: nftStaking?.mainState.countStaked,
            }
        ],
        [nftStaking],
    );
    const distributionData = useMemo(
        () => [
            {
                title: "MAIN",
                value: nftStaking?.mainPubkey.toString(),
            },
            {
                title: "STATUS",
                value: nftStaking?.currentDistribution.status,
            },
            { title: "DISTRIBUTION TIME", value: nftStaking?.currentDistribution.distributionTime?.toLocaleString() },
            { title: "SELECTION TIME", value: nftStaking?.currentDistribution.attributeSelectionTime?.toLocaleString() },
            {
                title: "FREEZE TIME",
                value: nftStaking?.currentDistribution.freezeTime?.toLocaleString(),
            },
            {
                title: "PLATFORM SHARE",
                value: `$${(nftStaking?.currentDistribution.platformProfitShareAmount || 0) / Math.pow(10, 6)}`,
            },
            {
                title: "ROYALTIES SHARE",
                value: `$${(nftStaking?.currentDistribution.royaltiesShareAmount || 0) / Math.pow(10, 6)}`,
            },
            {
                title: "UNSTAKING SHARE",
                value: `$${(nftStaking?.currentDistribution.unstakingShareAmount || 0) / Math.pow(10, 6)}`,
            },
            {
                title: "DISCRETIONARY AMOUNT",
                value: `$${(nftStaking?.currentDistribution.discretionaryAmount || 0) / Math.pow(10, 6)}`,
            },
            {
                title: "ROLLOVER AMOUNT",
                value: `$${(nftStaking?.currentDistribution.rolloverAmount || 0) / Math.pow(10, 6)}`,
            }
        ],
        [nftStaking?.currentDistribution],
    );

    // MODAL CRANKER
    const [selectedCrankType, setSelectedCrankType] = useState<CrankerActionModalType>()
    const [showCrankerModal, hideCrankerModal] = useModal(
        ({ in: open }): JSX.Element => (
            <CrankerActionModal visible={open} hideModal={hideCrankerModal} type={selectedCrankType} />
        ),
        [selectedCrankType],
    );

    // MODAL FUND
    const [selectedFundType, setSelectedFundType] = useState<FundModalType>()
    const distributionBalances = useMemo(() => {
        return {
            platform: nftStaking?.currentDistribution.platformProfitShareAmount || 0,
            rollover: nftStaking?.currentDistribution.rolloverAmount || 0,
            royalties: nftStaking?.currentDistribution.royaltiesShareAmount || 0,
            unstaking: nftStaking?.currentDistribution.unstakingShareAmount || 0,
            dicretionary: nftStaking?.currentDistribution.discretionaryAmount || 0
        }
    }, [nftStaking])
    const [showFundModal, hideFundModal] = useModal(
        ({ in: open }): JSX.Element => (
            <FundModal visible={open} hideModal={hideFundModal} type={selectedFundType} balances={distributionBalances} />
        ),
        [selectedFundType],
    );

    // CAN CRANK METHODS - TODO REFRESH NFT INSTANCE ON CYCLE
    const crankerMeta = useMemo(() => {
        if (nftStaking == null || nftStaking.currentDistribution == null) {
            return {
                attribute: false,
                result: false,
                individuals: false
            }
        }
        const now = new Date()

        const canCrankAttribute = nftStaking.currentDistribution.status == "accruing"
            && nftStaking.currentDistribution.attributeSelectionTime != null && nftStaking.currentDistribution.attributeSelectionTime <= now

        if (canCrankAttribute == true) {
            return {
                attribute: true,
                result: false,
                individuals: false
            }
        }

        const canCrankResult = nftStaking.currentDistribution.status == "accruingAttributeSelected"
            && nftStaking.currentDistribution.distributionTime != null && nftStaking.currentDistribution.distributionTime <= now

        if (canCrankResult == true) {
            return {
                attribute: false,
                result: true,
                individuals: false
            }
        }

        const canCrankIndividuals = nftStaking.currentDistribution.status == "traitAndIndividualSelected"
            && nftStaking.currentDistribution.individualTraitRecordsLeftToUpdate != null && nftStaking.currentDistribution.individualTraitRecordsLeftToUpdate > 0

        if (canCrankIndividuals == true) {
            return {
                attribute: false,
                result: false,
                individuals: true
            }
        }

        return {
            attribute: false,
            result: false,
            individuals: false
        }
    }, [nftStaking])

    return <div className="flex flex-col gap-y-3">
        <AdminBodyItem title="NFT Staking Cranker Wallet">
            <div className="flex gap-10">
                <div className="flex flex-col gap-2">
                    <span className="text-gray-400 uppercase font-normal">Cranker Sol Balance</span>

                    <div className="flex gap-1.5 items-center">
                        {truncatePubkey(balanceByType?.get(BalanceType.NFT_CRANKER)?.pubkey.toString() || '', 5)}
                        <IconButton
                            size="xs"
                            iconName="copy"
                            onClick={() => copyToClipboard(balanceByType?.get(BalanceType.NFT_CRANKER)?.pubkey.toString() || '')}
                        />
                    </div>

                    <div className="flex gap-2 items-center">
                        <Icon iconUrl={getS3StaticFolderUrl("/static/tokens/sol.png")} size="xl" />
                        <span className="text-3xl">{formatZeebitNumber(balanceByType?.get(BalanceType.NFT_CRANKER)?.solBalance || 0, NumberType.TOKEN_AMOUNT, undefined, 8)}</span>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <span className="text-gray-400 uppercase font-normal">Oracle Feed</span>
                    <div className="flex gap-1.5 items-center">
                        {truncatePubkey(balanceByType?.get(BalanceType.ORACLE)?.pubkey.toString() || '', 5)}
                        <IconButton
                            size="xs"
                            iconName="copy"
                            onClick={() => copyToClipboard(balanceByType?.get(BalanceType.ORACLE)?.pubkey.toString() || '')}
                        />
                    </div>
                    <div className="flex gap-2 items-center">
                        <Icon iconUrl={getS3StaticFolderUrl("/static/tokens/sol.png")} size="xl" />
                        <span className="text-3xl">{formatZeebitNumber(balanceByType?.get(BalanceType.ORACLE)?.solBalance || 0, NumberType.TOKEN_AMOUNT, undefined, 8)}</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-2">
                <Button disabled={crankerMeta.attribute == false} onClick={() => {
                    setSelectedCrankType(CrankerActionModalType.ATTRIBUTE_SELECT)
                    showCrankerModal()
                }} variant="secondary" size="sm">
                    Crank Attribute Selection
                </Button>
                <Button disabled={crankerMeta.result == false} onClick={() => {
                    setSelectedCrankType(CrankerActionModalType.RESULT_SELECT)
                    showCrankerModal()
                }} variant="secondary" size="sm">
                    Crank Result Selection
                </Button>
                <Button disabled={crankerMeta.individuals == false} onClick={() => {
                    setSelectedCrankType(CrankerActionModalType.ALL_INDIVIDUALS)
                    showCrankerModal()
                }} variant="secondary" size="sm">
                    Crank Updating Individual Records
                </Button>
            </div>
        </AdminBodyItem>

        <AdminBodyItem
            title={`Main Account (${nftStaking?.mainPubkey.toString()})`}
        >
            <FlatTable data={mainAccountData} />
        </AdminBodyItem>

        <AdminBodyItem
            title={`Current Distribution Account`}
            rightHeaderContent={
                <div className="flex flex-row gap-x-2">
                    <Button onClick={() => {
                        setSelectedFundType(FundModalType.FUND)
                        showFundModal()
                    }} variant="secondary" size="sm">
                        Fund Distribution
                    </Button>
                    <Button onClick={() => {
                        setSelectedFundType(FundModalType.UN_FUND)
                        showFundModal()
                    }} variant="secondary" size="sm">
                        Un-Fund Distribution
                    </Button>
                </div>
            }
        >
            <FlatTable data={distributionData} />
        </AdminBodyItem>
    </div>
}