import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import Separator from "../../Components/Separator/Separator";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { addOrUpdateOracleListIx } from "../../sdk/house";
import { PublicKey } from "@solana/web3.js";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { sendTransaction } from "../../../sdk/transactions";
import { ProgramContext } from "../../../contexts";
import Input from "../../../components/common/input/Input";
import { useWallet } from "@solana/wallet-adapter-react";
import { SignerContext } from "../../Contexts/SignersContext";
import { HouseContext } from "../../Contexts/AdminHouseContext";

interface IUpdateOracleModalProps {
  visible: boolean;
  hideModal: Function;
  remove: boolean;
  oraclePubkeyInput?: PublicKey
}

export const OracleAddOrUpdateModal: FC<IUpdateOracleModalProps> = ({ visible, hideModal, remove, oraclePubkeyInput }) => {
  const { house, loadHouse } = useContext(HouseContext)
  const [noPermissions, setNoPermissions] = useState(false);
  const [oraclePubkeyString, setOraclePubkeyString] = useState<string>()
  const [oracleInputError, setOracleInputError] = useState();
  const { signTransaction } = useWallet();
  const { signerInput, signerInputError, authorityPubkey, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput } = useContext(SignerContext);

  const oraclePubkey = useMemo(() => {
    if (oraclePubkeyString == null) return

    try {
      const pubkey = new PublicKey(oraclePubkeyString)
      setOracleInputError(undefined)
      return pubkey
    } catch (err) {
      console.warn('Issue with the oracle pubkey', { oraclePubkeyString })
      setOracleInputError('Please enter a valid pubkey.')
    }
  }, [oraclePubkeyString])

  // IF THERE IS AN INPUT, TRY USE IT
  useEffect(() => {
    console.log({
      oraclePubkeyInput,
      str: oraclePubkeyInput?.toString()
    })
    setOraclePubkeyString(oraclePubkeyInput?.toString())
  }, [oraclePubkeyInput])


  // CLEAR INPUTS WHEN MODAL LOADS
  useEffect(() => {
    setPinInput(undefined)
  }, [visible])

  const noPermissionsContent = useMemo(() => {
    return (
      <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
        <div className="font-white text-3xl font-oxanium">No Permissions</div>
        <div className="text-xl text-gray-300">
          No permissions have been granted to selected wallet. Please contact Zeebit’s team when
          requesting DevTools access.
        </div>
        <Button
          variant="secondary"
          onClick={() => {
            // WIPE ALL THE INPUTS
            // emptyInputs();
          }}
        >
          Try Another Wallet
        </Button>
      </div>
    );
  }, []);

  const { client, chain } = useContext(NetworkContext)
  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext)
  const { meta } = useContext(ProgramContext)

  const addOrUpdateOracleList = useCallback(async () => {
    if (signerInputError || house == null || oraclePubkey == null || client == null) {
      console.error('Issue with inputs for update oracle', house)

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const ix = await addOrUpdateOracleListIx(house, oraclePubkey, remove, authorityPubkey)

      console.log({
        house,
        kp: authorityPubkey.toString(),
        or: oraclePubkey.toString(),
        remove,
        client
      })

      const sig = await sendTransaction(
        [ix],
        client,
        authorityPubkey,
        true,
        chain,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )

      toast(
        <BaseToast
          message={`Successfully ${remove == false ? 'removed' : 'added'} oracle ${oraclePubkey.toString()}: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE HOUSE
      await loadHouse()

      // CLEAR PIN AND SIGNER
      setPinInput(undefined)

      hideModal()
    } catch (err) {
      console.error('Issue updating the oracle', err)
      toast(
        <BaseToast
          message={`Issue updating the oracle. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [house, selectedSigner, oraclePubkey, pinInput, client, remove, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError, chain])

  return (
    <BaseModal open={visible} onClose={hideModal} title={`${remove == true ? 'Remove' : 'Add'} Oracle`}>
      <div className="max-h-[400px] flex flex-col justify-center items-center text-gray-400 w-full">
        {noPermissions ? noPermissionsContent : ""}
        {!noPermissions ? (
          <div className="flex flex-col items-center gap-y-4 w-full">
            {/* Status */}
            <FormItem label="Choose Oracle" className="flex-1 self-stretch">
              <Input
                placeholder={"Please enter oracle pubkey"}
                type="string"
                error={oracleInputError}
                classes={{ label: "h-[42px] flex-row", wrapper: "flex-1" }}
                value={oraclePubkeyString}
                onChange={(e) => {
                  setOraclePubkeyString(e.target.value);
                }}
              />
            </FormItem>
            <Separator />

            {/* Wallet */}
            {signerInput}

            {/* AUTH WALLET BUTTON */}
            <FormItem
              error={
                noPermissions ? "Selected wallet does not have permission for this action" : ""
              }
              className="flex flex-col gap-1 flex-1 self-stretch"
            >
              <Button
                isLoading={loading}
                disabled={house == null || selectedSigner == null || pinInput == null || oraclePubkey == null || client == null}
                onClick={addOrUpdateOracleList}
                variant="secondary"
              >
                {`${remove == true ? 'Remove' : 'Add'} Oracle`}
              </Button>
            </FormItem>
          </div>
        ) : (
          ""
        )}
      </div>
    </BaseModal>
  );
};
