import { BN } from "@coral-xyz/anchor";
import { PublicKey, SystemProgram, TransactionInstruction } from "@solana/web3.js";

import Game from "../../sdk/gameSpec";
import House from "../../sdk/house";
import GameSpec from "../../sdk/gameSpec";
import HouseToken from "../../sdk/houseToken";

export const updateGameSpecIx = async (
    gameSpec: Game,
    authority: PublicKey,
    status?: string,
    interval?: number,
    minWagerInHouseTokenUnits?: number,
    maxBetsPerInstanceToken?: number,
    maxBetsPerPlaceIxn?: number,
    maxBetsPerSettleIxn?: number,
    maxPlayersPerToken?: number,
    maxTokensPerInstance?: number,
    inactivityTimeoutSeconds?: number
): Promise<TransactionInstruction> => {
    const permissionPubkey = House.deriverPermissionPubkey(
        gameSpec.house.publicKey,
        authority,
        gameSpec.baseProgram.programId
    );
    const statusObj = new Object();
    statusObj[status] = {};
    return await gameSpec.baseProgram.methods.gameSpecUpdate({
        status: status != undefined ? statusObj as any : null,
        interval: interval != undefined ? interval as any : null,
        minWagerInHouseTokenUnits: minWagerInHouseTokenUnits != undefined ? new BN(minWagerInHouseTokenUnits) : null,
        maxBetsPerInstanceToken: maxBetsPerInstanceToken != undefined ? maxBetsPerInstanceToken : null,
        maxBetsPerPlaceIxn: maxBetsPerPlaceIxn != undefined ? maxBetsPerPlaceIxn : null,
        maxBetsPerSettleIxn: maxBetsPerSettleIxn != undefined ? maxBetsPerSettleIxn : null,
        maxPlayersPerToken: maxPlayersPerToken != undefined ? maxPlayersPerToken : null,
        maxTokensPerInstance: maxTokensPerInstance != undefined ? maxTokensPerInstance : null,
        inactivityTimeoutSeconds: inactivityTimeoutSeconds != undefined ? inactivityTimeoutSeconds : null,
        callbackRespondDiscriminator: null, // TODO: Update?
        callbackSettleDiscriminator: null, // TODO: Update?
        callbackExpireDiscriminator: null, // TODO: Update?
    }).accounts({
        payer: authority,
        house: gameSpec.house.publicKey,
        authority: authority,
        permission: permissionPubkey,
        gameSpec: gameSpec.publicKey,
        systemProgram: SystemProgram.programId
    }).instruction()
}

export const updateGameSpecStatusIx = async (
    gameSpec: Game,
    authority: PublicKey,
    status: string
) => {
    return await updateGameSpecIx(gameSpec, authority, status)
}

export const addGameSpecTokenIxn = async (
    house: House,
    tokenMintPubkey: PublicKey,
    gameSpec: GameSpec,
    status: "active" | "frozen" | "outFlowsSuspended" | "inFlowsSuspended",
    authority: PublicKey
): Promise<TransactionInstruction> => {

    const permissionPubkey = House.deriverPermissionPubkey(
        house.publicKey,
        authority,
        house.baseProgram.programId
    );
    const statusObj = new Object();
    statusObj[status] = {};
    const gameSpecTokenPubkey = gameSpec.deriveGameSpecTokenPubkey(
        tokenMintPubkey
    );
    const houseTokenPubkey = HouseToken.deriveHouseTokenPubkey(
        house.publicKey,
        tokenMintPubkey,
        house.baseProgram.programId
    );

    return await house.baseProgram.methods.gameSpecTokenInit({
        status: status != undefined ? statusObj as any : null,
    }).accounts({
        payer: authority,
        house: house.publicKey,
        houseToken: houseTokenPubkey,
        authority: authority,
        permission: permissionPubkey,
        gameSpec: gameSpec.publicKey,
        gameSpecToken: gameSpecTokenPubkey,
        tokenMint: tokenMintPubkey,
        systemProgram: SystemProgram.programId
    }).instruction()
}