import React, { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import Scrollbar from "../scrollbar/Scrollbar";

export type TableRow = {
  id: string;
  divider?: string | ReactNode;
  [key: string]:
  | {
    value?: string | number | ReactNode;
    classes?: string;
    onClick?: (id: string, column: string) => void;
  }
  | string
  | ReactNode;
  classes?: string;
};

export type TableColumn = {
  header: string | ReactNode;
  accessor: string; // this is the key in the row object
  classes?: string;
  headerClasses?: string;
};

export interface TableGridProps {
  rows?: TableRow[];
  columns: TableColumn[];
  classes?: {
    tableContainer?: string;
    table?: string;
    header?: string;
    headerCell?: string;
    body?: string;
    bodyCell?: string;
  };
  showOnMobile?: string[];
  variant?: "regular" | "striped";
  emptyMessage?: string | ReactNode;
  isLoading?: boolean;
  loadingRowsNumber?: number;
  showHeader?: boolean;
  onLoadMore?: Function;
  onRowClick?: (index: string | number) => void;
}

const TableGrid: FC<TableGridProps> = ({
  rows = [],
  columns,
  classes,
  showOnMobile = [],
  variant = "regular",
  emptyMessage = "No data to display",
  isLoading,
  loadingRowsNumber = 5,
  onLoadMore,
  showHeader = true,
  onRowClick,
}) => {
  const intersectionObserverElementRef = useIntersectionObserver(() => onLoadMore?.(rows));

  const gridClasses = () => {
    switch (showOnMobile?.length || columns.length) {
      case 2:
        return `grid-cols-[repeat(2,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 3:
        return `grid-cols-[repeat(3,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 4:
        return `grid-cols-[repeat(4,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 5:
        return `grid-cols-[repeat(5,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 6:
        return `grid-cols-[repeat(6,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 7:
        return `grid-cols-[repeat(7,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 8:
        return `grid-cols-[repeat(8,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 9:
        return `grid-cols-[repeat(9,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 10:
        return `grid-cols-[repeat(10,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 11:
        return `grid-cols-[repeat(11,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 12:
        return `grid-cols-[repeat(12,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 13:
        return `grid-cols-[repeat(13,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 14:
        return `grid-cols-[repeat(14,minmax(auto,1fr))] ${gridMobClasses()}`;
      case 15:
        return `grid-cols-[repeat(15,minmax(auto,1fr))] ${gridMobClasses()}`;
      default:
        return `grid-cols-[repeat(1,minmax(auto,1fr))] ${gridMobClasses()}`;
    }
  };

  const gridMobClasses = () => {
    switch (columns.length) {
      case 2:
        return "@md/table:grid-cols-[repeat(2,minmax(auto,1fr))] ";
      case 3:
        return "@md/table:grid-cols-[repeat(3,minmax(auto,1fr))] ";
      case 4:
        return "@md/table:grid-cols-[repeat(4,minmax(auto,1fr))] ";
      case 5:
        return "@md/table:grid-cols-[repeat(5,minmax(auto,1fr))] ";
      case 6:
        return "@md/table:grid-cols-[repeat(6,minmax(auto,1fr))]";
      case 7:
        return "@md/table:grid-cols-[repeat(7,minmax(auto,1fr))] ";
      case 8:
        return "@md/table:grid-cols-[repeat(8,minmax(auto,1fr))] ";
      case 9:
        return "@md/table:grid-cols-[repeat(9,minmax(auto,1fr))] ";
      case 10:
        return "@md/table:grid-cols-[repeat(10,minmax(auto,1fr))] ";
      case 11:
        return "@md/table:grid-cols-[repeat(11,minmax(auto,1fr))] ";
      case 12:
        return "@md/table:grid-cols-[repeat(12,minmax(auto,1fr))] ";
      case 13:
        return "@md/table:grid-cols-[repeat(13,minmax(auto,1fr))] ";
      case 14:
        return "@md/table:grid-cols-[repeat(14,minmax(auto,1fr))] ";
      case 15:
        return "@md/table:grid-cols-[repeat(15,minmax(auto,1fr))] ";
      default:
        return "@md/table:grid-cols-[repeat(1,minmax(auto,1fr))] ";
    }
  };

  const dividerMobClasses = () => {
    const length = showOnMobile?.length || columns.length;

    switch (length) {
      case 2:
        return `col-span-2 ${dividerClasses()}`;
      case 3:
        return `col-span-3 ${dividerClasses()}`;
      case 4:
        return `col-span-4 ${dividerClasses()}`;
      case 5:
        return `col-span-5 ${dividerClasses()}`;
      case 6:
        return `col-span-6 ${dividerClasses()}`;
      case 7:
        return `col-span-7 ${dividerClasses()}`;
      case 8:
        return `col-span-8 ${dividerClasses()}`;
      case 9:
        return `col-span-9 ${dividerClasses()}`;
      case 10:
        return `col-span-10 ${dividerClasses()}`;
      case 11:
        return `col-span-11 ${dividerClasses()}`;
      case 12:
        return `col-span-12 ${dividerClasses()}`;
      case 13:
        return `col-span-13 ${dividerClasses()}`;
      case 14:
        return `col-span-14 ${dividerClasses()}`;
      case 15:
        return `col-span-15 ${dividerClasses()}`;
      default:
        return `col-span-1 ${dividerClasses()}`;
    }
  };

  const dividerClasses = () => {
    switch (columns.length) {
      case 1:
        return "@md/table:col-span-1 ";
      case 2:
        return "@md/table:col-span-2 ";
      case 3:
        return "@md/table:col-span-3 ";
      case 4:
        return "@md/table:col-span-4 ";
      case 5:
        return "@md/table:col-span-5 ";
      case 6:
        return "@md/table:col-span-6 ";
      case 7:
        return "@md/table:col-span-7 ";
      case 8:
        return "@md/table:col-span-8 ";
      case 9:
        return "@md/table:col-span-9 ";
      case 10:
        return "@md/table:col-span-10 ";
      case 11:
        return "@md/table:col-span-11 ";
      case 12:
        return "@md/table:col-span-12 ";
      case 13:
        return "@md/table:col-span-13 ";
      case 14:
        return "@md/table:col-span-14 ";
      case 15:
        return "@md/table:col-span-15 ";
      default:
        return "@md/table:col-span-1 ";
    }
  };

  const showOnMobileClasses = "hidden @md/table:flex";

  const baseCellClasses =
    "flex justify-center first:justify-start last:justify-end text-center last:text-right first:text-left";

  const getBreakpointClasses = ({ accessor }: TableColumn) => {
    if (!showOnMobile?.length || showOnMobile?.includes(accessor)) {
      return "";
    }
    return showOnMobileClasses;
  };

  const headerCellView = (column: TableColumn) => {
    return (
      <div
        data-id="header-cell"
        className={twMerge(
          "p-2 text-xs font-normal uppercase text-gray-400",
          column.classes,
          baseCellClasses,
          getBreakpointClasses(column),
          classes?.headerCell,
          column.headerClasses,
        )}
        key={column.accessor}
      >
        {column.header}
      </div>
    );
  };

  const bodyCellRender = (column: TableColumn, row: TableRow) => {
    return (
      <div
        data-id="body-cell"
        key={`${column.accessor}-${row.id}`}
        onClick={(e) => {
          if (row[column.accessor]?.onClick) {
            e.stopPropagation();
            row[column.accessor]?.onClick?.(row.id, column.accessor);
          }
        }}
        className={twMerge(
          baseCellClasses,
          `flex items-center bg-gray-700 p-2 text-sm first:rounded-l-md last:rounded-r-md`,
          onRowClick ? "cursor-pointer " : "",
          variant === "regular" ? "group-hover/row:bg-gray-600 group-active/row:bg-gray-700" : "",
          variant === "striped" ? "group-odd/row:bg-gray-700 group-even/row:bg-gray-800" : "",
          getBreakpointClasses(column),
          classes?.bodyCell,
          column.classes,
          row.classes,
          row[column.accessor]?.classes,
        )}
      >
        {typeof row[column.accessor] === "object" && row[column.accessor]?.value
          ? row[column.accessor]?.value
          : row[column.accessor]}
      </div>
    );
  };

  const rowRender = (row: TableRow, idx: number) => {
    if (row.divider) {
      return (
        <div
          data-id="table-row-divider"
          key={`${row.id}-divider`}
          className={twMerge("contents", row.classes)}
        >
          <div
            className={twMerge(
              dividerMobClasses(),
              "flex items-center justify-center rounded bg-gray-700 p-1 text-xs",
            )}
          >
            {row.divider}
          </div>
        </div>
      );
    }
    return (
      <div
        data-id="table-row"
        key={row.id}
        className={twMerge("group/row contents", row.classes)}
        onClick={() => onRowClick?.(row.id || idx)}
      >
        {columns.map((column) => bodyCellRender(column, row))}
      </div>
    );
  };

  const loader = <div className="h-3 w-full animate-pulse rounded bg-gray-600 px-3" />;
  const loadingRows = Array.from(Array(loadingRowsNumber).keys()).map((key) =>
    columns.reduce(
      (a, { accessor }) => ({
        ...a,
        id: `loader-${accessor}-${key}`,
        [accessor]: loader,
      }),
      {} as TableRow,
    ),
  );

  return (
    <div
      data-id="table"
      className={twMerge(
        `w-full max-w-full  @container/table`,
        classes?.tableContainer,
      )}
    >
      <Scrollbar>
        <div
          data-id="table"
          className={twMerge(
            `grid gap-y-2 font-normal`,
            variant === "striped" ? "gap-y-0" : "",
            gridClasses(),
            classes?.table,
          )}
        >
          {showHeader ?
            <div data-id="header-row" key={"header"} className={twMerge("contents", classes?.header)}>
              {columns.map((column) => headerCellView(column))}
            </div> : null}

          {rows?.length ? rows.map(rowRender) : null}
          {isLoading ? loadingRows.map(rowRender) : null}
        </div>

        {!rows?.length && !isLoading ? (
          <div className="flex w-full items-center justify-center py-10 text-gray-400">
            {emptyMessage}
          </div>
        ) : null}
        <div ref={intersectionObserverElementRef} />
      </Scrollbar>
    </div>
  );
};

export default TableGrid;
