import React, { FC } from "react";
import { twMerge } from "tailwind-merge";

export interface SeparatorProps {
  className?: string
}

const Separator: FC<SeparatorProps> = ({ className }) => {
  return (
    <div className={twMerge("w-full border-gray-600 border-b-[1px]", className)} />
  );
};

export default Separator;
