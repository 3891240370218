import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AdminBodyItem from "../Containers/AdminBodyItem";
import Button from "../../components/common/button/Button";
import FlatTable from "../Components/FlatTable/FlatTable";
import { Tab, TabSize } from "../../components/common/tab/Tab";
import TableGrid from "../../components/common/table-grid/TableGrid";
import { twMerge } from "tailwind-merge";
import { IReferralMetric, IReferralScheme, IReferralSchemeConfig, IReferralSettlement, IReferralStat } from "../../utils/supabase/types";
import { useModal } from "react-modal-hook";
import { AuthenticateModal } from "../modals/referrals/AuthenticateModal";
import { DataCachingContext } from "../Contexts/DataCachingContext";
import { adminApproveReferralSettlements, fetchAdminReferralSettlements, getAdminReferralConfig, getAdminReferralMetrics, getAdminReferralSchemes, getAdminReferralStats, processReferralSettlements, updateRatesAndSetForApproval } from "../../utils/supabase/supabase";
import { ReferralConfigModal } from "../modals/referrals/ReferralConfigModal";
import { ReferralSchemeModal } from "../modals/referrals/ReferralSchemeModal";
import Input from "../../components/common/input/Input";
import { formatZeebitNumber, NumberType } from "../../utils/currency/formatting";
import DropdownInput from "../../components/common/dropdown-input/DropdownInput";
import { ReferralSettlementModalType, ReferralSettlementsModal } from "../modals/referrals/ReferralSettlementsModal";
import { NetworkContext, ToasterContext } from "../../contexts";
import { Connection } from "@solana/web3.js";
import { BASE_TOAST_CONFIG, BaseToast } from "../../components/toast/BaseToast";

export enum ReferralSettlementStatus {
  INITIALISED = 'initialised',
  PENDING_APPROVAL = 'pending approval',
  APPROVED = 'approved',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  READY_FOR_RETRY = 'ready for retry',
  ERROR = 'error'
}

export const ReferralsPage = () => {
  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext);

  // CHECK THE LOGGED IN WALLETS VS THE ADMIN WALLETS IN THE DB
  const { jwt } = useContext(DataCachingContext)
  
  // MODAL USED TO LOAD / REFRESH A JWT IF NEEDED
  const [showAuthenticateModal, hideAuthenticateModal] = useModal(
      ({ in: open }): JSX.Element => (
        <AuthenticateModal visible={open} hideModal={hideAuthenticateModal} />
      ),
      [],
    );
  const tabs = ["config", "schemes", "stats", "metrics", "settlements"];
  const [currentTab, setCurrentTab] = useState<(typeof tabs)[number]>("config");
  
  // OVERALL REFERRAL CONFIG
  const [referralConfig, setReferralConfig] = useState<IReferralSchemeConfig>()
  const [referralSchemes, setReferralSchemes] = useState<IReferralScheme[]>()

  // USED FOR THE EDIT SCHEME MODAL
  const [selectedScheme, setSelectedScheme] = useState<IReferralScheme>()

  const referralConfigData = useMemo(
    () => [
      {
        title: "id",
        value: referralConfig?.id,
      },
      { title: "created at", value: referralConfig?.created_at },
      { title: "defaultReferralRatePerThousand", value: referralConfig?.defaultReferralRatePerThousand },
      { title: "maxEnhancedReferralRatePerThousand", value: referralConfig?.maxEnhancedReferralRatePerThousand },
      { title: "minReferredWagerValueToCollect", value: referralConfig?.minReferredWagerValueToCollect },
      { title: "maxReferralSchemesPerPlayer", value: referralConfig?.maxReferralSchemesPerPlayer },
    ],
    [referralConfig],
  );

  const loadReferralConfig = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    try {
      const config = await getAdminReferralConfig(payload)
    
      setReferralConfig(config[0])
    } catch(err) {
      console.warn(`Issue loading referral config`, {err})
    }
  }, [jwt])

  useEffect(() => {
    // LOAD THE REFERRAL CONFIG
    async function loadReferralConfig(jwtPayload: {
      jwt: string
    }) {
      try {
        const referralConfigResp = await getAdminReferralConfig(jwtPayload)
      
        setReferralConfig(referralConfigResp[0])
      } catch(err) {
        console.warn(`Err loading referral config`, {
          err
        })
      }
    }

    if (jwt == null) {
      return
    }

    loadReferralConfig(jwt)
  }, [jwt])

  // USED FOR FILTERING REFERRAL SCHEMES
  const [referralId, setReferralId] = useState<string>()
  const [wallet, setWallet] = useState<string>()

  const loadReferralSchemes = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    try {
      const referralSchemesResp = await getAdminReferralSchemes(payload)
    
      setReferralSchemes(referralSchemesResp)
    } catch(err) {
      console.warn(`Issue loading referral schemes`, {err})
    }
  }, [jwt, referralId, wallet])

  const [referralStats, setReferralStats] = useState<IReferralStat[]>()

  const loadReferralStats = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    try {
      const referralStatsResp = await getAdminReferralStats(payload)
    
      setReferralStats(referralStatsResp)
    } catch (err) {
      console.warn(`Issue loadig referral stats`, {err})
    }
  }, [jwt, referralId, wallet])

  useEffect(() => {
    async function loadReferralSchemes(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string
    }) {
      try {
        const referralSchemesResp = await getAdminReferralSchemes(jwtPayload)
      
        setReferralSchemes(referralSchemesResp)
      } catch (err) {
        console.warn(`Err loafing schemes`, {err})
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    loadReferralSchemes(payload)
  }, [jwt])

  useEffect(() => {
    async function loadReferralStats(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string
    }) {
      try {
        const referralStatsResp = await getAdminReferralStats(jwtPayload)
      
        setReferralStats(referralStatsResp)
      } catch (err) {
        console.log(`Err loading stats`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    loadReferralStats(payload)
  }, [jwt])

  // LOAD REFERRAL METRICS
  const [referralMetrics, setReferralMetrics] = useState<IReferralMetric[]>()
  useEffect(() => {
    async function loadReferralMetrics(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string
    }) {
      try {
        const referralMetricsResp = await getAdminReferralMetrics(jwtPayload)
      
        setReferralMetrics(referralMetricsResp)
      } catch (err) {
        console.log(`Err loading metrics`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    loadReferralMetrics(payload)
  }, [jwt])

  const loadReferralMetrics = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    try {
      const referralMetricsResp = await getAdminReferralMetrics(payload)
    
      setReferralMetrics(referralMetricsResp)
    } catch(err) {
      console.warn(`Issue loading referral metrics`, {err})
    }
  }, [jwt, referralId, wallet])


  // LOAD REFERRAL METRICS
  const [referralStatus, setReferralStatus] = useState<ReferralSettlementStatus>()
  const [referralSettlements, setReferralSettlements] = useState<IReferralSettlement[]>()
  useEffect(() => {
    async function loadReferralSettlements(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string,
      status?: ReferralSettlementStatus
    }) {
      try {
        const referralSettlementsResp = await fetchAdminReferralSettlements(jwtPayload)
      
        setReferralSettlements(referralSettlementsResp)
      } catch (err) {
        console.log(`Err loading metrics`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    if (referralStatus != null && referralStatus.length > 0) {
      payload.status = referralStatus
    }

    loadReferralSettlements(payload)
  }, [jwt, referralStatus])

  const loadReferralSettlements = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    if (referralStatus != null) {
      payload.status = referralStatus
    }

    try {
      const referralSettlementsResp = await fetchAdminReferralSettlements(payload)
    
      setReferralSettlements(referralSettlementsResp)
    } catch(err) {
      console.warn(`Issue loading referral settlements`, {err})
    }
  }, [jwt, referralId, wallet])

  const setRatesAndPrepareForApproval = useCallback(async (referralIds?: string[]) => {
    setLoading(true)
    let payload: any = {
      ...jwt
    }

    if (referralIds != null && referralIds.length > 0) {
      payload.referralIds = referralIds
    }

    try {
      
      const setRatesResp = await updateRatesAndSetForApproval(payload)

      // RELOAD THE SETTLEMENTS
      await loadReferralSettlements()
      toast(
        <BaseToast
            message={`Successfully set rates.`}
            type={"success"}
        />,
        BASE_TOAST_CONFIG,
    );
    } catch(err) {
      console.warn(`Issue setRatesAndPrepareForApproval`, {err})
      toast(
        <BaseToast
            message={`Issue setting rates. ${JSON.stringify(err)}`}
            type={"error"}
        />,
        BASE_TOAST_CONFIG,
    );
    } finally {
      setLoading(false)
    }
  }, [jwt, wallet, loadReferralSettlements, toast])

  const approveReferralSettlements = useCallback(async (referralIds?: string[]) => {
    setLoading(true)
    let payload: any = {
      ...jwt
    }

    if (referralIds != null && referralIds.length > 0) {
      payload.referralIds = referralIds
    }

    try {
      const approveReferralSettlementsResp = await adminApproveReferralSettlements(payload)

      // RELOAD THE SETTLEMENTS
      await loadReferralSettlements()
      toast(
        <BaseToast
            message={`Successfully approved the rates.`}
            type={"success"}
        />,
        BASE_TOAST_CONFIG,
    );

    } catch(err) {
      console.warn(`Issue approving referral settlements`, {err})
      toast(
        <BaseToast
            message={`Issue approving rates. ${JSON.stringify(err)}`}
            type={"error"}
        />,
        BASE_TOAST_CONFIG,
    );
    } finally {
      setLoading(false)
    }
  }, [jwt, wallet, loadReferralSettlements, toast])

  const { solanaClient } = useContext(NetworkContext)
  const confirmPayments = useCallback(async (referralIds?: string[]) => {
    setLoading(true)
    let payload: any = {
      ...jwt
    }

    const connection = new Connection(solanaClient.rpcEndpoint, {
      commitment: 'confirmed'
    })

    // LOAD RECORDS IN APPROVED STATUS, PAST END TIME, WITH NO SIG OR TX META
    const status = ReferralSettlementStatus.PROCESSING
    payload.status = status

    if (referralIds != null && referralIds.length > 0) {
      payload.referralIds = referralIds
    }

    try {
      const settlementsProcessing = await fetchAdminReferralSettlements(payload)
      const settlementsBySignature = settlementsProcessing.reduce((result, item) => {
        if (item.settlementSignature != null && item.status == status) {
          result.set(item.settlementSignature, item)
        }

        return result
      }, new Map<string, IReferralSettlement>())

      const signatureStatuses = settlementsProcessing.filter((settlement) => {
        return settlement.status == status && settlement.settlementSignature != null
      }).map((settlement) => {
        return String(settlement.settlementSignature)
      })

      console.log({
        signatureStatuses,
        solanaClient: connection.rpcEndpoint
      })

      if (signatureStatuses == null || signatureStatuses.length == 0) {
        return
      }

      const slot = await connection.getSlot("confirmed")

      const txns = await connection.getParsedTransactions(signatureStatuses, {
        maxSupportedTransactionVersion: 0,
        commitment: "confirmed"
      },)
      console.log({
        txns
      })

      const signatureMetas = await connection.getSignatureStatuses(signatureStatuses, {
        searchTransactionHistory: true
      })

      const processed: IReferralSettlement[] = []

      // GO THROUGH EACH, CHECK FOR CONFIRMED, OR ERROR
      signatureMetas.value.forEach((signatureMeta, index) => {
        const hasError = signatureMeta?.err != null
        const signature = signatureStatuses[index]
        const dbRecord = settlementsBySignature.get(signature)
        const isConfirmed = signatureMeta?.confirmationStatus != null
        const isReadyForRetry = dbRecord?.settlementSignature != null && dbRecord?.settlementTxnSlot != null && (slot > (dbRecord.settlementTxnSlot + 200))

        if (signature != null && dbRecord != null && signature == dbRecord.settlementSignature) {
          if (hasError == true) {
            processed.push({
              ...dbRecord,
              status: ReferralSettlementStatus.ERROR
            })
          } else if (isConfirmed == true) {
            processed.push({
              ...dbRecord,
              settlementTxnStatus: signatureMeta?.confirmationStatus,
              status: ReferralSettlementStatus.COMPLETED
            })
          } else if (isReadyForRetry) {
            processed.push({
              ...dbRecord,
              status: ReferralSettlementStatus.READY_FOR_RETRY
            })
          }
        }
      })

      if (processed != null && processed.length > 0) {
        const dbUpdate = await processReferralSettlements({
            jwt: jwt?.jwt,
            records: processed
        })
      }

      // RELOAD THE SETTLEMENTS
      await loadReferralSettlements()

      toast(
        <BaseToast
            message={`Successfully confirmed the settlements.`}
            type={"success"}
        />,
        BASE_TOAST_CONFIG,
    );
    } catch(err) {
      console.warn(`Issue loading referral metrics`, {err})
      toast(
        <BaseToast
            message={`There was an issue confirming the settlements.`}
            type={"error"}
        />,
        BASE_TOAST_CONFIG,
    );
    } finally {
      setLoading(false)
    }
  }, [jwt, wallet, loadReferralSettlements, solanaClient, toast])

  const setReadyForRetry = useCallback(async (referralIds?: string[]) => {
    setLoading(true)
    let payload: any = {
      ...jwt
    }

    // LOAD RECORDS IN APPROVED STATUS, PAST END TIME, WITH NO SIG OR TX META
    const status = ReferralSettlementStatus.ERROR
    payload.status = status

    if (referralIds != null && referralIds.length > 0) {
      payload.referralIds = referralIds
    }

    try {
      const errorSettlements = await fetchAdminReferralSettlements(payload)

      // CHECK THEY ARE ACTUALLY ERROR
      const actualErrors = errorSettlements.filter((settlement) => {
        return settlement.status == ReferralSettlementStatus.ERROR
      })

      const processed: IReferralSettlement[] = [...actualErrors].map((settlement) => {
        return {
          ...settlement,
          status: ReferralSettlementStatus.READY_FOR_RETRY
        }
      })

      if (processed != null && processed.length > 0) {
        const dbUpdate = await processReferralSettlements({
            jwt: jwt?.jwt,
            records: processed
        })
      } else {
        throw new Error("No referral settlements in error status to update.")
      }

      // RELOAD THE SETTLEMENTS
      await loadReferralSettlements()

      toast(
        <BaseToast
            message={`Successfully updated for retry.`}
            type={"success"}
        />,
        BASE_TOAST_CONFIG,
    );
    } catch(err) {
      console.warn(`Issue updating for retry`, {err})
      toast(
        <BaseToast
            message={`There was an issue updating for retry.`}
            type={"error"}
        />,
        BASE_TOAST_CONFIG,
    );
    } finally {
      setLoading(false)
    }
  }, [jwt, wallet, loadReferralSettlements, solanaClient, toast])

   // MODAL USED TO LOAD / REFRESH A JWT IF NEEDED
   const [showReferralConfigModal, hideReferralConfigModal] = useModal(
    ({ in: open }): JSX.Element => (
      <ReferralConfigModal referralConfig={referralConfig} loadReferralConfig={loadReferralConfig} visible={open} hideModal={hideReferralConfigModal} />
    ),
    [referralConfig, loadReferralConfig],
  );

  // MODAL USED TO LOAD / REFRESH A JWT IF NEEDED
  const [showReferralSchemeModal, hideReferralSchemeModal] = useModal(
    ({ in: open }): JSX.Element => (
      <ReferralSchemeModal referralScheme={selectedScheme} reloadSchemes={loadReferralSchemes} visible={open} hideModal={hideReferralSchemeModal} />
    ),
    [selectedScheme, loadReferralSchemes],
  );

  const [type, setType] = useState<ReferralSettlementModalType>()
  const [showReferralSettlementsModal, hideReferralSettlementsModal] = useModal(
    ({ in: open }): JSX.Element => (
      <ReferralSettlementsModal visible={open} hideModal={async () => {
        // RELOAD THE DATA
        await loadReferralSettlements()

        hideReferralSettlementsModal()
      }} type={type} />
    ),
    [type, loadReferralSettlements],
  );

  const tabContent = () => {
    // TODO - ACTIONS ONCE HOUSE ACTIONS ARE THERE
    if (currentTab === "config") {
      return (
        <>
          <AdminBodyItem title="Referral Config">
            <div className="flex justify-end items-center gap-x-2">
              <Button disabled={jwt == null || referralConfig == null} variant="secondary" onClick={showReferralConfigModal}>
                Change Config
              </Button>
              <Button variant="secondary" onClick={showAuthenticateModal}>
                Authenticate
              </Button>
            </div>
          </AdminBodyItem>
          <AdminBodyItem
            title="Referral Config"
          >
            <FlatTable data={referralConfigData} />
          </AdminBodyItem>
        </>
      );
    }
    if (currentTab === "schemes") {
      const formattedSchemeRows = (referralSchemes || [])?.map((scheme) => {
        return (
          {
            identifier: scheme.identifier,
            wallet: scheme.wallet,
            accrualExpiryDate: scheme.accrualExpiryDate,
            defaultReferralRatePerThousand: scheme.defaultReferralRatePerThousand,
            enhancedAccrualExpiryDate: scheme.enhancedAccrualExpiryDate,
            enhancedReferralRatePerThousand: scheme.enhancedReferralRatePerThousand,
            status: scheme.status,
            edit: (
              <div className="flex gap-3">
                <Button
                  onClick={() => {
                    setSelectedScheme(scheme)
                    showReferralSchemeModal()
                   // TODO - SET SCHEME
                   // OPEN MODAL
                  }}
                >
                  Change Scheme
                </Button>
              </div>

            )
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="Referral Schemes"
          titleSuffix={<span className="text-gray-300 text-xl">{referralSchemes?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={referralId} onChange={(e) => {
                        setReferralId(e.target.value);
                    }} placeholder="Referral Id" />
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={wallet} onChange={(e) => {
                        setWallet(e.target.value);
                    }} placeholder="Wallet Pubkey" />
              <Button onClick={loadReferralSchemes}>Load</Button>

              <Button  onClick={showAuthenticateModal}>Authenticate</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedSchemeRows}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "identifier",
                accessor: "identifier",
              },
              {
                header: "wallet",
                accessor: "wallet",
              },
              {
                header: "accrualExpiryDate",
                accessor: "accrualExpiryDate",
              },
              {
                header: "defaultReferralRatePerThousand",
                accessor: "defaultReferralRatePerThousand",
              },
              {
                header: "enhancedAccrualExpiryDate",
                accessor: "enhancedAccrualExpiryDate",
              },
              {
                header: "enhancedReferralRatePerThousand",
                accessor: "enhancedReferralRatePerThousand",
              },
              {
                header: "status",
                accessor: "status",
              },
              {
                header: "Edit",
                accessor: "edit",
                classes: " right-0 bg-gray-800"
              },
            ]}
            variant="regular"
            emptyMessage="No Referral Schemes"
          />
        </AdminBodyItem>
      )
    }
    if (currentTab === "stats") {
      const formattedStatRows = (referralStats || [])?.map((stat) => {
        return (
          {
            identifier: stat.identifier,
            referredPlayers: stat.referredPlayers,
            totalBets: stat.totalBets,
            totalReferralCommissionAccrued: stat.totalReferralCommissionAccrued,
            totalReferralCommissionDrawndown: stat.totalReferralCommissionDrawndown,
            totalWagered: stat.totalWagered
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="Referral Schemes"
          titleSuffix={<span className="text-gray-300 text-xl">{referralSchemes?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={referralId} onChange={(e) => {
                        setReferralId(e.target.value);
                    }} placeholder="Referral Id" />
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={wallet} onChange={(e) => {
                        setWallet(e.target.value);
                    }} placeholder="Wallet Pubkey" />
              <Button onClick={loadReferralStats}>Load</Button>

              <Button  onClick={showAuthenticateModal}>Authenticate</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedStatRows}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "identifier",
                accessor: "identifier",
              },
              {
                header: "referredPlayers",
                accessor: "referredPlayers",
              },
              {
                header: "totalBets",
                accessor: "totalBets",
              },
              {
                header: "totalReferralCommissionAccrued",
                accessor: "totalReferralCommissionAccrued",
              },
              {
                header: "totalReferralCommissionDrawndown",
                accessor: "totalReferralCommissionDrawndown",
              },
              {
                header: "totalWagered",
                accessor: "totalWagered",
              },
            ]}
            variant="regular"
            emptyMessage="No Referral Stats"
          />
        </AdminBodyItem>
      )
    }
    if (currentTab === "metrics") {
      const formattedMetricsRows = (referralMetrics || [])?.map((metric) => {
        return (
          {
            identifier: metric.referralId,
            chain: metric.chain,
            countPlayers: metric.countPlayers,
            date: metric.date,
            platform: metric.platform,
            totalExpectedEdge: metric.totalExpectedEdge,
            totalExpectedReferralCommission: metric.totalExpectedReferralCommission,
            totalLossCount: metric.totalLossCount,
            totalPaidOut: metric.totalPaidOut,
            totalWagered: metric.totalWagered,
            pnl: metric.totalPaidOut - metric.totalWagered,
            totalWinCount: metric.totalWinCount
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="Daily Referral Metrics"
          titleSuffix={<span className="text-gray-300 text-xl">{referralMetrics?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={referralId} onChange={(e) => {
                        setReferralId(e.target.value);
                    }} placeholder="Referral Id" />
              <Button onClick={loadReferralMetrics}>Load</Button>

              <Button  onClick={showAuthenticateModal}>Authenticate</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedMetricsRows}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "Date",
                accessor: "date",
              },
              {
                header: "identifier",
                accessor: "identifier",
              },
              {
                header: "count players",
                accessor: "countPlayers",
              },
              {
                header: "total payout",
                accessor: "totalPaidOut",
              },
              {
                header: "total wagered",
                accessor: "totalWagered",
              },
              {
                header: "total pnl",
                accessor: "pnl",
              },
            ]}
            variant="regular"
            emptyMessage="No Referral Metrics"
          />
        </AdminBodyItem>
      )
    }
    if (currentTab === "settlements") {
      const formattedSettlementRows = (referralSettlements || [])?.map((settlement) => {
        // CASINO PNL VALUES HERE
        const thisMonthPnl = settlement.totalWagered - settlement.totalPaidOut
        const previousMonthPnl = (settlement.previousMonthWagered || 0) - (settlement.previousMonthPaidOut || 0)
        let aggregatePnl = 0

        if (thisMonthPnl > 0) {
          if (previousMonthPnl >= 0) {
            aggregatePnl = thisMonthPnl
          } else {
            aggregatePnl = thisMonthPnl + previousMonthPnl
          }
        }

        return (
          {
            chain: settlement.chain,
            identifier: settlement.referralId,
            startDate: settlement.startDate,
            referralRate: `${formatZeebitNumber((settlement.referralRate || 0), NumberType.PROBABILITY)}%`,
            owner: settlement.owner,
            status: settlement.status,
            totalPaidOut: `$${formatZeebitNumber(settlement.totalPaidOut, NumberType.DOLLAR_AMOUNT)}`,
            totalWagered:  `$${formatZeebitNumber(settlement.totalWagered, NumberType.DOLLAR_AMOUNT)}`,
            pnlThisMonth: `$${formatZeebitNumber(thisMonthPnl, NumberType.DOLLAR_AMOUNT)}`,
            pnlLastMonth: `$${formatZeebitNumber(previousMonthPnl, NumberType.DOLLAR_AMOUNT)}`,
            aggPnl: `$${formatZeebitNumber(aggregatePnl, NumberType.DOLLAR_AMOUNT)}`,
            referralComission: `$${formatZeebitNumber(aggregatePnl * (settlement.referralRate || 0), NumberType.DOLLAR_AMOUNT)}`,
            settlementSignature: settlement.settlementSignature,
            settlementTxnStatus: settlement.settlementTxnStatus,
            settlementTxnSlot: settlement.settlementTxnSlot,
            action: (settlement.status == ReferralSettlementStatus.PENDING_APPROVAL ?
              <div className="flex gap-3">
                <Button
                  isLoading={loading}
                  onClick={async () => {
                    try {
                      setLoading(true)
                      const approved = await approveReferralSettlements([settlement.referralId])
                      toast(
                        <BaseToast
                            message={`Approved referral settlement ${settlement.referralId}`}
                            type={"success"}
                        />,
                        BASE_TOAST_CONFIG,
                    );
                    } catch (err) {
                      toast(
                        <BaseToast
                            message={`There was an issue approving ${settlement.referralId}. ${JSON.stringify(err)}`}
                            type={"error"}
                        />,
                        BASE_TOAST_CONFIG,
                    );
                    } finally {
                      setLoading(false)
                    }
                  }}
                >
                  Approve
                </Button>
              </div>: settlement.status == ReferralSettlementStatus.INITIALISED ? <div className="flex gap-3">
                <Button
                  isLoading={loading}
                  onClick={async () => {
                    try {
                      setLoading(true)
                      const setRatesResp = await setRatesAndPrepareForApproval([settlement.referralId])
                      toast(
                        <BaseToast
                            message={`Successfully set the rate for ${settlement.referralId}.`}
                            type={"success"}
                        />,
                        BASE_TOAST_CONFIG,
                    );
                    } catch (err) {
                      toast(
                        <BaseToast
                            message={`There was an issue setting the rate for ${settlement.referralId}. ${JSON.stringify(err)}`}
                            type={"error"}
                        />,
                        BASE_TOAST_CONFIG,
                    );
                    } finally {
                      setLoading(false)
                    }
                  }}
                >
                  Set Rate
                </Button>
              </div>: settlement.status == ReferralSettlementStatus.ERROR ? <div className="flex gap-3">
                <Button
                  isLoading={loading}
                  onClick={async () => {
                    try {
                      const setRatesResp = await setReadyForRetry([settlement.referralId])
                      
                    } catch (err) {
                     
                    } finally {
                    }
                  }}
                >
                  Set Ready for Retry
                </Button>
              </div>: ''

            )
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="Monthly Referral Settlements"
          titleSuffix={<span className="text-gray-300 text-xl">{referralSettlements?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <DropdownInput
                classes={{ button: "py-0 h-[42px] rounded font-normal border-2 focus:border-2" }}

                options={[
                    { label: 'All', value: '' },
                    { label: ReferralSettlementStatus.INITIALISED, value: ReferralSettlementStatus.INITIALISED },
                    { label: ReferralSettlementStatus.PENDING_APPROVAL, value: ReferralSettlementStatus.PENDING_APPROVAL },
                    { label: ReferralSettlementStatus.APPROVED, value: ReferralSettlementStatus.APPROVED },
                    { label: ReferralSettlementStatus.PROCESSING, value: ReferralSettlementStatus.PROCESSING },
                    { label: ReferralSettlementStatus.READY_FOR_RETRY, value: ReferralSettlementStatus.READY_FOR_RETRY },
                    { label: ReferralSettlementStatus.COMPLETED, value: ReferralSettlementStatus.COMPLETED },
                    { label: ReferralSettlementStatus.ERROR, value: ReferralSettlementStatus.ERROR },
                ]}
                selectedValue={referralStatus}
                onSelect={setReferralStatus}
            />
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={referralId} onChange={(e) => {
                        setReferralId(e.target.value);
                    }} placeholder="Referral Id" />
              <Button isLoading={loading} onClick={loadReferralMetrics}>Load</Button>

              <Button isLoading={loading} onClick={showAuthenticateModal}>Authenticate</Button>

              <Button isLoading={loading} onClick={setRatesAndPrepareForApproval}>Set Rates</Button>
              <Button isLoading={loading}  onClick={approveReferralSettlements}>Approve Settlements</Button>
              <Button isLoading={loading}  onClick={() => {
                setType(ReferralSettlementModalType.RETRY)
                showReferralSettlementsModal()
              }}>Retry Payments</Button>
              <Button isLoading={loading}  onClick={() => {
                setType(ReferralSettlementModalType.INITIAL)
                showReferralSettlementsModal()
              }}>Process Payments</Button>
              <Button isLoading={loading} onClick={confirmPayments}>Confirm Payments</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedSettlementRows}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "identifier",
                accessor: "identifier",
              },
              {
                header: "startDate",
                accessor: "startDate",
              },
              {
                header: "status",
                accessor: "status",
              },
              {
                header: "totalPaidOut",
                accessor: "totalPaidOut",
              },
              {
                header: "totalWagered",
                accessor: "totalWagered",
              },
              {
                header: "pnlThisMonth",
                accessor: "pnlThisMonth",
              },
              {
                header: "pnlLastMonth",
                accessor: "pnlLastMonth",
              },
              {
                header: "aggPnl",
                accessor: "aggPnl",
              },
              {
                header: "referralRate",
                accessor: "referralRate",
              },
              {
                header: "owner",
                accessor: "owner",
              },
              {
                header: "referralComission",
                accessor: "referralComission",
              },
              {
                header: "settlementSignature",
                accessor: "settlementSignature",
              },
              {
                header: "settlementTxnStatus",
                accessor: "settlementTxnStatus",
              },
              {
                header: "settlementTxnSlot",
                accessor: "settlementTxnSlot",
              },
              {
                header: "action",
                accessor: "action",
              },
            ]}
            variant="regular"
            emptyMessage="No Referral Settlements"
          />
        </AdminBodyItem>
      )
    }
  };

  return (
    <>
      <div className="flex gap-4">
        {tabs.map((tab, index) => (
          <Tab
            size={TabSize.MD}
            active={currentTab === tab}
            onClick={() => setCurrentTab(tab)}
            extraClasses="capitalize"
          >
            {tab}
          </Tab>
        ))}
      </div>
      {tabContent()}
    </>
  );
};
