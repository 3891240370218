import React, { useContext, useMemo, useState } from "react";
import AdminBodyItem from "../Containers/AdminBodyItem";
import Button from "../../components/common/button/Button";
import FlatTable from "../Components/FlatTable/FlatTable";
import { Tab, TabSize } from "../../components/common/tab/Tab";
import Icon from "../../components/common/icon/Icon";
import IconButton from "../../components/common/button/IconButton";
import { useModal } from "react-modal-hook";
import { EditStatusModal } from "../modals/house/EditStatusModal";
import { NetworkContext } from "../../contexts";
import { HouseStatus, HouseTokenStatus } from "../../sdk/enums";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import { ITokenEditInput, TokenConfigurationModal, TokenEditModalType } from "../modals/house/TokenConfigurationModal";
import { truncatePubkey } from "../../utils/string/string";
import { WithdrawProfitModal } from "../modals/platform/WithdrawProfitsModal";
import TableGrid from "../../components/common/table-grid/TableGrid";
import { BalanceContext, BalanceType } from "../Contexts/BalanceContext";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { twMerge } from "tailwind-merge";
import Balance from "../Components/TableCells/Balance";
import Pubkey from "../Components/TableCells/Pubkey";
import TruncateCell from "../Components/TableCells/TruncateCell";
import { OracleAddOrUpdateModal } from "../modals/house/OracleAddOrUpdate";
import { PublicKey } from "@solana/web3.js";
import { APP_NETWORK_TYPE } from "../../types/chain";
import { BPS_DENOMINATOR } from "../../sdk/houseToken";
import { HouseContext } from "../Contexts/AdminHouseContext";

export const HousePage = () => {
  const { house, houseTokens } = useContext(HouseContext);
  const { oracleListBalances } = useContext(BalanceContext);
  const { platformTokenMetaByPubkey } = useContext(NetworkContext);
  const { copyToClipboard } = useCopyToClipboard("Copied to clipboard")
  const tabs = ["house state", "oracle list", "house tokens"];
  const [currentTab, setCurrentTab] = useState<(typeof tabs)[number]>("house state");
  const isActive = useMemo(() => {
    return house?.status == HouseStatus.Active;
  }, [house]);

  const [unwrappedFlatTable, setUnwrappedFlatTable] = useState<string | null>(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState<string | null>(null);

  const [showHouseStatusModal, hideHouseStatusModal] = useModal(
    ({ in: open }): JSX.Element => (
      <EditStatusModal visible={open} hideModal={hideHouseStatusModal} />
    ),
    [],
  );

  // ADD / REMOVE ORACLE MODAL
  const [removeOracle, setRemoveOracle] = useState(false)
  const [oraclePubkey, setOraclePubkey] = useState<PublicKey>()

  const [showUpdateOracleModal, hideUpdateOracleModal] = useModal(
    ({ in: open }): JSX.Element => (
      <OracleAddOrUpdateModal visible={open} hideModal={hideUpdateOracleModal} remove={removeOracle} oraclePubkeyInput={oraclePubkey} />
    ),
    [removeOracle, oraclePubkey],
  );

  const [selectedToken, setSelectedToken] = useState<ITokenEditInput>()
  const [selectedTokenEditType, setSelectedTokenEditType] = useState<TokenEditModalType>()
  const [showTokenConfigurationModal, hideTokenConfigurationModal] = useModal(
    ({ in: open }): JSX.Element => (
      <TokenConfigurationModal visible={open} hideModal={hideTokenConfigurationModal} token={selectedToken} type={selectedTokenEditType} />
    ),
    [selectedToken, selectedTokenEditType],
  );

  const houseAccountDate = useMemo(
    () => [
      {
        title: "id",
        value: house?.baseState?.id.toString(),
      },
      { title: "status", value: HouseStatus[house?.status || 0] },
      { title: "state version", value: house?.baseState?.stateVersion.toString() },
      { title: "tokens", value: house?.baseState.countTokens },
      { title: "games", value: house?.baseState.countGames },
      {
        title: "oracle",
        value: (
          <div className="flex gap-1.5 items-center">
            <span>{house?.baseState?.oracleList.toString()}</span>
            <IconButton
              size="xs"
              iconName="copy"
              className="bg-transparent"
              onClick={() => copyToClipboard(house?.baseState?.oracleList.toString() || '')}
            />
          </div>
        )
      }
    ],
    [house],
  );

  const { chain } = useContext(NetworkContext)

  const tabContent = () => {
    // TODO - ACTIONS ONCE HOUSE ACTIONS ARE THERE
    if (currentTab === "house state") {
      return (
        <>
          <AdminBodyItem title="House Status">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 h-full items-center p-3 bg-gray-700 rounded-md min-w-[200px]">
                <div
                  className={`w-[16px] h-[16px] rounded-full ${isActive ? "bg-response-success animate-pulse" : "bg-response-error"
                    }`}
                />
                <span>{isActive ? "Active" : "Inactive"}</span>
              </div>
              <Button variant="secondary" onClick={() => showHouseStatusModal()}>
                Change Status
              </Button>
            </div>
          </AdminBodyItem>
          <AdminBodyItem
            title="House Account"
            // rightHeaderContent={
            //   <Button onClick={() => { }}>Update Config</Button>
            // } - NO IXN TO UPDATE CURRENTLY
          >
            <FlatTable data={houseAccountDate} />
          </AdminBodyItem>
        </>
      );
    }
    if (currentTab === "oracle list") {
      const formattedBalancesRows = oracleListBalances?.map((balance, index) => (
        {
          id: balance.pubkey.toString(),
          classes: "",
          name: (<span className="capitalize whitespace-nowrap font-bold">{BalanceType.RANDOMNESS_ORACLE} {index} Balance</span>),
          pubkey: (
            <div className="flex gap-1.5 items-center">
                <>
                  <div className="font-bold">{truncatePubkey(balance?.pubkey.toString() || '', 5)}</div>
                  < IconButton
                    size="xs"
                    iconName="copy"
                    className="bg-transparent"
                    iconClass="text-gray-300"
                    onClick={() => copyToClipboard(balance?.pubkey.toString() || '')}
                  />
                </>
            </div>
          ),
          balance: (
            <div className="flex gap-2 items-center">
              <Icon iconUrl="/static/tokens/sol.png" size="xl" />
              <span className="">{
                formatZeebitNumber(
                  balance?.solBalance || 0,
                  NumberType.TOKEN_AMOUNT,
                  undefined,
                  8
                )
              }</span>
            </div>
          ),
          remove: (
            <Button
              variant="gray"
              size="sm"
              onClick={() => {
                console.log(oraclePubkey?.toString())
                setRemoveOracle(true)
                setOraclePubkey(balance.pubkey)
  
                showUpdateOracleModal()
              }}
            >
              Remove
            </Button>
          )
        }
      ));

      return (
        <AdminBodyItem title="Oracle List" rightHeaderContent={
          <div className="flex basis-0 gap-x-2">
            <Button onClick={() => {
              setRemoveOracle(false)
              setOraclePubkey(undefined)
              showUpdateOracleModal()
            }}>Add</Button>
            <Button onClick={() => {
              setRemoveOracle(true)
              setOraclePubkey(undefined)
              showUpdateOracleModal()
            }}>Remove</Button>
          </div>
        }>
          <TableGrid
            rows={formattedBalancesRows}
            columns={[
              {
                header: "Name",
                accessor: "name",
                classes: "text-base bg-none! border-b border-gray-500 first:rounded-l-none",
              },
              {
                header: "Pubkey",
                accessor: "pubkey",
                classes: "text-base bg-none! border-b border-gray-500 rounded-none",
              },
              {
                header: "Balance",
                accessor: "balance",
                classes: "text-base bg-none! border-b border-gray-500 rounded-none last:rounded-r-none justify-end",
                headerClasses: "justify-end",
              },
              {
                header: "Remove",
                accessor: "remove",
                classes: "text-base bg-none! border-b border-gray-500 rounded-none last:rounded-r-none"
              },
            ]}
            variant="regular"
            emptyMessage=" "
            onRowClick={(rowId) => {
             
            }}
          />
        </AdminBodyItem>
      )
    }
    if (currentTab === "house tokens") {
      const formattedBalancesRows = houseTokens?.map((token) => {
        const houseTokenMint = token.tokenMintPubkey.toString();
        const meta = platformTokenMetaByPubkey.get(houseTokenMint);
        const availableBalance = token.availableBalance && meta?.decimals ? token.availableBalance / Math.pow(10, meta?.decimals) : 0;
        const lockedBalance = token.lockedBalance && meta?.decimals ? token.lockedBalance / Math.pow(10, meta?.decimals) : 0;
        const playerBalance = token.playerBalance && meta?.decimals ? token.playerBalance / Math.pow(10, meta?.decimals) : 0;
        const outstandingLpTokens = token.outstandingLpBalance && meta?.decimals ? token.outstandingLpBalance / Math.pow(10, meta?.decimals) : 0;
        const incrementUnit = token.incrementUnit && meta?.decimals ? token.incrementUnit / Math.pow(10, meta?.decimals) : 0;
        const proportionTotalBankrollPercent = (token.proportionTotalBankrollBps / BPS_DENOMINATOR) * 100

        return (
          {
            id: meta?.pubkey.toString(),
            classes: "border-t",
            token: (
              token.publicKey ?
                <Pubkey iconUrl={meta?.imageDarkPng} iconSize="xl" buttonIconSize="xs" pubkey={token.publicKey.toString() || ''} />
                : null
            ),
            status: token?.status ? HouseTokenStatus[token?.status] : 'Unknown',
            delegation: token.delegationStatus ? token.delegationStatus.charAt(0).toUpperCase() + token.delegationStatus.slice(1) : '',
            incrementUnit: incrementUnit,
            outstandingLpTokens: (<Balance
            iconUrl={meta?.imageDarkPng}
            balance={outstandingLpTokens}
            decimals={8}
            fiat={undefined} />),
            proportionTotalBankrollBps: `${proportionTotalBankrollPercent}%`,
            activeBets: token.state.activeBets,
            vault: (token.vaultPublicKey ? <Pubkey iconSize="xl" buttonIconSize="xs" pubkey={token.vaultPublicKey.toString() || ''} /> : ''),
            tokenMint: (token.state.tokenMint ? <Pubkey iconSize="xl" buttonIconSize="xs" pubkey={token.state.tokenMint.toString() || ''} /> : ''),
            available: (
              <Balance
                iconUrl={meta?.imageDarkPng}
                balance={availableBalance}
                decimals={8}
                fiat={undefined} />
            ),
            locked: (
              <Balance
                iconUrl={meta?.imageDarkPng}
                balance={lockedBalance}
                decimals={8}
                fiat={undefined} />
            ),
            playerBalance: (
              <Balance
                iconUrl={meta?.imageDarkPng}
                balance={playerBalance}
                decimals={8}
                fiat={undefined} />
            ),
            edit: (
              <div className="flex gap-3">
                {chain == APP_NETWORK_TYPE.MB_AND_SOLANA ? <Button
                  variant='gray'
                  onClick={() => {
                    setSelectedToken({ token, context: meta });
                    setSelectedTokenEditType(TokenEditModalType.DELEGATION);
                    showTokenConfigurationModal();
                  }}
                >
                  Delegation
                </Button>: ''}
                <Button
                  onClick={() => {
                    setSelectedToken({ token, context: meta });
                    setSelectedTokenEditType(TokenEditModalType.CONFIG);
                    showTokenConfigurationModal();
                  }}
                >
                  Change Config
                </Button>
              </div>

            )
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="House Token List"
          titleSuffix={<span className="text-gray-300 text-xl">{houseTokens?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <Button  onClick={() => {
                    setSelectedToken(undefined);
                    setSelectedTokenEditType(TokenEditModalType.CREATE);
                    showTokenConfigurationModal();
                  }}>Add</Button>
              <Button disabled={true}>Remove</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedBalancesRows || []}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "Token",
                accessor: "token",
              },
              {
                header: "Status",
                accessor: "status",
              },
              {
                header: "Delegation",
                accessor: "delegation",
              },
              {
                header: <TruncateCell text="Increment Unit" truncateTo={6} />,
                accessor: "incrementUnit",
              },
              {
                header: <TruncateCell text="Outstanding Lp Tokens" />,
                accessor: "outstandingLpTokens",
              },
              {
                header: <TruncateCell text="Proportion Total Bankroll Bps" />,
                accessor: "proportionTotalBankrollBps",
              },
              {
                header: "Active Bets",
                accessor: "activeBets",
              },
              {
                header: "Vault",
                accessor: "vault",
              },

              {
                header: "Token Mint",
                accessor: "tokenMint",
              },
              {
                header: <TruncateCell text="Available Balance" truncateTo={6} />,
                accessor: "available",
              },
              {
                header: <TruncateCell text="Locked Balance" truncateTo={6} />,
                accessor: "locked",
              },

              {
                header: <TruncateCell text="Player Balance" truncateTo={6} />,
                accessor: "playerBalance",
              },
              {
                header: "Edit",
                accessor: "edit",
                classes: " right-0 bg-gray-800"
              },
            ]}
            variant="regular"
            emptyMessage=" "
            onRowClick={(rowId) => { }}
          />
        </AdminBodyItem>
      )
    }
  };

  return (
    <>
      <div className="flex gap-4">
        {tabs.map((tab, index) => (
          <Tab
            size={TabSize.MD}
            active={currentTab === tab}
            onClick={() => setCurrentTab(tab)}
            extraClasses="capitalize"
          // disabled={tab === "callers" || tab === "liquidity" || tab === "permissions"}
          >
            {tab}
          </Tab>
        ))}
      </div>
      {tabContent()}
    </>
  );
};
