import React, { FC } from 'react';
import { Tooltip } from '../../../components/common';

export interface TruncateCellProps {
    text: string;
    truncateTo?: number
}

const TruncateCell: FC<TruncateCellProps> = ({ text, truncateTo = 10 }) => {
    if (text.length > truncateTo) {
        return (<Tooltip content={text}><>{text.slice(0, truncateTo) + '...'}</></Tooltip>)
    }
    return (text);
};

export default TruncateCell;