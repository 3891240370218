import { Outlet, Navigate } from "react-router-dom";
import { LeftNav } from "./Navs/LeftNav";
import { SCROLLBAR_CLASSES } from "../../styles/commonClasses";
import { useContext } from "react";
import { TokenAccessContext } from "../Contexts/TokenAccessProvider";

const AuthRoutes = () => {
    const { isAdmin } = useContext(TokenAccessContext);
    return (isAdmin) ? <Outlet /> : <Navigate to="/" />;
};
export default AuthRoutes;