// FUND DISTRIBUTION

import { PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import { BN } from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

import { MAX_INDIVIDUALS_TO_UPDATE_PER_IXN, MAX_INDIVIDUALS_TO_UPDATE_PER_TXN } from "../../sdk/nftStaking/constants";
import NftStaking from "../../sdk/nftStaking/nftStaking";
import { getPreIxns } from "./utils";

export const fundDistributionIx = async (
    nftStaking: NftStaking,
    payer: PublicKey,
    platformProfitShareAmount: number,
    royaltiesShareAmount: number,
    unstakingShareAmount: number,
    discretionaryAmount: number,
): Promise<TransactionInstruction> => {
    const distributionPubkey = nftStaking.deriveDistributionPubkey(nftStaking.currentInstanceNonce);
    const tokenMintPubkey = nftStaking.mainState.tokenMint;
    const tokenVaultPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, nftStaking.mainPubkey, true);
    const tokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, payer, true);

    const tx = new Transaction()

    return await nftStaking.program.methods.fundDistribution({
        platformProfitShareAmount: new BN(platformProfitShareAmount),
        royaltiesShareAmount: new BN(royaltiesShareAmount),
        unstakingShareAmount: new BN(unstakingShareAmount),
        discretionaryAmount: new BN(discretionaryAmount),
    }).accounts({
        payer: payer,
        main: nftStaking.mainPubkey,
        distribution: distributionPubkey,
        tokenMint: tokenMintPubkey,
        tokenVault: tokenVaultPubkey,
        tokenAccount: tokenAccountPubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId
    }).instruction()
};

export const unFundDistributionIx = async (
    nftStaking: NftStaking,
    authority: PublicKey,
    platformProfitShareAmount: number,
    royaltiesShareAmount: number,
    unstakingShareAmount: number,
    discretionaryAmount: number,
): Promise<TransactionInstruction> => {
    const distributionPubkey = nftStaking.deriveDistributionPubkey(nftStaking.currentInstanceNonce);
    const tokenMintPubkey = nftStaking.mainState.tokenMint;
    const tokenVaultPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, nftStaking.mainPubkey, true);
    const tokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, authority, true);

    return await nftStaking.program.methods.unfundDistribution({
        platformProfitShareAmount: new BN(platformProfitShareAmount),
        royaltiesShareAmount: new BN(royaltiesShareAmount),
        unstakingShareAmount: new BN(unstakingShareAmount),
        discretionaryAmount: new BN(discretionaryAmount),
    }).accounts({
        authority: authority,
        main: nftStaking.mainPubkey,
        distribution: distributionPubkey,
        tokenMint: tokenMintPubkey,
        tokenVault: tokenVaultPubkey,
        tokenAccount: tokenAccountPubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId
    }).instruction()
};

export const crankAttributeSelectionIx = async (
    nftStaking: NftStaking,
    payer: PublicKey
): Promise<TransactionInstruction> => {

    const clientSeed = [3, 5, 7, 9];
    const distributionInstanceNonce = nftStaking.currentInstanceNonce;
    const distributionPubkey = nftStaking.deriveDistributionPubkey(nftStaking.currentInstanceNonce);
    const randomnessRequestPubkey = nftStaking.deriveRandomnessRequestPubkey(new BN(distributionInstanceNonce), distributionPubkey);
    const callbackTablePubkey = nftStaking.deriveCallbackTablePubkey(randomnessRequestPubkey);

    return await nftStaking.program.methods.crankAttributeSelection({
        clientSeed: clientSeed
    }).accounts({
        payer: payer,
        main: nftStaking.mainPubkey,
        distribution: distributionPubkey,
        randomnessRequest: randomnessRequestPubkey,
        callbackTable: callbackTablePubkey,
        randomnessDispatcherSigner: nftStaking.randomnessDispatcherSignerPubkey,
        randomnessProgram: nftStaking.randomnessProgramPubkey,
        nftStaking: nftStaking.program.programId,
        systemProgram: SystemProgram.programId
    }).instruction()
};

export const crankResultSelectionIx = async (
    nftStaking: NftStaking,
    payer: PublicKey
): Promise<TransactionInstruction> => {

    const clientSeed = [2, 4, 6, 8];
    const distributionInstanceNonce = nftStaking.currentInstanceNonce;
    const distributionPubkey = nftStaking.deriveDistributionPubkey(nftStaking.currentInstanceNonce);
    const nextDistributionInstanceNonce = nftStaking.currentInstanceNonce + 1;
    const nextDistributionPubkey = nftStaking.deriveDistributionPubkey(nextDistributionInstanceNonce);
    const selectedAttribute = nftStaking.currentDistribution.selectedAttribute;
    const attributePubkey = nftStaking.deriveAttributePubkey(selectedAttribute);
    const randomnessRequestPubkey = nftStaking.deriveRandomnessRequestPubkey(new BN(distributionInstanceNonce).add(new BN(100_000)), distributionPubkey);
    const callbackTablePubkey = nftStaking.deriveCallbackTablePubkey(randomnessRequestPubkey);

    return await nftStaking.program.methods.crankResultSelection({
        clientSeed: clientSeed,
        nextInstanceNonce: (nftStaking.currentInstanceNonce + 1)
    }).accounts({
        payer: payer,
        main: nftStaking.mainPubkey,
        distribution: distributionPubkey,
        nextDistribution: nextDistributionPubkey,
        attribute: attributePubkey,
        randomnessRequest: randomnessRequestPubkey,
        callbackTable: callbackTablePubkey,
        randomnessProgram: nftStaking.randomnessProgramPubkey,
        randomnessDispatcherSigner: nftStaking.randomnessDispatcherSignerPubkey,
        nftStaking: nftStaking.program.programId,
        systemProgram: SystemProgram.programId
    }).instruction()
};


const crankUpdateIndividualsIx = async (
    payer: PublicKey,
    nftStaking: NftStaking,
    ids: number[]
): Promise<TransactionInstruction> => {


    const distributionPubkey = nftStaking.deriveDistributionPubkey(nftStaking.currentInstanceNonce);
    const nextDistributionInstanceNonce = nftStaking.currentInstanceNonce + 1;
    const nextDistributionPubkey = nftStaking.deriveDistributionPubkey(nextDistributionInstanceNonce);

    return await nftStaking.program.methods.crankUpdateIndividuals({
        ids: ids
    }).accounts({
        payer: payer,
        main: nftStaking.mainPubkey,
        collectionTable: nftStaking.collectionTablePubkey,
        distribution: distributionPubkey,
        nextDistribution: nextDistributionPubkey,
        systemProgram: SystemProgram.programId
    }).instruction();
};

export const crankAllUpdateIndividualsTxns = async (nftStaking: NftStaking, payer: PublicKey): Promise<Transaction[] | undefined> => {
    const allIdsToUpdate = getIdsToUpdate(nftStaking);

    if (allIdsToUpdate.length == 0) {
        console.log('No Ids to update')
        console.log('main.instanceNonce', nftStaking.currentInstanceNonce)
        return
    }

    const txns: Transaction[] = []

    // NUMBER OF TXNS NEEDED
    const idsChunked = Math.ceil(allIdsToUpdate.length / MAX_INDIVIDUALS_TO_UPDATE_PER_TXN)

    for (let i = 0; i < idsChunked; i++) {
        // IDS FOR THIS TXN
        const idsChunk = allIdsToUpdate.slice(
            i * MAX_INDIVIDUALS_TO_UPDATE_PER_TXN,
            Math.min(allIdsToUpdate.length, (i + 1) * MAX_INDIVIDUALS_TO_UPDATE_PER_TXN)
        );

        const tx = new Transaction()
        // ADD PRE-IXNS
        const preIxns = getPreIxns()
        tx.add(...preIxns)
        
        // NUMBER OF IXNS FOR THIS TX
        const numberChunks = Math.min(Math.ceil(idsChunk.length / MAX_INDIVIDUALS_TO_UPDATE_PER_IXN), 10);

        for (let i = 0; i < numberChunks; i++) {
            // IDS FOR THIS IXN
            const idsChunked = idsChunk.slice(
                i * MAX_INDIVIDUALS_TO_UPDATE_PER_IXN,
                Math.min(idsChunk.length, (i + 1) * MAX_INDIVIDUALS_TO_UPDATE_PER_IXN)
            );

            const ix = await crankUpdateIndividualsIx(
                payer,
                nftStaking,
                idsChunked
            )
            tx.add(ix)
        }

        txns.push(tx)
    }

    return txns
}

const getIdsToUpdate = (nftStaking: NftStaking): number[] => {

    const ids: number[] = [];
    const selectedAttributeId = nftStaking.currentDistribution.selectedAttribute;

    const selectedTraitIds = Array.from(new Uint16Array(nftStaking.currentDistribution.selectedTraits));
    const currentInstanceNonce = nftStaking.currentDistribution.instanceNonce;

    nftStaking.collectionTable.rows.forEach((individualRecord, idx) => {
        // console.log(idx, individualRecord.nftMint.toString().slice(0, 5), individualRecord.traits, individualRecord.updateInstanceNonce)
        const trait: number = individualRecord.traits[selectedAttributeId]

        if (selectedTraitIds.includes(trait) && individualRecord.updateInstanceNonce < currentInstanceNonce) {
            ids.push(idx);
        }
    })

    return ids
}