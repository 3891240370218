import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { SignerContext } from './SignersContext';
import { useWallet } from '@solana/wallet-adapter-react';
import House from '../../sdk/house';
import { PublicKey } from '@solana/web3.js';
import { HouseContext } from './AdminHouseContext';

export interface ITokenAccessContext {
    isAdmin: boolean;
    isLoading: boolean;
}

export const TokenAccessContext = createContext<ITokenAccessContext>({} as ITokenAccessContext);

export const TokenAccessProvider: FC<PropsWithChildren> = ({ children }) => {
    const { signers } = useContext(SignerContext);
    const { house } = useContext(HouseContext);
    const { publicKey: walletPubKey } = useWallet();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const checkAuthority = async () => {
            setIsLoading(true);

            try {
                if (house && walletPubKey) {
                    const permissionBasePubkey = House.deriverPermissionPubkey(
                        house?.publicKey,
                        walletPubKey,
                        house.baseProgram?.programId
                    );
                    const permissionBasePubkeyExist = await house.baseProgram.provider.connection.getAccountInfo(permissionBasePubkey);

                    setIsAdmin(!!permissionBasePubkeyExist);
                } else if (house && signers.length) {
                    const results = await Promise.all(signers.map(async (signer) => {
                        try {
                            const permissionBasePubkey = House.deriverPermissionPubkey(
                                house.publicKey,
                                new PublicKey(signer.publicKey),
                                house.baseProgram.programId
                            );
                            return !!(await house.baseProgram.provider.connection.getAccountInfo(permissionBasePubkey));
                        } catch (error) {
                            console.error("Error checking signer permission:", signer, error);
                            return false;
                        }
                    }));
                    const isTokenAdmin = results.some(Boolean);
                    setIsAdmin(isTokenAdmin);
                } else if (isAdmin) {
                    setIsAdmin(false);
                }
            } catch (error) {
                console.error("Error in checkAuthority:", error);
                setIsAdmin(false);
            } finally {
                setIsLoading(false);
            }
        };
        checkAuthority();
    }, [walletPubKey, signers, house?.baseProgram]);

    return (
        <TokenAccessContext.Provider
            value={useMemo(
                () => ({
                    isAdmin,
                    isLoading
                }),
                [isAdmin, isLoading],
            )}
        >
            {children}
        </TokenAccessContext.Provider>
    );
};
