import { useCallback, useContext, useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { PublicKey } from "@solana/web3.js";
import Game from "../../sdk/gameSpec";
import { getPlatformGames } from "../../utils/config/utils";
import { IPlatformGame } from "../../types/game";
import House from "../../sdk/house";
import { ProgramContext } from "../../contexts/ProgramContext";
import { GameConfigurationModal, GameEditModalType, IGameEditInput } from "../modals/game/GameConfigurationModal";
import Button from "../../components/common/button/Button";
import { NetworkContext } from "../../contexts";
import AdminBodyItem from "../Containers/AdminBodyItem";
import { twMerge } from "tailwind-merge";
import { IconFont } from "../../components/common";
import TableGrid from "../../components/common/table-grid/TableGrid";
import { ImageStatic } from "../../components/common/image/ImageStatic";
import { HouseContext } from "../Contexts/AdminHouseContext";

export const GamesPage = () => {
  const { house } = useContext(HouseContext)
  const { meta } = useContext(ProgramContext)
  const { chain, platformTokenMetaByPubkey } = useContext(NetworkContext);
  const [games, setGames] = useState<{ game: Game | undefined, context: IPlatformGame | undefined }[]>()
  const [loading, setLoading] = useState(false)

  const loadGameSpecs = useCallback(async () => {
    const gameConfigs = getPlatformGames(chain)

    if (house == null) {
      return
    }

    setLoading(true)

    const gameByPubkey = gameConfigs.reduce((result, item) => {
      if (item.gameSpecPubkey != null && item.gameSpecPubkey.length > 0) {
        result.set(item.gameSpecPubkey, item)
      }

      return result
    }, new Map<string, IPlatformGame>)

    const gameSpecs = await Promise.all(
      gameConfigs.filter((game) => {
        return game.gameSpecPubkey != null && game.gameSpecPubkey.length > 0
      }).map(
        (gameConfig) => Game.load(house, new PublicKey(gameConfig.gameSpecPubkey), gameConfig.type || 0)))

    setGames(gameSpecs.map((gs) => {
      return {
        game: gs,
        context: gameByPubkey.get(gs.publicKey.toString())
      }
    }))
    setLoading(false)
  }, [house, chain])

  useEffect(() => {
    async function loadGameSpecs(gameConfigs: IPlatformGame[], house: House) {
      setLoading(true)

      const gameByPubkey = gameConfigs.reduce((result, item) => {
        if (item.gameSpecPubkey != null && item.gameSpecPubkey.length > 0) {
          result.set(item.gameSpecPubkey, item)
        }

        return result
      }, new Map<string, IPlatformGame>)

      const gameSpecs = await Promise.all(
        gameConfigs.filter((game) => {
          return game.gameSpecPubkey != null && game.gameSpecPubkey.length > 0
        }).map(
          (gameConfig) => Game.load(house, new PublicKey(gameConfig.gameSpecPubkey), gameConfig.type || 0)))

      setGames(gameSpecs.map((gs) => {
        return {
          game: gs,
          context: gameByPubkey.get(gs.publicKey.toString())
        }
      }))
      setLoading(false)
    }

    if (meta?.zeebitV2ErProgram
      == null || house == null) {
      return
    }

    const gameConfigs = getPlatformGames(chain)

    loadGameSpecs(gameConfigs, house)
  }, [meta, house, chain])

  const [selectedGame, setSelectedGame] = useState<IGameEditInput>()
  const [selectedGameEditType, setSelectedGameEditType] = useState<GameEditModalType>()
  const [showGameConfigurationModal, hideGameConfigurationModal] = useModal(
    ({ in: open }): JSX.Element => (
      <GameConfigurationModal visible={open} hideModal={hideGameConfigurationModal} game={selectedGame} type={selectedGameEditType} loadGames={loadGameSpecs} />
    ),
    [selectedGame, selectedGameEditType, loadGameSpecs],
  );

  const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

  const formattedGamesRows = games?.map((gameMeta) => {
    const game = gameMeta.game
    const context = gameMeta.context
    const gameDisplayName = context?.name['EN'];
    const iconName = context?.icon;
    const gameStatus = Object.keys(game?.state.status)[0];
    const minBet = (game?.state.minWagerInHouseTokenUnits?.toNumber() || 0);
    const multitoken = Array.from(game?.supportedTokens).length > 1;// NEEDS REAL DATA
    const tokensImages = Array.from(game?.supportedTokens)?.map((token) => {
      const tokenMeta = platformTokenMetaByPubkey.get(token[0]);
      const tokenImg = tokenMeta?.imageDarkPng || '';
      return (<ImageStatic url={tokenImg} classes={{ wrapper: "relative [&:not(:first-child)]:-ms-1" }} />)
    })

    return (
      {
        id: game?.publicKey.toString(),
        classes: "border-t",
        game: (
          <div className="flex gap-2 items-center">
            <IconFont name={iconName} />
            <div>{gameDisplayName}</div>
          </div>
        ),
        status: gameStatus ? gameStatus : 'Unknown',
        min: (<div className="flex flex-col items-start gap-0.5"><div>{minBet}</div></div>),
        multitoken: multitoken ? 'True' : 'False',
        tokens: (<div className="flex">{tokensImages}</div>),
        actions: (<div className="flex gap-1">
          <Button variant="gray" icon={<IconFont name="filter_sliders" />} onClick={() => {
            setSelectedGameEditType(GameEditModalType.CONFIG)
            setSelectedGame(gameMeta)
            showGameConfigurationModal()
          }}>
            Config
          </Button>
          <Button variant="gray" icon={<IconFont name="filter_sliders" />} onClick={() => {
            setSelectedGameEditType(GameEditModalType.STATUS)
            setSelectedGame(gameMeta)
            showGameConfigurationModal()
          }}>
            Status
          </Button>
          <Button icon={<IconFont name="filter_sliders" />} variant="gray" onClick={() => {
            setSelectedGameEditType(GameEditModalType.TOKEN_SUPPORT)
            setSelectedGame(gameMeta)
            showGameConfigurationModal()
          }}>
            Tokens
          </Button>
          <Button variant="gray" disabled={true} onClick={() => {
            setSelectedGameEditType(GameEditModalType.DELEGATION)
            setSelectedGame(gameMeta)
            showGameConfigurationModal()
          }}>
            Delegate
          </Button>
        </div>)
      }
    )
  });

  return <div>
    <AdminBodyItem title="Game list">
      <TableGrid
        isLoading={loading || games?.length == 0}
        rows={formattedGamesRows}
        classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold text-sm"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
        columns={[
          {
            header: "Game",
            accessor: "game",
          },
          {
            header: "Min bet",
            accessor: "min",
          },
          {
            header: "Multitoken",
            accessor: "multitoken",
          },
          {
            header: 'Tokens',
            accessor: "tokens",
          },
          {
            header: "Info/Edit",
            accessor: 'actions',
          }

        ]}
        variant="regular"
        emptyMessage=" "
        onRowClick={(rowId) => { }}
      />

    </AdminBodyItem>
  </div>;
};
