import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import bs58 from "bs58";
import { decryptSecretKey, encryptSecretKey } from "../sdk/utils";
import { NetworkContext } from "../../contexts/NetworkContext";
import TransactionHandler from "../sdk/transactionHandler";
import { RPC_WRITE_ENPOINTS } from "../sdk/constants";
import PasswordInput from "../Components/PasswordInput/PasswordInput";
import FormItem from "../../components/common/form-item/FormItem";
import DropdownInput from "../../components/common/dropdown-input/DropdownInput";
import { truncatePubkey } from "../../utils/string/string";
import { useWallet } from "@solana/wallet-adapter-react";

export interface ISigner {
  publicKey: string;
  encryptedPrivate: string;
  solBalance: number;
}

export interface ISignersContext {
  signers: ISigner[];
  addSigner: (privateKey: string, pin: string, onError: Function, onSuccess: Function) => Promise<void>;
  removeSigner: (signer: ISigner) => void;
  clearSigners: () => void;
  getKeypair: (publicKey: string, pin: string) => Keypair;
  loadKeypair: (secretKey: string) => Keypair
  isOnSignersList: (signer: string) => boolean
  transactionHandler: TransactionHandler | undefined;
  connectedViaKeyPair: boolean;
  connectedViaWallet: boolean;
  isLoading: boolean;
  signerInput?: ReactNode;
  signerInputError: boolean;
  keypair: Keypair | null;
  authorityPubkey: PublicKey | null;
  pinInput?: string;
  selectedSigner?: ISigner;
  setPinInput: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const SignerContext = createContext<ISignersContext>({} as ISignersContext);

interface Props {
  children: any;
}

export const SignerProvider = ({ children }: Props) => {
  const [signers, setSigners] = useLocalStorage("zeebit-admin-signers", "");
  const { client } = useContext(NetworkContext)
  const [transactionHandler, setTransactionHandler] = useState<TransactionHandler>();
  const { publicKey: walletPubKey, connected } = useWallet();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedSigner, setSelectedSigner] = useState<ISigner>();
  const [pinInput, setPinInput] = useState<string>();
  const [pinInputError, setPinInputError] = useState<string>();
  const [signerInputError, setSignerInputError] = useState(false);
  const [keypair, setKeypair] = useState<Keypair | null>(null);
  const [authorityPubkey, setAuthorityPubkey] = useState<PublicKey | null>(null);

  useEffect(() => {
    if (selectedSigner == null && signers != null && signers.length > 0) {
      setSelectedSigner(signers[0])
    }
  }, [signers])

  const connectedViaKeyPair = useMemo(() => !!signers?.length, [signers]);
  const connectedViaWallet = useMemo(() => connected, [connected]);

  useEffect(() => {
    if (connectedViaKeyPair && selectedSigner?.publicKey != null && pinInput != null) {
      let kp;
      try {
        kp = getKeypair(selectedSigner.publicKey, pinInput);
        if (pinInputError) {
          setPinInputError("")
        }
      } catch (error) {
        console.log({ error });

        setPinInputError(error?.message ? error?.message : typeof error == "string" ? error : 'Unknown password format error')
      }
      if (kp) {
        setSignerInputError(false);
        setKeypair(kp);
        setAuthorityPubkey(kp.publicKey);
        return;
      }
      return;
    }
    if (connectedViaWallet) {
      setSignerInputError(false);
      setKeypair(null);
      setAuthorityPubkey(walletPubKey);
      return;
    }
    if (authorityPubkey == null ||
      (!connectedViaKeyPair && !connectedViaWallet) ||
      (connectedViaKeyPair && (selectedSigner == null || pinInput == null)) ||
      (connectedViaWallet && walletPubKey == null)
    ) {
      setKeypair(null);
      setAuthorityPubkey(null);
      setSignerInputError(true);
      return;
    }
  }, [connectedViaWallet, connectedViaKeyPair, selectedSigner, walletPubKey, pinInput]);

  const signerInput = connectedViaKeyPair ?
    <div className="flex gap-3 w-full">
      {/* Wallet */}
      <FormItem className="flex-1 self-stretch">

        <DropdownInput
          classes={{ button: "py-0 h-[42px] rounded font-normal border-2 focus:border-2 border-gray-600" }}
          options={signers?.map((signer) => {
            return { label: truncatePubkey(signer.publicKey, 5), value: signer as object }
          })}
          selectedValue={selectedSigner}
          onSelect={setSelectedSigner}
        />
      </FormItem>

      {/* PIN */}
      <FormItem error={pinInputError} className="flex-1 self-stretch">
        <PasswordInput
          error={pinInputError}
          placeholder={"Enter PIN"}
          value={pinInput || ""}
          onChange={setPinInput}
        />
      </FormItem>

    </div> : null;


  useEffect(() => {
    setTransactionHandler(TransactionHandler.load(
      RPC_WRITE_ENPOINTS,
      {
        commitment: "processed",
        skipPreflight: true
      },
      5
    ))
  }, [])

  useEffect(() => {
    async function loadBalances(signers: ISigner[], connection: Connection) {
      const balances = await Promise.all((signers.map((signer) => connection.getBalance(new PublicKey(signer.publicKey)))))
      const updatedSigners = [...signers].map((signer, index) => {
        return {
          ...signer,
          solBalance: (balances[index] || 0) / Math.pow(10, 9)
        }
      })

      setSigners(updatedSigners)
    }

    if (client == null) {
      return
    }

    if (signers == null || signers == "") {
      setSigners([]);
    } else {
      loadBalances(signers, client)
    }
  }, [client]);

  const loadKeypair = (secretKey: string): Keypair => {
    // TODO HANDLE SEED PHRASE

    return Keypair.fromSecretKey(bs58.decode(secretKey))
  }

  const isOnSignersList = useCallback((signer: string) => {
    // CHECK THIS IN FUTURE
    return true
  }, [])

  const addSigner = useCallback(async (privateKey: string, pin: string, onError: Function, onSuccess: Function) => {
    // CHECK VALID PRIVATE KEY
    try {
      const keypair = loadKeypair(privateKey)

      // CHECK PUBKEY ON LIST OF AUTHORITIES
      const pubkey = keypair.publicKey.toString()
      const balance = await client?.getBalance(keypair.publicKey)

      const signer: ISigner = {
        publicKey: pubkey,
        encryptedPrivate: encryptSecretKey(privateKey, pin),
        solBalance: (balance || 0) / Math.pow(10, 9),
      }

      const signersUpdated = [...signers];
      signersUpdated.push(signer);

      setSigners(signersUpdated);

      onSuccess(`Successfully added wallet: ${signer.publicKey}`)
    } catch (err) {
      console.error('ISSUE ADDING SIGNER', err)
      onError(err)
    }
  },
    [signers, client],
  );

  const removeSigner = useCallback(
    (signer: ISigner) => {
      if (signers.length == 1) {
        setSigners([])
        return
      }

      const signersUpdated = [...signers];
      const index = signersUpdated.findIndex((sig) => {
        return sig.publicKey == signer.publicKey;
      });
      const removed = signersUpdated.splice(index, 1);

      setSigners(removed);
    },
    [signers],
  );

  const getKeypair = useCallback((publicKey: string, pin: string) => {
    const signersArr: ISigner[] = Array.from(signers)

    // ENSURE SIGNER IN LOCAL STORAGE
    const signer = signersArr.find((signer) => {
      return signer.publicKey == publicKey
    })

    if (signer == null) {
      throw new Error("Signer not found")
    }

    // DECRYPT THE SECRET KEY
    const decryptedSecretKey = decryptSecretKey(signer.encryptedPrivate, pin)

    // LOAD KEYPAIR
    const keypair = loadKeypair(decryptedSecretKey)

    // ENSURE PUBKEY IS SAME AS LOCAL
    if (keypair.publicKey.toString() != signer.publicKey) {
      throw new Error(`Pubkey different after loading; Before: ${signer.publicKey} After: ${keypair.publicKey.toString()}`)
    }

    return keypair
  }, [signers])

  const clearSigners = useCallback(() => {
    setSigners([]);
  }, [signers]);

  return (
    <SignerContext.Provider
      value={useMemo(
        () => ({
          signers: signers,
          addSigner: addSigner,
          removeSigner: removeSigner,
          clearSigners: clearSigners,
          getKeypair: getKeypair,
          loadKeypair: loadKeypair,
          isOnSignersList: isOnSignersList,
          transactionHandler: transactionHandler,
          connectedViaKeyPair,
          connectedViaWallet,
          isLoading,
          signerInput,
          signerInputError,
          keypair,
          authorityPubkey,
          selectedSigner,
          pinInput,
          setPinInput,
        }),
        [
          signers,
          addSigner,
          removeSigner,
          clearSigners,
          getKeypair,
          loadKeypair,
          isOnSignersList,
          transactionHandler,
          connectedViaKeyPair,
          connectedViaWallet,
          isLoading, signerInput,
          keypair,
          authorityPubkey,
          selectedSigner,
          pinInput,
          setPinInput
        ],
      )}
    >
      {children}
    </SignerContext.Provider>
  );
};
