import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import LiquidityProvider from "../sdk/liquidityProvider";
import { ISigner, SignerContext } from "./SignersContext";
import House from "../../sdk/house";
import HouseToken from "../../sdk/houseToken";
import { useWallet } from "@solana/wallet-adapter-react";
import { HouseContext } from "./AdminHouseContext";

export interface ILpContext {
  liquidityProviders: LiquidityProvider[] | undefined;
  lpsByOwnerPubkey: Map<string, LiquidityProvider[]> | undefined;
  loadLps: () => Promise<void>;
  isAdmin: boolean;
}

export const LpContext = createContext<ILpContext>({} as ILpContext);

interface Props {
  children: any;
}

export const LpProvider = ({ children }: Props) => {
  const [lps, setLps] = useState<LiquidityProvider[]>();
  const { publicKey: walletPubkey } = useWallet();
  const lpsByOwnerPubkey = useMemo(() => {
    if (lps == null || lps.length == 0) {
      return
    }

    return lps.reduce((result, item) => {
      if (item.baseState != null) {
        const ownerPubkey = item.ownerPubkey?.toString()

        if (result.has(ownerPubkey) == false) {
          result.set(ownerPubkey, [item])
        } else {
          result.get(ownerPubkey)?.push(item)
        }
      }

      return result
    }, new Map<string, LiquidityProvider[]>)
  }, [lps])
  const { signers } = useContext(SignerContext);
  const { house, houseTokens } = useContext(HouseContext)

  const loadLps = useCallback(async () => {
    if ((signers == null && walletPubkey == null) || house == null || houseTokens == null) {
      return
    }

    const lpPromises: Promise<LiquidityProvider>[] = []

    signers.forEach((signer) => {
      const signerPubkey = new PublicKey(signer.publicKey)
      houseTokens.forEach((houseToken) => {
        lpPromises.push(LiquidityProvider.load(houseToken, signerPubkey))
      })
    })
    if (walletPubkey) {
      houseTokens.forEach((houseToken) => {
        lpPromises.push(LiquidityProvider.load(houseToken, walletPubkey))
      })
    }

    const lpsLoaded = await Promise.all(lpPromises)
    const lpsWithState = lpsLoaded.filter((lp) => {
      return lp.baseState != null
    })

    setLps(lpsWithState)
  }, [signers, house, houseTokens, walletPubkey])

  useEffect(() => {
    async function loadLps(signers: ISigner[], walletPubkey: PublicKey | null, house: House, houseTokens: HouseToken[]) {
      const lpPromises: Promise<LiquidityProvider>[] = []

      signers.forEach((signer) => {
        const signerPubkey = new PublicKey(signer.publicKey)
        houseTokens.forEach((houseToken) => {
          lpPromises.push(LiquidityProvider.load(houseToken, signerPubkey))
        })
      })
      if (walletPubkey) {
        houseTokens.forEach((houseToken) => {
          lpPromises.push(LiquidityProvider.load(houseToken, walletPubkey))
        })
      }

      const lpsLoaded = await Promise.all(lpPromises)
      const lpsWithState = lpsLoaded.filter((lp) => {
        return lp.baseState != null || lp.erState != null
      })

      setLps(lpsWithState)
    }

    if ((signers == null && walletPubkey == null) || house == null || houseTokens == null) {
      return
    }

    loadLps(signers, walletPubkey, house, houseTokens)
  }, [signers, house, houseTokens, walletPubkey]);

  return (
    <LpContext.Provider
      value={useMemo(
        () => ({
          liquidityProviders: lps,
          lpsByOwnerPubkey: lpsByOwnerPubkey,
          loadLps: loadLps,
          isAdmin: !!lps?.length,
        }),
        [lps, lpsByOwnerPubkey, loadLps],
      )}
    >
      {children}
    </LpContext.Provider>
  );
};
