import React, { FC } from 'react';
import Icon from '../../../components/common/icon/Icon';
import { formatZeebitNumber, NumberType } from '../../../utils/currency/formatting';

export interface BalanceProps {
    iconUrl?: string;
    balance: number;
    decimals: number;
    fiat?: number;
}

const Balance: FC<BalanceProps> = ({ iconUrl, balance, decimals, fiat }) => {
    return (
        <div className="flex flex-col gap-1 items-start">
            <div className="flex gap-1 items-baseline">
                {iconUrl ? <Icon iconUrl={iconUrl} size="xs" /> : null}
                <span className="">{
                    formatZeebitNumber(
                        balance,
                        NumberType.TOKEN_AMOUNT,
                        undefined,
                        decimals
                    )
                }</span>
            </div>
            {fiat ? <div className="text-xs text-gray-400">~{`~$${fiat}`}</div> : null}
        </div>
    );
};

export default Balance;