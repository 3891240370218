import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Connection, PublicKey } from "@solana/web3.js";

import {
    NetworkContext
} from "../../contexts";
import { NftStakingContext } from "./NftStakingContext";
import { NFT_CRANKER } from "../sdk/constants";
import { HouseContext } from "./AdminHouseContext";

export const BalanceContext = createContext<IBalanceContext>({} as IBalanceContext);

interface Props {
    children: any;
}

export enum BalanceType {
    HOUSE_PAYER = 'house payer',
    RANDOMNESS_PROVIDER = 'randomness provider',
    RANDOMNESS_ORACLE = 'randomness oracle',
    NFT_CRANKER = 'nft cranker',
    ORACLE = 'oracle'
}

interface IBalanceToWatch {
    type: BalanceType,
    pubkey: PublicKey
    lamportBalance?: number
    solBalance?: number
}

interface IBalanceContext {
    balanceByPubkey: Map<string, IBalanceToWatch> | undefined
    balanceByType: Map<BalanceType, IBalanceToWatch> | undefined
    oracleListBalances: IBalanceToWatch[]
    isLoading: boolean
    signerPubkeys: string[] | undefined
}

// CONTEXT PROVIDING THE BALANCE OF IMPORTANT SIGNING WALLETS
export const BalanceProvider = ({ children }: Props) => {
    // STATE
    const [balancesToWatch, setBalancesToWatch] = useState<IBalanceToWatch[]>()
    const [balanceByPubkey, setBalanceByPubkey] = useState<Map<string, IBalanceToWatch>>()
    const [balanceByType, setBalanceByType] = useState<Map<BalanceType, IBalanceToWatch>>()
    
    const [oraclePubkeys, setOraclePubkeys] = useState<PublicKey[]>()
    const [oracleListBalances, setOracleListBalances] = useState<IBalanceToWatch[]>()

    const [loading, setLoading] = useState(true)
    const signerPubkeys = useMemo(() => {
        if (balancesToWatch == null) {
            return
        }

        return balancesToWatch.map((balance) => {
            return balance.pubkey?.toString?.()
        })
    }, [balancesToWatch])
    
    // CONTEXT
    const { house } = useContext(HouseContext)
    const { client, solanaClient } = useContext(NetworkContext)

    const { nftStaking } = useContext(NftStakingContext)

    useEffect(() => {
        if (!house?.programId || house.baseState == null || house.oracelList == null || nftStaking?.floorPriceOracle == null) {
            return
        }

        const housePayer = house.housePayerPubkey()

        setOraclePubkeys(house.oracelList?.approvedOracles || [])

        setBalancesToWatch([
            {
                type: BalanceType.HOUSE_PAYER,
                pubkey: housePayer
            },
            {
                type: BalanceType.NFT_CRANKER,
                pubkey: NFT_CRANKER
            },
            {
                type: BalanceType.ORACLE,
                pubkey: nftStaking.floorPriceOracle
            }
        ])
    }, [house, nftStaking])

    useEffect(() => {
        async function loadBalances(balances: IBalanceToWatch[], connection: Connection, oraclePubkeys: PublicKey[], solanaConnection: Connection) {
            setLoading(true)

            // LOAD BALANCES FROM CHAIN
            const balancesLoaded = await Promise.all(balances.map((balance) => {
                // ALWAYS LOAD NFT AND ORACLE FROM SOLANA CONNECTION
                if ([BalanceType.NFT_CRANKER, BalanceType.ORACLE].includes(balance.type)) {
                    return solanaConnection.getBalance(balance.pubkey, 'processed')
                }

                return connection.getBalance(balance.pubkey, 'processed')
            }))

            // LOAD ORACLE BALANCES
            const oracleBalancesLoaded = await Promise.all(oraclePubkeys.map((pubkey) => {
                return connection.getBalance(pubkey, 'processed')
            }))

            // UPDATE ORACLE VALUES
            const updatedOracleBalances = [...oraclePubkeys].map((pubkey, index) => {
                const balValue = oracleBalancesLoaded[index]
                const balance: IBalanceToWatch = {
                    lamportBalance: balValue,
                    solBalance: balValue / Math.pow(10, 9),
                    pubkey: pubkey,
                    type: BalanceType.RANDOMNESS_ORACLE
                }

                balance.lamportBalance = balValue
                balance.solBalance = balValue / Math.pow(10, 9)

                return balance
            })

            // UPDATE VALUES
            const updatedBalances = [...balances].map((balance, index) => {
                const balValue = balancesLoaded[index]

                balance.lamportBalance = balValue
                balance.solBalance = balValue / Math.pow(10, 9)

                return balance
            })

            const balByPubkey = updatedBalances.reduce((result, item) => {
                result.set(item.pubkey.toString(), item)
                
                return result
            }, new Map<string, IBalanceToWatch>)

            const balByType = updatedBalances.reduce((result, item) => {
                result.set(item.type, item)
                
                return result
            }, new Map<BalanceType, IBalanceToWatch>)

            console.log({
                balancesLoaded,
                updatedBalances,
                balByPubkey,
                balByType,
                connection,
                updatedOracleBalances
            })


            setOracleListBalances(updatedOracleBalances)
            setBalanceByPubkey(balByPubkey)
            setBalanceByType(balByType)
            setLoading(false)
        }

        if (client == null || solanaClient == null) {
            return
        }

        if (balancesToWatch == null || balancesToWatch.length == 0 || oraclePubkeys == null || oraclePubkeys.length == 0) {
            setBalanceByPubkey(undefined)
            setBalanceByType(undefined)
            setOracleListBalances(undefined)

            return
        }

        loadBalances(balancesToWatch, client, oraclePubkeys, solanaClient)
    }, [balancesToWatch, client, oraclePubkeys, solanaClient])
    
    return (
        <BalanceContext.Provider
            value={useMemo(
                () => ({
                    balanceByPubkey: balanceByPubkey,
                    balanceByType: balanceByType,
                    isLoading: loading,
                    signerPubkeys: signerPubkeys,
                    oracleListBalances: oracleListBalances
                }),
                [balanceByPubkey, balanceByType, loading, signerPubkeys, oracleListBalances],
            )}
        >
            {children}
        </BalanceContext.Provider>
    );
};
