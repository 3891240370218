import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { toast } from "react-toastify";
import { HouseProvider } from "./Contexts/AdminHouseContext";
import { NetworkProvider } from "../contexts/NetworkContext";
import { ProgramProvider } from "../contexts/ProgramContext";
import { RandomnessDispatcherProvider } from "../contexts/RandomnessDispatcherContext";
import { ToasterContext } from "../contexts/ToasterContext";
import Error404Page from "../pages/Error404Page";
import { ModalProvider as ReactModalProvider } from "react-modal-hook";
import { TransitionGroup } from "react-transition-group";
import { MainContainer } from "./Containers/MainContainer";
import { OverviewPage } from "./Pages/OverviewPage";
import { SignerProvider } from "./Contexts/SignersContext";
import { BalanceProvider } from "./Contexts/BalanceContext";
import { HousePage } from "./Pages/HousePage";
import { GamesPage } from "./Pages/GamesPage";
import { LiquidityProvidersPage } from "./Pages/LiquidityProvidersPage";
import { LpProvider } from "./Contexts/LiquidityProviderContext";
import { NftStakingPage } from "./Pages/NftStakingPage";
import { NftStakingProvider } from "./Contexts/NftStakingContext";
import AuthRoutes from "./Containers/AuthRoutes";
import { SolanaWalletProvider } from "../contexts";
import { TokenAccessProvider } from "./Contexts/TokenAccessProvider";
import LpRoutes from "./Containers/LPRoutes";

export const App = () => {
  return (
    <ToasterContext.Provider value={toast}>
      <NetworkProvider>
        <ProgramProvider>
          <RandomnessDispatcherProvider>
            <HouseProvider>
              <SolanaWalletProvider>
                <SignerProvider>
                  <TokenAccessProvider>
                    <NftStakingProvider>
                      <BalanceProvider>
                        <LpProvider>
                          <ReactModalProvider rootComponent={TransitionGroup}>
                            <BrowserRouter>
                              <Routes>
                                <Route path="" element={<MainContainer />}>
                                  <Route index element={<OverviewPage />} />

                                  <Route element={<AuthRoutes />}>
                                    <Route path="house" element={<HousePage />} />
                                    <Route path="games" element={<GamesPage />} />
                                  </Route>

                                  <Route element={<LpRoutes />}>
                                    <Route path="liquidity" element={<LiquidityProvidersPage />} />
                                  </Route>
                                  <Route path="nft-staking" element={<NftStakingPage />} />
                                  <Route path="*" element={<Error404Page backHome={``} />} />
                                </Route>
                              </Routes>
                            </BrowserRouter>
                          </ReactModalProvider>
                        </LpProvider>
                      </BalanceProvider>
                    </NftStakingProvider>
                  </TokenAccessProvider>
                </SignerProvider>
              </SolanaWalletProvider>
            </HouseProvider>
          </RandomnessDispatcherProvider>
        </ProgramProvider>
      </NetworkProvider>
    </ToasterContext.Provider>
  );
};
