import { useContext, useEffect, useMemo, useState } from "react";
import Dropdown from "../../components/common/dropdown/Dropdown";
import AdminBodyItem from "../Containers/AdminBodyItem";
import Icon from "../../components/common/icon/Icon";
import IconFont from "../../components/common/iconFont/IconFont";
import Button from "../../components/common/button/Button";
import { useModal } from "react-modal-hook";
import { TransferModalType, TransferModal, ILpMeta } from "../modals/liquidity/TransferModal";
import { LpContext } from "../Contexts/LiquidityProviderContext";
import { truncatePubkey } from "../../utils/string/string";
import { NetworkContext } from "../../contexts";
import TableGrid, { TableRow } from "../../components/common/table-grid/TableGrid";
import { twMerge } from "tailwind-merge";
import Balance from "../Components/TableCells/Balance";
import Pubkey from "../Components/TableCells/Pubkey";
import DropdownInput from "../../components/common/dropdown-input/DropdownInput";
import { HouseContext } from "../Contexts/AdminHouseContext";

export const LiquidityProvidersPage = () => {
  const [selectedWallet, setSelectedWallet] = useState<string>();
  const [selectedToken, setSelectedToken] = useState<string>();
  const { lpsByOwnerPubkey } = useContext(LpContext);
  const availableLpWallets = useMemo(() => {
    return Array.from(lpsByOwnerPubkey?.keys() || [])
  }, [lpsByOwnerPubkey])
  const { platformTokenMetaByPubkey: tokensByIdentifier } = useContext(NetworkContext)

  const filteredLps = useMemo(() => {
    if (lpsByOwnerPubkey == null || selectedWallet == null) {
      return []
    }

    if (selectedToken != null) {
      return lpsByOwnerPubkey.get(selectedWallet)?.filter((lp) => {
        return lp.houseToken.tokenMintPubkey.toString() == selectedToken
      })
    } else {
      return lpsByOwnerPubkey.get(selectedWallet)
    }
  }, [selectedWallet, lpsByOwnerPubkey, selectedToken])

  useEffect(() => {
    if (availableLpWallets != null && availableLpWallets.length > 0 && selectedWallet == null) {
      setSelectedWallet(availableLpWallets[0])
    }
  }, [availableLpWallets])

  const [selectedModalType, setSelectedModalType] = useState<TransferModalType>()
  const [selectedLpMeta, setSelectedLpMeta] = useState<ILpMeta>()

  const [showTransferModal, hideTransferModal] = useModal(
    ({ in: open }): JSX.Element => (
      <TransferModal visible={open} hideModal={hideTransferModal} lpMeta={selectedLpMeta} modalType={selectedModalType} updateModalType={setSelectedModalType} />
    ),
    [selectedLpMeta, selectedModalType],
  );

  const { house } = useContext(HouseContext)

  const formattedTokensRows: TableRow[] = useMemo(
    () => {
      if (filteredLps == null || tokensByIdentifier == null || house == null) {
        return []
      }

      return filteredLps?.map((lp, index) => {
        const tokenMintPubkey = lp.houseToken.tokenMintPubkey.toString()
        const tokenConfig = tokensByIdentifier.get(tokenMintPubkey);
        const balance = lp.getWithdrawableBalance();
        const uiBalance = balance / Math.pow(10, tokenConfig?.decimals || 6)
        const netDeposits = lp.netDeposits
        const uiNetDeposits = netDeposits / Math.pow(10, tokenConfig?.decimals || 6)
        const ownerPubKey = lp.ownerPubkey.toString();

        return {
          id: String(index) + tokenConfig?.symbol,
          token: (<div className="flex gap-1 items-baseline">
            {tokenConfig?.imageDarkPng ? <Icon iconUrl={tokenConfig?.imageDarkPng} size="xs" /> : null}
            <span className="uppercase">{tokenConfig?.symbol}</span>
          </div>),
          tokenMint: (tokenMintPubkey ? <Pubkey iconSize="xl" buttonIconSize="xs" pubkey={tokenMintPubkey || ''} truncateSize={3} /> : ''),
          owner: (ownerPubKey ? <Pubkey iconSize="xl" buttonIconSize="xs" pubkey={ownerPubKey || ''} truncateSize={3} /> : ''),
          balance: <Balance
            iconUrl={tokenConfig?.imageDarkPng}
            balance={uiBalance}
            decimals={tokenConfig?.decimals || 6}
            fiat={undefined} />,
          deposited: <Balance
            iconUrl={tokenConfig?.imageDarkPng}
            balance={uiNetDeposits}
            decimals={tokenConfig?.decimals || 6}
            fiat={undefined} />,

          depositWithdraw: (
            <div className="flex gap-1.5">
              <Button
                size="sm"
                icon={<IconFont name="download" />}
                variant="gray"
                onClick={() => {
                  setSelectedLpMeta({
                    lp: lp,
                    token: tokenConfig
                  })
                  setSelectedModalType(TransferModalType.DEPOSIT)
                  showTransferModal()

                }}
              >
                Deposit
              </Button>
              <Button
                size="sm"
                icon={<IconFont name="download" className="rotate-180" />}
                variant="gray"
                onClick={() => {
                  setSelectedLpMeta({
                    lp: lp,
                    token: tokenConfig
                  })
                  setSelectedModalType(TransferModalType.WITHDRAW)
                  showTransferModal()
                }}
              >
                Withdraw
              </Button>
            </div>
          ),
        }
      })
    },
    [filteredLps, tokensByIdentifier, house, showTransferModal, selectedModalType, selectedModalType]
  );
  const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

  return (
    <>
      <h1 className="text-2xl">Liquidity Dashboard</h1>
      <AdminBodyItem
        titlePrefix={
          <DropdownInput
            classes={{ button: "py-0 h-[42px] rounded font-normal border-0" }}

            options={availableLpWallets.map((wallet) => {
              return { label: truncatePubkey(wallet, 4), value: wallet }
            })}
            selectedValue={selectedWallet}
            onSelect={setSelectedWallet}
          />
        }
      >
        <TableGrid
          rows={formattedTokensRows}
          classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
          columns={[
            {
              header: "Token",
              accessor: "token",
            },
            {
              header: "Token Mint",
              accessor: "tokenMint",
            },
            {
              header: "Owner",
              accessor: "owner",
            },
            {
              header: "Balance",
              accessor: "balance",
            },
            {
              header: "Net Deposited",
              accessor: "deposited",
            },

            {
              header: "Deposit/Withdraw",
              accessor: "depositWithdraw",
            },
          ]}
          variant="regular"
          emptyMessage=" "
          onRowClick={(rowId) => { }}
        />
      </AdminBodyItem>
    </>
  );
};
