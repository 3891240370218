import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Input from "../../../components/common/input/Input";
import Button from "../../../components/common/button/Button";
import Separator from "../../Components/Separator/Separator";
import Icon from "../../../components/common/icon/Icon";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { createHouseTokenIx, delegateHouseTokenIx, undelegateHouseTokenIx, updateHouseTokenIx } from "../../sdk/house";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { sendTransaction } from "../../../sdk/transactions";
import { ProgramContext } from "../../../contexts";
import { PublicKey } from "@solana/web3.js";
import { CasinoSwitch } from "../../../components/common/switch/switch";
import HouseToken, { BPS_DENOMINATOR } from "../../../sdk/houseToken";
import { ICasinoToken } from "../../../types/token";
import { useWallet } from "@solana/wallet-adapter-react";
import DropdownInput from "../../../components/common/dropdown-input/DropdownInput";
import { SignerContext } from "../../Contexts/SignersContext";
import { HouseContext } from "../../Contexts/AdminHouseContext";

export interface ITokenEditInput {
  token: HouseToken
  context: ICasinoToken
}

export enum TokenEditModalType {
  DELEGATION = 'delegation',
  CONFIG = 'config',
  CREATE = 'create'
}

interface ITokenConfigurationModalProps {
  visible: boolean;
  hideModal: Function;
  token: ITokenEditInput | undefined
  type: TokenEditModalType | undefined
}

export const TokenConfigurationModal: FC<ITokenConfigurationModalProps> = ({
  visible,
  hideModal,
  token,
  type
}) => {

  const [noPermissions, setNoPermissions] = useState(false);
  const { signTransaction } = useWallet();
  const { signerInput, signerInputError, authorityPubkey, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput } = useContext(SignerContext);

  const noPermissionsContent = useMemo(() => {
    return (
      <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
        <div className="font-white text-3xl font-oxanium">No Permissions</div>
        <div className="text-xl text-gray-300">
          No permissions have been granted to selected wallet. Please contact Zeebit’s team when
          requesting DevTools access.
        </div>
        <Button
          variant="secondary"
          onClick={() => {
            // WIPE ALL THE INPUTS
            // emptyInputs();
          }}
        >
          Try Another Wallet
        </Button>
      </div>
    );
  }, []);

  const { client, erClient } = useContext(NetworkContext)
  const { house, loadHouse } = useContext(HouseContext)
  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext)
  const { meta } = useContext(ProgramContext);


  useEffect(() => {
    setPinInput(undefined);
  }, [visible]);

  const [selectedTokenStatus, setSelectedTokenStatus] = useState<string>()
  const [commitFrequencyMs, setCommitFrequencyMs] = useState<number>(60_000)

  // DELEGATE / UN-DELEGATE HOUSE TOKEN
  const changeDelegationStatus = useCallback(async () => {
    if (signerInputError || house == null || token?.token == null || selectedTokenStatus == null || client == null) {
      console.error('Issue with inputs for set token status', house)

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const isDelegated = token.token.delegationStatus == 'delegated'
      let sig

      if (isDelegated) {
        // WANT TO UN-DELEGATE
        const undelegateHouseTokenIxn = await undelegateHouseTokenIx(token.token, authorityPubkey)
        sig = await sendTransaction(
          [undelegateHouseTokenIxn],
          erClient,
          authorityPubkey,
          false,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )
      } else {
        // WANT TO DELEGATE
        const delegateHouseTokenIxn = await delegateHouseTokenIx(token.token, authorityPubkey, commitFrequencyMs)
        sig = await sendTransaction(
          [delegateHouseTokenIxn],
          client,
          authorityPubkey,
          true,
          meta?.errorByCodeByProgram,
          undefined,
          connectedViaWallet ? signTransaction : undefined,
          connectedViaKeyPair ? keypair : undefined,
          undefined,
          "confirmed"
        )
      }

      toast(
        <BaseToast
          message={`Successfully ${isDelegated ? 'undelegated' : 'delegated'} the house token: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE HOUSE
      await loadHouse()

      // CLEAR PIN AND SIGNER
      setPinInput(undefined)

      hideModal()
    } catch (err) {
      console.error('Issue updating status', err)
      toast(
        <BaseToast
          message={`Issue updating token status, check logs. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, selectedTokenStatus, client, pinInput, house, loadHouse, token, erClient, commitFrequencyMs, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  const [proportionTotalBankrollPercentage, setProportionTotalBankrollPercentage] = useState<number>() // 0%-100%
  const proportionTotalBankrollBps = useMemo(() => {
    if (proportionTotalBankrollPercentage == null) return

    const ratio = proportionTotalBankrollPercentage / 100
    const bps = ratio * BPS_DENOMINATOR

    return bps
  }, [proportionTotalBankrollPercentage])
  const [incrementUnit, setIncrementUnit] = useState<number>() // this is ui value
  const incrementUnitBasis = useMemo(() => {
    if (incrementUnit == null) return

    return incrementUnit * Math.pow(10, token?.context.decimals || 6)
  }, [incrementUnit, token?.context.decimals])
  const [liquidityEnabled, setLiquidityEnabled] = useState(false)
  const [tokenMint, setTokenMint] = useState()
  const tokenMintPubkey = useMemo(() => {
    try {
      return new PublicKey(tokenMint)
    } catch (err) { }
  }, [tokenMint])

  useEffect(() => {
    if (token?.token != null) {
      console.log({
        token
      })
      setProportionTotalBankrollPercentage((token.token.proportionTotalBankrollBps / BPS_DENOMINATOR) * 100)
      setIncrementUnit((token.token.incrementUnit || 0) / Math.pow(10, token.context?.decimals || 6))
      setSelectedTokenStatus(Object.keys(token.token.baseState.status)[0])
      setLiquidityEnabled(true) // NEEDS REAL DATA
    }
  }, [token, type])


  // UPDATE HOUSE TOKEN IX
  const updateHouseToken = useCallback(async () => {
    if (selectedSigner == null || pinInput == null || house == null || token?.token == null || client == null) {
      console.error('Issue with inputs for set token config')

      return
    }

    setLoading(true)

    try {
      const ix = await updateHouseTokenIx(token.token, authorityPubkey, selectedTokenStatus, incrementUnitBasis, proportionTotalBankrollBps)
      const isBase = token.token.isDelegated == false
      const clientToUse = isBase ? client : erClient;

      const sig = await sendTransaction(
        [ix],
        clientToUse,
        authorityPubkey,
        isBase,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )

      toast(
        <BaseToast
          message={`Successfully updated house token: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE HOUSE
      await loadHouse()


      // CLEAR PIN AND SIGNER
      setPinInput(undefined)

      hideModal()
    } catch (err) {
      console.error('Issue updating house token', err)
      toast(
        <BaseToast
          message={`Issue updating house token, check logs. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, selectedTokenStatus, client, pinInput, house, proportionTotalBankrollBps, incrementUnit, incrementUnitBasis, liquidityEnabled, loadHouse, erClient, selectedTokenStatus, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  // CREATE HOUSE TOKEN
  const createHouseToken = useCallback(async () => {
    if (selectedSigner == null || pinInput == null || house == null || client == null || tokenMintPubkey == null) {
      console.error('Issue with inputs for create house token')

      return
    }

    setLoading(true)

    try {
      const ix = await createHouseTokenIx(house, authorityPubkey, tokenMintPubkey, incrementUnitBasis || 0.01, liquidityEnabled, proportionTotalBankrollBps)

      const sig = await sendTransaction(
        [ix],
        client,
        authorityPubkey,
        true,
        meta?.errorByCodeByProgram,
        undefined,
        connectedViaWallet ? signTransaction : undefined,
        connectedViaKeyPair ? keypair : undefined,
        undefined,
        "confirmed"
      )

      toast(
        <BaseToast
          message={`Successfully updated token config: ${sig}`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE HOUSE
      await loadHouse()


      // CLEAR PIN AND SIGNER
      setPinInput(undefined)

      hideModal()
    } catch (err) {
      console.error('Issue updating token config', err)
      toast(
        <BaseToast
          message={`Issue updating token config, check logs. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, selectedTokenStatus, client, pinInput, house, proportionTotalBankrollBps, incrementUnit, incrementUnitBasis, liquidityEnabled, loadHouse, erClient, signTransaction, connectedViaWallet, connectedViaKeyPair, authorityPubkey, keypair, signerInputError])

  return (
    <BaseModal open={visible} onClose={hideModal} title={type == TokenEditModalType.CREATE ? "New House Token" : "Token configuration"}>
      <div className=" flex flex-col justify-center items-center text-gray-400 w-full">
        {noPermissions ? noPermissionsContent : ""}
        {!noPermissions ? (
          <div className="flex flex-col items-center gap-y-4 w-full">
            {/* Status */}
            {type != TokenEditModalType.CREATE ? <FormItem className="flex-1 self-stretch">
              <Input
                variant="regular"
                isPreview
                disabled
                value={token?.context?.symbol}
                leftInfo={<Icon iconUrl={token?.context?.imageDarkPng} className="mr-2" />}
                classes={{ input: "text-gray-50" }}
              />
            </FormItem> : ''}

            {type == TokenEditModalType.CONFIG ? <>
              <FormItem label="Choose Status" className="flex-1 self-stretch">
                <DropdownInput
                  classes={{ button: "py-0 h-[42px] rounded font-normal border-2 focus:border-2 border-gray-600" }}

                  options={[
                    { label: 'active', value: 'active' },
                    { label: 'frozen', value: 'frozen' },
                    { label: 'inFlowsSuspended', value: 'inFlowsSuspended' },
                    { label: 'outFlowsSuspended', value: 'outFlowsSuspended' }
                  ]}
                  selectedValue={selectedTokenStatus}
                  onSelect={setSelectedTokenStatus}
                />
              </FormItem>

              <Separator />
            </> : ''}

            {type == TokenEditModalType.CREATE ? <>
              <div className="flex text-gray-50 justify-between w-full items-center">
                <span>Token Mint</span>
                <Input
                  name="tokenMint"
                  variant="regular"
                  value={tokenMint}
                  onChange={(e) => {
                    setTokenMint(e.target.value)
                  }}
                  type="string"
                />
              </div>
            </> : ''}

            {type == TokenEditModalType.DELEGATION && token?.token.delegationStatus != 'delegated' ? <>
              <div className="flex text-gray-50 justify-between w-full items-center">
                <span>Commit Frequency Ms</span>
                <Input
                  name="commitFrequency"
                  variant="regular"
                  value={commitFrequencyMs}
                  onChange={(e) => {
                    setCommitFrequencyMs(e.target.value)
                  }}
                  type="number"
                />
              </div>
            </> : ''}


            {[TokenEditModalType.CONFIG, TokenEditModalType.CREATE].includes(type) ? <>
              <div className="flex text-gray-50 justify-between w-full items-center">
                <span>Liquidity Providing (Enabled)</span>
                <CasinoSwitch
                  checked={liquidityEnabled}
                  onChange={setLiquidityEnabled}
                />
              </div>
              <div className="flex text-gray-50 justify-between w-full items-center">
                <span>Unit Increments (UI)</span>
                <Input
                  name="unitIncrement"
                  variant="regular"
                  value={incrementUnit}
                  onChange={(e) => {
                    setIncrementUnit(Number(e.target.value))
                  }}
                  type="number"
                  leftInfo={<Icon iconUrl={token?.context?.imageDarkPng} className="mr-1.5" />}
                />
              </div>
              <div className="flex text-gray-50 justify-between w-full items-center">
                <div className="flex flex-col">
                  <span>Kelly Proportion</span>
                  <span className="text-gray-300 text-sm font-normal">of total bankroll</span>
                </div>
                <Input
                  name="proportionTotalBankrollBps"
                  variant="regular"
                  value={proportionTotalBankrollPercentage}
                  onChange={(e) => {
                    setProportionTotalBankrollPercentage(Number(e.target.value))
                  }}
                  type="number"
                  rightInfo={<span className="text-gray-400">%</span>}
                />
              </div>
            </> : ''}

            {/* Wallet */}
            <div className="w-full border-b border-b-gray-400" />
            <div className="flex gap-4 w-full">
              {signerInput}
            </div>
            {/* AUTH WALLET BUTTON */}
            {[TokenEditModalType.CONFIG].includes(type) ? <Button
              className="w-full"
              disabled={house == null || signerInputError}
              onClick={updateHouseToken}
              variant="secondary"
              isLoading={loading}
            >
              Update House Token
            </Button> : ''}

            {type == TokenEditModalType.CREATE ? <>
              <Button
                className="w-full"
                disabled={house == null || signerInputError || tokenMint == null || incrementUnit == null || proportionTotalBankrollBps == null || incrementUnit == null}
                onClick={createHouseToken}
                variant="secondary"
                isLoading={loading}
              >
                Create House Token
              </Button></> : ''}

            {type == TokenEditModalType.DELEGATION ? <>
              <Button
                className="w-full"
                disabled={house == null || signerInputError}
                onClick={changeDelegationStatus}
                variant="secondary"
                isLoading={loading}
              >
                {token?.token.delegationStatus == 'delegated' ? 'Undelegate' : 'Delegate'} House Token
              </Button></> : ''}
          </div>
        ) : (
          ""
        )}
      </div>
    </BaseModal>
  );
};
