import { FC } from "react";
import { TokenAmountField } from "../../../components/games/formGenerator/TokenAmoutInput";

export interface BalanceInputProps {
    balance: number;
    setBalance: React.Dispatch<React.SetStateAction<number>>;
    icon: string;
    max: number;
    error?: string;
}

export const BalanceInput: FC<BalanceInputProps> = ({ balance, setBalance, icon, max, error }) => {
    return (
        <>
            <TokenAmountField

                minWager={0}
                wager={String(balance)}
                setWager={setBalance}
                iconUrl={icon || ""}
                step={0.1}
                maxWager={max}
                actions={["half", "max"]}
                error={error}
            />
        </>
    );
};